import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Card,
  Checkbox,
  Select,
  Col,
  Row,
  message,
  Radio
} from "antd";
import Service from "../../service";
import { Link } from "react-router-dom";
import Logowhite from "assets/images/Logowhite.svg";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import { useDispatch } from "react-redux";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const DayFiveFeedbackForm = (props) => {
  const userData = localStorage.getItem('user_data') || {};
  const userId = localStorage.getItem('user_data') ? JSON.parse(userData)?._id : "";
  const params_id = userId;

  const [form] = Form.useForm();
  const Logo = localStorage.getItem("LogoURL");
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState({});
  const [isFormsubmited, setIsFormsubmited] = useState(false);

  // Function to handle checkbox changes

  useEffect(() => {
    if (params_id != "") {
      fetchEmpById(params_id);
      getFeedbackStatus();
    } else {
      window.location = "/signin"
    }

  }, []);
  const questions = [
    "You have attended orientation session/meeting with HR",
    "You had got a walk through of company policies & procedures",
    "You have got a walk through of HRMS",
    "You are aware of your working days & shift timings",
    "You have understood the leave policy",
    "You have understood the probation confirmation policy",
    "You have understood the 7, 15 & 30 days feedback mechanism",
    "You have understood the quarterly ranking system at Elsner",
    "You have received an access to your official email ID",
    "You have understood the ticketing concept for your grievances",
    "You had a brief about Elsner Got Talent & Elsner Elevate",
    "You had a walk through of the hierarchy of your department",
    "You have received employee welcome kit",
    "You had your system ready on the day of your joining",
    "You had an introduction with your workplace buddy?",
    "You had an introduction with your reporting manager?",
  ];

  const induction = [
    "Your Induction Program Was Informative",
    "Your Induction Program Was well organized",
    "Your Induction Program Helped you feel more prepared",
    "Your Induction Program Helped you feel more at ease with colleagues",
    "Your Induction Program Company rules were explained clearly",
  ];



  const fetchEmpById = async (params_id) => {
    // use this userID params to fetch emp data for admin and TLs
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.empById + "/" + params_id,
      });

      if (
        response.data.status == 200 &&
        response?.data &&
        response?.data?.data
      ) {
        setResponseData(response.data);
        // setEmpDetails(response?.data?.data);
        // setDeptData(response?.data?.data?.subdepartment_id?._id)
      }

      if (response.data.status == 403) {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
      //   setMount(false);
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      const payload = {
        created_by: params_id, // Pass the logged-in user ID
        dayFiveFeedback: {
          received_materials: formValues?.received_materials,
          started_work_on_project: formValues?.progress_of_project,
          any_challenges: formValues?.encountered_challenges,
          detail_challenges: formValues?.details_of_encountered_challenges,
          need_additional_support: formValues?.need_additional_support,
          need_additional_support_detail: formValues?.details_need_additional_support,
          today_work_highlight: formValues?.work_highlight,
          overallexp_of_today: formValues?.overall_exp_rate,
        },
      };
      // return;

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.postNewFeedbackData,
        body: payload,
      });

      if (response.status === 200) {
        message.success("Feedback submitted successfully!");
        setIsFormsubmited(true);
      } else {
        message.error(response.data?.message || "Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      message.error("Failed to submit feedback. Please try again.");
    }
  };

  const getFeedbackStatus = async () => {
    // use this userID params to fetch emp data for admin and TLs
    try {
      dispatch(showAuthLoader());
      const payload = {
        userId: params_id,
        feedbackType: "dayFiveFeedback"
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getFeedbackStatus,
        body: payload
      });

      if (
        response.data.status == 200 &&
        response?.data &&
        response?.data?.message == "Day Five feedback is already provided"
      ) {
        setIsFormsubmited(true);
        // setResponseData(response.data);
        // setEmpDetails(response?.data?.data);
        // setDeptData(response?.data?.data?.subdepartment_id?._id)
      }


      dispatch(hideAuthLoader());
      //   setMount(false);
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 13 },
    },
    wrapperCol: {
      xs: { span: 24 },
      md: { span: 16 },
      lg: { span: 11 },
    },
  };
  return (
    <>
      <div className="gx-app-login-wrap account-login">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content feedback-form-div-first">
              <div className="gx-app-logo" style={ { paddingBottom: "80px" } }>
                { Logo ? (
                  <img alt="example" src={ Logo } />
                ) : (
                  <img alt="example" src={ Logowhite } />
                ) }
              </div>

            </div>
            { (responseData.status !== 403 && !isFormsubmited) && (
              <div className="gx-app-login-content feedback-form-div-second">
                <div className="gx-app-login-left-content">
                  <h6>Welcome to Elsner HRMS Portal</h6>

                </div>
                <Card style={ { overflowX: "hidden", margin: "0" } }>
                  <div className="inout-header">
                    <h2>Day Five Feedback Form</h2>
                  </div>
                  <Form
                    form={ form }
                    className="feedback"
                    noValidate={ true }
                    { ...formItemLayout }
                    onFinish={ handleSubmit }
                  >
                    <Row>
                      <Col md={ 24 } sm={ 24 }>
                        <Form.Item
                          className="top-field"
                          label="Name"
                          name="fname"
                          initialValue={ responseData ? responseData.data?.full_name : "-" }
                        >
                          <span>{ responseData ? responseData.data?.full_name : "-" }</span>
                        </Form.Item>
                        <Form.Item
                          className="top-field"
                          label="Official Email ID"
                          name="email"
                          initialValue={ responseData ? responseData.data?.email : "-" }
                        >
                          <span>{ responseData ? responseData.data?.email : "-" }</span>
                        </Form.Item>



                        <Form.Item
                          className="top-field"
                          label="Employee Code"
                          name="emp_code"
                          initialValue={ responseData ? responseData.data?.emp_code : "-" }
                        >
                          <span>{ responseData ? responseData.data?.emp_code : "-" }</span>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          label="Date of joining"
                          name="joining_date"
                          initialValue={ responseData ? responseData.data?.created_At : "-" }
                        >
                          <span>{ responseData ? moment(responseData?.data?.created_At).format("DD-MM-YYYY") : "-" }</span>
                        </Form.Item>



                        <Form.Item
                          label="Please confirm whether you received all the necessary materials, documents, and access required for your role"
                          name="received_materials" // The name is still used for form submission but no default value is set
                        >
                          <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="feedback-day-one"

                          label="Have you started working on any projects or tasks assigned to you? If so, how is the progress? "
                          name="progress_of_project"
                          rules={ [
                            { required: true, message: "This field can't be empty." },
                          ] }
                        >
                          <Input.TextArea
                            rows={ 4 }
                          />
                        </Form.Item>



                        <Form.Item
                          label="Are there any challenges you've encountered so far that you'd like assistance with?"
                          name="encountered_challenges" // The name is still used for form submission but no default value is set
                        >
                          <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="feedback-day-one"

                          label="If your answer to the above question is yes, then please help us with more details"
                          name="details_of_encountered_challenges"
                        >
                          <Input.TextArea
                            rows={ 4 }
                          />
                        </Form.Item>

                        <Form.Item
                          label="Are there any areas where you feel you need additional support or training?"
                          name="need_additional_support" // The name is still used for form submission but no default value is set
                        >
                          <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="feedback-day-one"

                          label="If your answer to the above question is yes, then please help us with more details"
                          name="details_need_additional_support"
                        >
                          <Input.TextArea
                            rows={ 4 }
                          />
                        </Form.Item>

                        <Form.Item
                          className="feedback-day-one"

                          label="What have been the work highlights for you for today?"
                          name="work_highlight"
                          rules={ [
                            { required: true, message: "This field can't be empty." },
                          ] }
                        >
                          <Input.TextArea
                            rows={ 4 }
                          />
                        </Form.Item>


                        <Form.Item
                          className="top-field"
                          label="Please rate on your overall experience for today"
                          name="overall_exp_rate"
                        >
                          <Select placeholder="--Select--">
                            <Select.Option value="1">1 (Lowest)</Select.Option>
                            <Select.Option value="2">2</Select.Option>
                            <Select.Option value="3">
                              3
                            </Select.Option>
                            <Select.Option value="4">4</Select.Option>
                            <Select.Option value="5">5 (Highest)</Select.Option>
                          </Select>
                        </Form.Item>


                      </Col>
                    </Row>
                    <Form.Item className="ant-col-xs-24 ant-col-sm-24 ant-col-lg-10 leavebtns">
                      <div className="add-leave-button ">
                        <Button id="addbutton" type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            ) }

            { isFormsubmited && (
              <div className="gx-app-login-content response-msg">
                <h2>Day five feedback submitted</h2>
              </div>
            ) }
          </div>
        </div>
      </div>
    </>
  );
};
export default DayFiveFeedbackForm;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Form,
  Col,
  Modal,
  Popover,
  message,
  Row,
  Tag,
  Select,
  Input,
} from "antd";
import HrmsLogo from "../../assets/images/HrmsLogo.svg"
import { userSignOut } from "../../appRedux/actions/Auth";
import ProfileImage from "../../assets/images/default_profile.jpg";
import Econnect from "../../assets/images/AppLogo/Econnect.svg";
import Estimation from "../../assets/images/AppLogo/Estimation_tool.svg";
import Ecrm from "../../assets/images/AppLogo/Ecrm.svg";
import Ehrms from "../../assets/images/AppLogo/Ehrms.svg";
import Email_Tool from "../../assets/images/AppLogo/Email_Tool.svg";
import ETask_Hub from "../../assets/images/AppLogo/ETask_Hub.svg";
import Frame from "../../assets/images/AppLogo/Frame.svg";
import PdfIcon from "../../assets/images/pdf_icon.png";
import JobIcon from "../../assets/images/job_icon1.svg";
import { Link, withRouter } from "react-router-dom";
import Service from "../../service";
import { CiBellOn } from "react-icons/ci";
import { BiRefresh } from "react-icons/bi";
import { ImTicket } from "react-icons/im";



import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


import moment from "moment";
import { notificationAccessBlock } from "../../roles";
import { capitalizeFirstLetter, relationshipArry } from "../../components/EmpInfoManagement/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const formItemLayoutContact = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const popoverStyle = {
  position: "absolute",
  top: "50px",
  right: "0",
  width: "250px",
  background: "white",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "16px",
  border: "1px solid #ddd",
};

const profileContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const gridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "10px",
  border: "1.427px solid #07385C",
  padding: "8px",
  background: "linear-gradient(180deg, #FFF 0%, #DAF0FF 100%)",
  borderRadius: " 14.265px",
};

const buttonStyle = {
  padding: "10px",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "none",
  cursor: "pointer",
  background:"transparent"
};

const buttonTextStyle = {
  fontSize: "12px",
  marginTop: "5px",
  color:"gray"
}
const exceptThisSymbols = ["e", "E", "+", "-", "."];
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const LogoAction = useSelector((state) => state.common);
  const Logo = localStorage.getItem('LogoURL');
  const [isOpen, setIsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [dataCount, setCountdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addeditform] = Form.useForm();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [flag, setflag] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const [ModalDestry, setModalDestry] = useState(false)
  const location = useLocation()
  const { width, themeType } = useSelector(({ settings }) => settings);

  const [sideBarlogo, setSideBarlogo] = useState();

  const [ssoToken,setssoToken]=useState(localStorage.getItem("ssoToken"))
  // const apiUrl = import.meta.env.REACT_TASKHUB_REDIRECT;
  const handleVisibleChange = (visible) => {
    setVisible(visible);
    // readNotificationFromUser();
  };
const [checked,setChecked]=useState({
  "isHRMS":false,
  "isTaskHub":false, 
  "isEConnect":false,
  "isECRM":false,
  "isEmailTool":false, 
  "isEstimationTool":false ,
})

 const handleModuleGetData = async (id) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.empGteApplicable + "/" + id,
      });
      if (response.data.status === 200) {
        console.log(response, 'handleModuleGetData');
        let userData = response.data.data;
        
        // Create checked state with only the permission fields
        const permissionData = {
          isHRMS: userData.isHRMS || false,
          isTaskHub: userData.isTaskHub || false,
          isEConnect: userData.isEConnect || false,
          isECRM: userData.isECRM || false,
          isEmailTool: userData.isEmailTool || false,
          isEstimationTool: userData.isEstimationTool || false,
        };
        
        setChecked(permissionData);
        // setdefaultValues(permissionData);
        // setPreSelectedValues(permissionData);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
    }
  };



useEffect(()=>{
  
  handleModuleGetData(authUser._id)
},[authUser._id])

console.log(checked,'checked');

  useEffect(() => {
    // getNotification();
    setInterval(() => {
      // getNotification();
    }, 300000);
  }, []);

  // useEffect(() => {
  //   // console.log(authUser.isPasswordChanged,'chchchchchch')
  //   if (authUser.isPasswordChanged == false) {
  //     // console.log(authUser.isPasswordChanged,'chchchchchch...')

  //     success()
  //   }
  //   if (authUser?.isContactsAdded == false) {

  //     contact()
  //   }


  // }, [authUser.isPasswordChanged])

  // useEffect(()=>{
  //   if ((authUser?.isEducationDetailsAdded == false
  //     ||authUser?.isExperienceDetailsAdded
  //     ==false)&& location.pathname!=='/my-profile') {
  // // console.log( location.pathname,' location.pathname');

  //       EducationAndExperience()
  //       Modal.destroyAll()
  //   }
  // },[location.pathname])


  // Watch for changes in `authUser` instead of just `authUser.isPasswordChanged`

  // useEffect(() => {
  //     if (
  //         (authUser?.isEducationDetailsAdded === false || authUser?.isExperienceDetailsAdded === false) &&
  //         location.pathname !== '/my-profile'
  //     ) {
  //       return  EducationAndExperience();
  //         Modal.destroyAll();
  //     }
  // }, [authUser?.isEducationDetailsAdded === false || authUser?.isExperienceDetailsAdded === false , location.pathname]); // Added `authUser` to dependencies

  const contentOfPopover = (<div className="absolute top-12 right-0 w-56 bg-white shadow-lg rounded-lg p-4 border">
    <div className="flex flex-col items-center">
      <img
        src="https://via.placeholder.com/50" // Replace with actual profile image
        alt="Profile"
        className="w-12 h-12 rounded-full"
      />
      <span className="font-medium mt-2">Harshal</span>
    </div>

    {/* Action Buttons */}
    <div className="grid grid-cols-3 gap-2 mt-3">
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">Task Hub</span>
      </button>
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">HRMS</span>
      </button>
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">E-Connect</span>
      </button>
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">E-CRM</span>
      </button>
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">Email Tool</span>
      </button>
      <button className="p-2 bg-gray-100 rounded flex flex-col items-center">
        <span className="text-sm">Estimation</span>
      </button>
    </div>
  </div>)
  const getAdminListLogo = async () => {
    try {
      const params = `/${authUser?._id}`;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.adminSetting + params,
      });
      if (response.data?.data?.config?.adminSetting?.logo) {
        setSideBarlogo(response.data.data.config.adminSetting.logo)
      } else {
        if (LogoAction.logo) {
          setSideBarlogo(LogoAction.logo)
        } else if (Logo) {
          setSideBarlogo(Logo)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getNotification = async () => {
    setTimeout(() => {
      setflag(false);
    }, 2000);
    try {
      const reqBody = {
        id: authUser?._id,
        department_name: authUser?.department_id?.department_name,
      };
      // dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getNotification,
        body: reqBody,
      });
      if (response.data.status === 200) {
        setdata(response?.data?.data);
        setCountdata(response?.data.metaData.totalCount);
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      // dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  let ids = [
    data?.map((item) => {
      return item?._id;
    }),
  ];
  const readNotificationFromUser = async () => {
    try {
      const reqBody = {
        notification_id: authUser?._id,
        allnotification_id: ids,
      };
      // dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.readNotificationFromUser,
        body: reqBody,
      });
      if (response.data.status === 200) {
        // getNotification();
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      // dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };


  // fetch contact data from api
  const fetchContactDetails = async () => {
    try {
      const empId = authUser?._id;
      const reqBody = {
        employee_id: empId,

      };
      // if (searchText && searchText !== "") {
      //   setSearchEnabled(true);
      // }
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.contactList,
        body: reqBody,
      });


      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleAddEdit = async (values) => {
    try {
      const empId = authUser?._id;
      const reqBody = {
        employee_id: empId,
        employee_contact: {
          ...values,
        },
      };

      dispatch(showAuthLoader());
      let data = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addContact,
        body: reqBody,
      });

      fetchContactDetails();
      getUserDetailsByID();
      let isContactsAdded = user_data?.isContactsAdded;//localStorage.getItem("isContactsAdded")
      console.log(isContactsAdded, "isContactsAdded", data);
      if (data.status == 400) {
        message.error(data.data.message)
      } else {
        if (!isContactsAdded) {
          Modal.destroyAll();
          addeditform.resetFields()
        }
      }

      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
    // handleCloseEditModal();
  };
  const readNotification = async (notification_id, slug) => {
    try {
      if (props.location.pathname == slug) {
        window.location.href = slug;
      }
      setVisible(false);
      const reqBody = {
        notification_id: notification_id,
        user_id: authUser?._id,
      };
      //   dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.readNotification,
        body: reqBody,
      });
      if (response.data.status === 200) {
        // setdata(response?.data?.data);
        // setCountdata(response?.data.metaData.totalCount);
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      //   dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };
  const getUserDetailsByID = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.empById + "/" + authUser?._id,
        body: {},
      });

      const userData = response.data.data;
      localStorage.setItem("user_data", JSON.stringify(userData));
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  }


  const changepassword = async (value) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value?.current_password,
        newPassword: value?.confirm_password,
        emp_id: authUser?._id,
        isPasswordChanged: true,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmschangepassword,
        body: reqBody,
      });
      if (response.data.status === 200) {
        message.success(response.data.message);
        setIsModalOpen(false);
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  useEffect(() => {
    getAdminListLogo();
  }, [LogoAction.logo])

  useEffect(() => {
    if (authUser?.isPasswordChanged === false) {
      success();
    }
    if (authUser?.isContactsAdded === false) {
      contact();
    }
    if (

      authUser.isEducationDetailsAdded == false &&
      location.pathname !== "/my-profile"
    ) {
      EducationAndExperience();

    }
    if ((authUser.experience != 0 && authUser.isExperienceDetailsAdded == false) &&
      location.pathname !== "/my-profile"
    ) {
      EducationAndExperience();

    }
    // console.log(authUser,'authUser');



  }, [authUser]);  // 🔥 Ensure re-run when authUser or pathname changes

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const [openPopover, setOpenPopover] = useState(false);

  // const { authUser } = useSelector(({ auth }) => auth);  
  const first_name =
    authUser.first_name.charAt(0).toUpperCase() + authUser.first_name.slice(1);
  const last_name =
    authUser.last_name.charAt(0).toUpperCase() + authUser.last_name.slice(1);

  const userName = first_name + " " + last_name;

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser?.emp_code && (
        <>
          {" "}
          <li
            onClick={() => {
              setOpenPopover(false)
              props.history.push("/my-profile");
            }}
          >
            Edit Profile
          </li>
          <li onClick={() => {
            props.history.push('/employee-overview')
          }}>Employee Overview</li>
          <li onClick={showModal}>Change Password</li>
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
      {!authUser?.emp_code && (
        <>
          {" "}
          <li onClick={() => props.history.push("/admin-edit")}>
            Edit Profile
          </li>
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
    </ul>
  );

  const changepasswordForCommon = async (value) => {

    // console.log(value,'valuevalue',isPasswordData);

    try {
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value.old_password,
        newPassword: value.new_password,
        emp_id: authUser?._id,
        isPasswordChanged: true
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmschangepassword,
        body: reqBody,
      });
      if (response.data.status == 200) {
        message.success(response.data.message);
        // setIsModalOpen(false);
        // dispatch(setIsPasswordChange(false))

        Modal.destroyAll();
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };



  const success = () => {
    Modal.warning({
      title: 'Change Password',
      content: (
        <>
          <div className="CVhangePassword">
            <Form
              onFinish={changepasswordForCommon}
              {...formItemLayout}
            >
              <Form.Item
                label="Current Password"
                name="old_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter Current Password!",
                  },
                ]}
              >
                <Input.Password name="old_password" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter New Password!",
                  },
                ]}
              >
                <Input.Password name="new_password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter Confirm Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The confirm passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="profilecenter-uploadbtn">
                <Button type="primary" htmlType="submit">
                  Change Password
                </Button>
              </div>
            </Form>
          </div>

        </>
      ),
      footer: false,
      style: { textAlign: 'center' },
    });
  };

  const contact = () => {
    Modal.warning({
      title: "Add Contact",
      content: (
        <>
          <div className="basic_info_form_wrapper">
            <Form {...formItemLayoutContact} form={addeditform} onFinish={handleAddEdit}>
              <Row>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                      {
                        max: 30,
                        message: "Name should be less than 30 characters",
                      },
                    ]}
                    normalize={capitalizeFirstLetter}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="mobile"
                    label="Contact No."
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Contact Number",
                      },
                      {
                        len: 10,
                        message: "Please enter valid Contact Number",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        addeditform.setFieldsValue({ mobile: value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="relationship"
                    label="Relationship"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Relationship",
                      },
                      {
                        max: 30,
                        message: "Relationship should be less than 30 characters",
                      },
                    ]}
                  >
                    <Select placeholder="Select Relationship">
                      {relationshipArry?.map((i) => (
                        <Select.Option key={i} value={i}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    {...formItemLayout}
                    className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                  >
                    <div className="add-leave-button">
                      <Button type="primary" htmlType="submit" id="addbutton">
                        Submit
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      ),
      footer: false,
      style: { textAlign: "center" },

    });

  }

  const EducationAndExperience = () => {
    Modal.warning({
      title: "Add Details",
      content: (
        <>
          <div className="basic_info_form_wrapper">
            <p>Please Fill Your Pending Details
            </p>
            {/* <Link to='/my-profile'> */}
            <Button
              className="btn-success"
              onClick={() => {
                props.history.push('/my-profile')
                Modal.destroyAll()
              }}
            >
              Click
            </Button>
            {/* </Link> */}

            {/* <a href={'}>
                              <Button
                                className="btn-success"
                               
                              >
                               Click 
                              </Button>
                            </a> */}
          </div>
        </>
      ),
      footer: false,
      style: { textAlign: "center" },

    });

  }

  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  // console.log(`https://taskhub.elsner.com/signin?token=${ssoToken}`,'SSoToknT');
  
  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">


      
      {/* <Grip s
ize={24} /> */}
      
{checked?.isTaskHub||checked?.isECRM||checked?.isEConnect||checked?.isEmailTool||
checked?.isEstimationTool?

      <Popover placement="bottomRight" trigger='click'
        content={
          <div  style={gridContainerStyle}>

            {checked?.isTaskHub &&
            <a href={`https://taskhub.elsner.com/signin?token=${ssoToken}`}
            target="_blank" style={buttonTextStyle}>
            <button style={buttonStyle}>
                <img src={ETask_Hub} alt="Task Hub" style={{ width: "30px", height: "30px" }} />
                <span style={buttonTextStyle}>Task Hub</span>
              </button>
            </a>
              }
            {checked?.isEConnect &&
               <a href={`https://e-connect.elsner.com/signin?token=${ssoToken}`}
               target="_blank" style={buttonTextStyle}>
            <button style={buttonStyle}>
                
                <img src={Econnect} alt="E-Connect" style={{ width: "30px", height: "30px" }} />
                <span style={buttonTextStyle}>E-Connect</span>
              </button>
              </a>}
            {/* <button style={buttonStyle}>
                <img src="https://via.placeholder.com/30" alt="HRMS" />
                <span style={buttonTextStyle}>HRMS</span>
              </button> */}
            {checked?.isECRM &&
             <a href={`https://crm.elsner.com/login?token=${ssoToken}`}
             target="_blank" style={buttonTextStyle}>
              <button style={buttonStyle}>
                <img src={Ecrm} alt="E-CRM" style={{ width: "30px", height: "30px" }} />
                <span style={buttonTextStyle}>E-CRM</span>
              </button></a>}



            {checked?.isEmailTool &&
            // <a href={`https://tool.elsnerdev.com/latest/customer/index.php/guest/returnsso-login?token=${ssoToken}`} target="_blank" style={buttonTextStyle}>
            <a href={`https://tool.elsnerdev.com/latest/customer/index.php/guest/returnsso-login?token=${ssoToken}`} 
   target="_blank" 
   rel="noopener noreferrer" 
   style={buttonTextStyle}>
            <button style={buttonStyle}>
                <img src={Email_Tool} alt="Email Tool" style={{ width: "30px", height: "30px" }} />
                <span style={buttonTextStyle}>Email Tool</span>
              </button>
              </a>}
              {checked?.isEstimationTool &&
         <a href={`https://estimationtool.elsnerdev.com/loginpage?token=${ssoToken}`}  target="_blank"  style={buttonTextStyle}>   <button style={buttonStyle}>
         <img src={Estimation} alt="Estimation"  style={{ width: "30px", height: "30px" }}/>
         <span style={buttonTextStyle}>Estimation</span>
       </button></a>}

          </div>
          }>
             <img
          src={Frame}
          className="avatar-user"
          alt="User"
          style={{cursor:'pointer'}}
        />
     
      </Popover>:''
}
      {/* :''} */}
     
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        onOpenChange={(newVisible) => setOpenPopover(newVisible)}
      >
        <img
          src={
            authUser.emp_img
              ? `${Service.Server_Base_URL}/uploads/emp_images/${authUser.emp_img}`
              : ProfileImage
          }
          className="avatar-user"
          alt="User"
        />
        <span className="gx-avatar-name">
          {userName}
          <i className="icon icon-chevron-down gx-ml-2" />
        </span>
      </Popover>

      <Modal
        footer={false}
        title="Change Password"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={(values) => {
            changepassword(values);
          }}
          {...formItemLayout}
        >
          <Form.Item
            label="Current Password"
            name="current_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Current Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter New Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Confirm Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The confirm passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="profilecenter-uploadbtn">
            <Button key="back" type="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default withRouter(UserProfile);

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

// import ElsnerElevate from "assets/images/ElsnerElevate.svg";
import Logowhite from "assets/images/Logowhite.svg";
import Service from '../../service';
import HrmsLogo from "../../assets/images/HrmsLogo2.png"

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  const { authUser } = useSelector(
    ({ auth }) => auth
  );
  const [sideBarlogo, setSideBarlogo] = useState();
  const LogoAction = useSelector((state) => state.common);
  const Logo = localStorage.getItem('LogoURL');
  useEffect(() => {
    getAdminListLogo();
  }, [LogoAction.logo])

  const getAdminListLogo = async () => {
    try {
      const params = `/${authUser?._id}`;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.adminSetting + params,
      });
      if (response.data?.data?.config?.adminSetting?.logo) {
        setSideBarlogo(response.data.data.config.adminSetting.logo)
      } else {
        if (LogoAction.logo) {
          setSideBarlogo(LogoAction.logo)
        } else if (Logo) {
          setSideBarlogo(Logo)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header sidebar_main">
      { (navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar hemmenu">
        {/* <i
          className={ `gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}` }
          onClick={ () => {
            setSidebarCollapsed(!sidebarCollapsed)
          } }
        /> */}
      </div> : null }

      <Link to={ authUser?.emp_code ? '/dashboard' : '/dashboard' } className="gx-site-logo">

        { sideBarlogo ? (
          <img alt="" src={ sideBarlogo } />
        ) :
          navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
            <img alt="lo" src={ require("assets/images/fav.ico") } /> :
            themeType === THEME_TYPE_LITE ?
              <img alt="logo1" src={ HrmsLogo } /> :
              <img alt="logo2" src={ HrmsLogo } />
        }
        {/* <img alt="" src={ sideBarlogo } /> */ }
      </Link>
    </div>
  );
};

export default SidebarLogo;

import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Select, Table, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react'
import { getResource } from '../../util/getResourcePermission';
import config from "../../permission/config.json";
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { AiOutlineDelete } from 'react-icons/ai';
import Service from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { hideAuthLoader, showAuthLoader } from '../../appRedux/actions/Auth';
import moment from "moment";

export const Subscrition = () => {
    const Search = Input.Search;

    const [IsModalView, setIsModalView] = useState(false)
    const [IsEditModalView, setIsEditModalView] = useState(false)
    const [IsViewModalView, setIsViewModalView] = useState(false)

    const [totalRecords, setTotalRecords] = useState(0);
    const [searchRef,setsearchRef]=useState("")
    
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [subscriptionData, setsubscriptionData] = useState([])
    const [formEdit, setformEdit] = useState(true)
    const dispatch = useDispatch()
    const [view, setView] = useState('Add')
    let { authUser } = useSelector(({ auth }) => auth);
    const [employeeDoc, setEmployeeDoc] = useState([]);

    const [form] = Form.useForm();
    const [formNew] = Form.useForm();
    const [editModalTempId, setEditModalTempId] = useState('')
    const [viewModalData, setviewModalData] = useState({})
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    // const [selectedTimeline, setSelectedTimeline] = useState(null);
    // const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);


    const columns = [

        {
            title: "Date",
            dataIndex: "date",
            key: "date",

            render: (text, record, index) => {
                return <span style={{ textTransform: "capitalize" }}>{moment(record.createdAt ? record.createdAt : '-').format('DD MMM YYYY')}</span>;
            },
        },
        {
            title: "Time Line",
            dataIndex: "time",
            key: "time",

            render: (text, record, index) => {

                return <span style={{ textTransform: "capitalize" }}>{record.timeline ? record.timeline.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) : '-'}</span>;
            },
        },
        {
            title: "Subscription",
            dataIndex: "Subscription_name",
            key: "Subscription_name",
            render: (text, record, index) => {
                console.log(record,'record');
                
                return <span>{record?.detail ? record?.detail : '-'
                }</span>;
            },
        },
       
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            render: (text, record, index) => {
                return <span>{record?.creatorDetails?.full_name ? record?.creatorDetails?.full_name : '-'}</span>;
            },
        },
       
       
        {
            title: "BU Head",
            dataIndex: "bu_head",
            key: "bu_head",
            render: (text, record, index) => {
                return <span>{record?.businessHeadDetails?.full_name ? record?.businessHeadDetails?.full_name : '-'}</span>;
            },
        },
        {
            title: "Department Name",
            dataIndex: "department_name",
            key: "department_name",
            render: (text, record, index) => {
                return <span>{record?.departmentDetails?.department_name ? record?.departmentDetails?.department_name : '-'}</span>;
            },
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "ctions",
            render: (text, record, index) => {

                return <div
                    style={{
                        display: "flex",
                        flexwrap: "wrap",
                    }}
                >
                    <Button type="link success" title="View" onClick={() => { viewSubscModalOpen(record._id) }}>
                        <EyeOutlined style={{ fontSize: "18px" }} />
                    </Button>

                    {getResource(config.RESOURCES.SUBSCRIPTION, ["update"]) && (<>
                        <Button type="link success" title="Edit"
                            //  onClick={clkOnEditPen}
                            onClick={() => { editSubscModalOpen(record._id) }}
                        >
                            <EditOutlined style={{ fontSize: "18px", color: 'green' }} />

                        </Button></>)}
                    {getResource(config.RESOURCES.SUBSCRIPTION, ["delete"]) && (<>

                        <Popconfirm
                            className="delete-popup"
                            title="Are you really want to delete this ?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => { delete_subscription(record._id) }}
                        >
                            <Button type="link success" title="Delete" >
                                <AiOutlineDelete style={{ fontSize: "18px", color: 'red' }} />

                            </Button>
                        </Popconfirm>
                    </>)}

                </div>
            },
        },
    ]


    const addSubscModalOpen = () => {
        form.resetFields();

        setIsModalView(true)
    }
    const addSubscModalClose = () => {
        setIsModalView(false)
        setView('Add')
        formNew.resetFields();
        setSelectedTimeline(null)
        setviewModalData({})

    }

    const editSubscModalOpen = async (id) => {
        setIsEditModalView(true)
        setEditModalTempId(id)
        await detail_subscription(id)

    }
    const editSubscModalClose = () => {
        setIsEditModalView(false)
        form.resetFields();
        form.setFieldsValue({
            subscrition_detail: '',
            subscrition_info: '',
            subscrition_timeline: '',
        });
        setviewModalData({})
    }

    const viewSubscModalOpen = async (id) => {

        await detail_subscription(id)
        setIsViewModalView(true)
    }
    const viewSubscModalClose = async () => {
        setIsViewModalView(false)
        form.resetFields();
        form.setFieldsValue({
            subscrition_detail: '',
            subscrition_info: '',
            subscrition_timeline: '',
        });
        setviewModalData({})

    }

    // const add_subscription = async (value) => {
    //     try {
    //         dispatch(showAuthLoader());

    //         console.log(value, 'add_subscription');

    //         // Validation: Check required fields
    //         if (
    //             !value.subscrition_detail ||
    //             !value.subscrition_info ||
    //             !value.subscrition_timeline ||
    //             (!value.subscrition_invoice && value.subscrition_timeline !== 'free_trial') || // Invoice required except free_trial
    //             (value.subscrition_timeline !== 'yearly' && !value.subscription_date) || // subscription_date required except yearly
    //             (value.subscrition_timeline === 'free_trial' && (!value.from_date || !value.to_date)) // free_trial requires from & to dates
    //         ) {
    //             dispatch(hideAuthLoader());
    //             return message.error('Required fields are missing');
    //         }

    //         // Proceed with the form data if validation passes
    //         const formData = new FormData();
    //         formData.append('detail', value.subscrition_detail);
    //         formData.append('info', value.subscrition_info);
    //         formData.append('timeline', value.subscrition_timeline);
    //         formData.append('createdBy', authUser._id);

    //         // If free_trial, send from_date & to_date instead of subscription_date
    //         if (value.subscrition_timeline === 'free_trial') {
    //             formData.append('from_date', value.from_date);
    //             formData.append('to_date', value.to_date);
    //         } else {
    //             formData.append('subscription_date', value.subscription_date);
    //         }

    //         // Attach invoice files (if applicable)
    //         if (value.subscrition_timeline !== 'free_trial') {
    //             for (let i = 0; i < employeeDoc.length; i++) {
    //                 formData.append('invoice', employeeDoc[i]);
    //             }
    //         }

    //         console.log(formData, 'reqBody');

    //         const response = await Service.makeAPICall({
    //             methodName: Service.postMethod,
    //             api_url: Service.add_subscription,
    //             body: formData,
    //         });

    //         if (response.status) {
    //             if (response.status === 403) {
    //                 message.error(response.message);
    //             } else {
    //                 dispatch(hideAuthLoader());
    //                 get_subscription(); // Reload subscriptions
    //                 addSubscModalClose(); // Close modal only if success
    //                 setEmployeeDoc([]);
    //                 message.success('Subscription added successfully!');
    //             }
    //         }
    //         console.log(response, 'response');
    //     } catch (err) {
    //         console.log(err);
    //         dispatch(hideAuthLoader()); // Ensure loader is hidden in case of error
    //         return message.error('Something went wrong. Please try again.');
    //     }
    // };


    const add_subscription = async (value) => {
        try {
            dispatch(showAuthLoader());

            console.log("🔍 Submitted Form Values:", value); // Debugging log

            // Check missing fields
            // if (!value.subscrition_detail) console.log("🚨 Missing: subscrition_detail");
            // if (!value.subscrition_info) console.log("🚨 Missing: subscrition_info");
            // if (!value.subscrition_timeline) console.log("🚨 Missing: subscrition_timeline");

            // // Invoice is required except for Free Trial
            // if (value.subscrition_timeline !== "free_trial" && !value.subscrition_invoice) {
            //     console.log("🚨 Missing: subscrition_invoice");
            // }

            // // Subscription date is required except for Yearly
            // if (value.subscrition_timeline !== "yearly" && !value.subscription_date) {
            //     console.log("🚨 Missing: subscription_date");
            // }

            // // Free trial requires both from_date and to_date
            // if (value.subscrition_timeline === "free_trial") {
            //     if (!value.from_date) console.log("🚨 Missing: from_date");
            //     if (!value.to_date) console.log("🚨 Missing: to_date");
            // }

            if (
                !value.subscrition_detail ||
                !value.subscrition_info ||
                (value.subscrition_timeline !== "yearly" && !value.subscription_date && value.subscrition_timeline !== "free_trial") ||
                (value.subscrition_timeline === "free_trial" && (!value.from_date || !value.to_date))
            ) {
                dispatch(hideAuthLoader());
                return message.error("All required fields must be filled.");
            }

            const formData = new FormData();
            formData.append("detail", value.subscrition_detail);
            formData.append("info", value.subscrition_info);
            formData.append("timeline", value.subscrition_timeline);
            formData.append("createdBy", authUser._id);

            if (value.subscrition_timeline === "free_trial") {
                formData.append("from_date", value.from_date);
                formData.append("to_date", value.to_date);
            } else {
                formData.append("subscription_date", value.subscription_date);
            }

            // if (value.subscrition_timeline !== "free_trial") {
            for (let i = 0; i < employeeDoc.length; i++) {
                formData.append("invoice", employeeDoc[i]);
            }
            // }

            console.log("📤 FormData Sent:", Object.fromEntries(formData.entries())); // Log FormData

            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.add_subscription,
                body: formData,
            });

            if (response.status) {
                if (response.status === 403) {
                    message.error(response.message);
                } else {
                    dispatch(hideAuthLoader());
                    get_subscription();
                    addSubscModalClose();
                    setEmployeeDoc([]);
                    message.success(" Subscription added successfully!");
                }
            }
        } catch (err) {
            console.error(" Error in add_subscription:", err);
            dispatch(hideAuthLoader());
            return message.error("Something went wrong. Please try again.");
        }
    };

    const get_subscription = async () => {
        try {
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.get_subscription + `?page=${pagination.current}&limit=${pagination.pageSize}`,

            });
            console.log(response, 'subscriptionData');

            setsubscriptionData(response.data.data)
            setTotalRecords(response.data.totalRecords)
        } catch (error) {
            console.log(error, 'error');

            // message.error()
        }
    }
    const get_subscription_search = async () => {
        try {
            console.log(searchRef,'searchRef');
            
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.get_subscription + `?search=${searchRef}`,

            });
            console.log(response, 'subscriptionData');

            setsubscriptionData(response.data.data)
            setTotalRecords(response.data.totalRecords)
        } catch (error) {
            console.log(error, 'error');

            // message.error()
        }
    }

    const delete_subscription = async (id) => {
        try {
            // Validate if the ID is provided
            if (!id) {
                return message.error('Invalid subscription ID');
            }

            const req = { id: id };
            console.log(req, 'req');

            const response = await Service.makeAPICall({
                methodName: Service.deleteMethod,
                api_url: Service.delete_subscription,
                body: req,
            });

            console.log(response, 'subscriptionData');

            // Reload subscriptions after successful deletion
            if (response.status) {
                get_subscription();
                message.success('Subscription Deleted')
            } else {
                return message.error('Failed to delete the subscription. Please try again.');
            }
        } catch (error) {
            console.log(error);
            // Show a generic error message in case of any exception
            return message.error('Something went wrong. Please try again.');
        }
    };


    const detail_subscription = async (id) => {
        try {
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.detail_subscription + '/' + id,
            });
            form.setFieldsValue({
                subscrition_detail: response?.data?.data.detail,
                subscrition_info: response?.data?.data.info,
                subscrition_timeline: response?.data?.data.timeline,
            });
            return setviewModalData(response?.data?.data)
        } catch (error) {
            console.log(error);

        }
    }

    const edit_subscription = async (value) => {
        try {
            // Show loader
            dispatch(showAuthLoader());
            console.log(value, 'value');

            // Validate required fields before submitting
            if (
                !value.subscrition_detail ||
                !value.subscrition_info 
                // (value.subscrition_timeline !== "yearly" && !value.subscription_date && value.subscrition_timeline !== "free_trial") ||
                // (value.subscrition_timeline === "free_trial" && (!value.from_date || !value.to_date))
            ) {
                dispatch(hideAuthLoader());
                return message.error("All required fields must be filled.");
            }

            // Create FormData object
            const formData = new FormData();
            formData.append("detail", value.subscrition_detail);
            formData.append("info", value.subscrition_info);
            formData.append("timeline", value.subscrition_timeline);
            formData.append("updatedBy", authUser._id);

            if (value.subscrition_timeline === "free_trial") {
                formData.append("from_date", value.from_date);
                formData.append("to_date", value.to_date);
            } else if (value.subscrition_timeline !== "yearly") {
                formData.append("subscription_date", value.subscription_date);
            }

            // Append invoice files if available
            for (let i = 0; i < employeeDoc.length; i++) {
                formData.append("invoice", employeeDoc[i]);
            }

            // Make API call to update the subscription
            const response = await Service.makeAPICall({
                methodName: Service.putMethod,
                api_url: `${Service.edit_subscription}/${editModalTempId}`,
                body: formData,
            });

            if (response.status) {
                dispatch(hideAuthLoader());
                get_subscription(); // Reload data
                message.success("Subscription Updated");
                editSubscModalClose(); // Close modal
                setEditModalTempId("");
            } else {
                dispatch(hideAuthLoader());
                return message.error("Failed to edit the subscription. Please try again.");
            }
        } catch (err) {
            console.log(err);
            dispatch(hideAuthLoader());
            return message.error("Something went wrong. Please try again.");
        }
    };



    const handleTableChange = (page, pageSize) => {
        // setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize,
        })
        get_subscription();
    };

    // const formatInvoiceDate = (invoice) => {
    //     // Check if the invoice object and necessary dates are present
    //     if (!invoice || (!invoice.invoice_date && (!invoice.from_date || !invoice.to_date))) {
    //         return "N/A";  // Return "N/A" if no date is provided
    //     }

    //     // If the invoice is for a free trial, handle both from_date and to_date
    //     if (invoice.invoice_timeline === "free_trial") {
    //         // Check if both from_date and to_date are available
    //         if (invoice.from_date && invoice.to_date) {
    //             const fromDate = moment(invoice.from_date);
    //             const toDate = moment(invoice.to_date);
    //             return `${fromDate.format("YYYY MMMM DD dddd")} to ${toDate.format("YYYY MMMM DD dddd")}`;
    //         }
    //         // If only one of the dates is available (incomplete range), format it individually
    //         return moment(invoice.from_date || invoice.to_date).format("YYYY MMMM DD dddd");
    //     }

    //     // Handle regular invoices (monthly, yearly, one_time)
    //     const date = moment(invoice.invoice_date);
    //     return date.format("YYYY MMMM DD dddd");
    // };

    const formatInvoiceDate = (invoice) => {
        if (!invoice.invoice_date||invoice.invoice_date=='undefined') return "N/A"; // Return "N/A" if no date is provided

        const { invoice_timeline, invoice_date } = invoice;
console.log(invoice_timeline, invoice_date ,'invoice_timeline, invoice_date ');

        // Handle "free_trial" with date range
        if (invoice_timeline === "free_trial" && invoice_date.includes(" to ") && invoice_date !== 'undefined to undefined') {
            const [fromDate, toDate] = invoice_date
                .split(" to ")
                .map(date => moment(new Date(date.trim())).format("MMMM D, YYYY"));
        console.log(fromDate, toDate,'fromDate, toDate');
        
            // Check if either logfromDate or toDate is 'undefined' after trimming and formatting
            if (fromDate === 'undefined' || toDate === 'undefined') {
                return 'N/A';  // Return 'N/A' if the date is invalid
            } else {
                return `${fromDate} to ${toDate}`;  // Return formatted date range
            }
                }else if(invoice_timeline === "monthly"){
            return moment(new Date(invoice_date)).format("MMMM YYYY");

        }else if(invoice_timeline === "yearly"){
            return moment(new Date(invoice_date)).format(" YYYY");

        } else if( invoice_timeline === "one_time"){            return moment(new Date(invoice_date)).format("MMMM DD, YYYY ");


        }

        // Handle all other invoices (monthly, yearly, one_time)
        return 'N/A';
    };

    useEffect(() => {
        get_subscription(pagination.current, pagination.pageSize)
    }, [pagination.current, pagination.pageSize])

    console.log(subscriptionData, 'subscriptionData', viewModalData, viewModalData.invoice);

    const handleTimelineChange = (value) => {
        setSelectedTimeline(value);
        setSelectedDate(null); // Reset date when timeline changes
    };
    const handleFileUpload = (e) => {
        const allowedTypes = ["application/pdf", "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg", "image/png"];

        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => allowedTypes.includes(file.type));

        if (validFiles.length !== files.length) {
            message.error("Only .pdf, .doc, .docx, .jpg, .png files are allowed!");
        } else {
            setEmployeeDoc(validFiles); // Store only valid files
        }
    };
console.log(viewModalData,'viewModalData');
  useEffect(()=>{
        if(searchRef==''){
            get_subscription();
        }
    },[searchRef])
    return (
        <>
            <Card title='Subscriptions' >

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <Search
                              onChange={(e)=>setsearchRef(e.target.value)}
                              placeholder="Search..."
                              onSearch={get_subscription_search}
                            //   onKeyUp={resetSearchFilter}
                              style={{ width: 200 }}
                              className="mr2"
                            />
                            {/* <Button
                              type="primary"
                            //   onClick={() => openEventModalView()}
                              className="mr2"
                            >
                              Filter
                            </Button>
                            <Button
                              onClick={() => {
                                form.resetFields();
                                setFilterData([]);
                                setVal("All");
                              }}
                              className="mr2"
                              disabled={filterData.length != 0 ? false : true}
                            >
                              Clear Filter
                            </Button> */}
                
                {getResource(config.RESOURCES.SUBSCRIPTION, ["create"]) && (<>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button onClick={addSubscModalOpen} type="primary">Add Subscription</Button>
                    </div>
                </>)}
</div>
                {/* {getResource(config.RESOURCES.SUBSCRIPTION, ["create"]) && (<>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button onClick={addSubscModalOpen} type="primary">Add Subscription</Button>
                    </div>
                </>)} */}

                {/* //crate subscription */}

                <Modal
                    title="Add Subscription"
                    width={1100}
                    open={IsModalView}
                    footer={false}
                    onCancel={addSubscModalClose}
                >
                    <Form form={formNew} onFinish={add_subscription} initialValues={{ subscrition_detail: "", subscrition_info: "", subscrition_timeline: "" }}>
                        {/* First row: Details and Info */}
                        <Row gutter={24}>
                            <Col sm={24} md={12}>
                                {/* <Form.Item label="Subscription Name" name="subscrition_detail" required>
                                    <Input placeholder="Subscription Name" disabled={!formEdit} />
                                </Form.Item> */}

                                <Form.Item
                                    label="Subscription Name"
                                    name="subscrition_detail"
                                    rules={[{ required: true, message: "Subscription Name is required" }]}
                                >
                                    <Input placeholder="Subscription Name" disabled={!formEdit} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item label="Info" name="subscrition_info" required>
                                    <TextArea placeholder="Info.." disabled={!formEdit} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Second row: Invoice and Timeline */}
                        <Row gutter={24}>
                            <Col sm={24} md={12}>
                                <Form.Item label="Time Line" name="subscrition_timeline" required>
                                    <Select disabled={!formEdit} size="large" onChange={handleTimelineChange}>
                                        <Option value="monthly">Monthly</Option>
                                        <Option value="yearly">Yearly</Option>
                                        <Option value="free_trial">Free Trial</Option>
                                        <Option value="one_time">One Time</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item label="Invoice" name="subscrition_invoice">
                                    <input type="file" accept='.pdf, .doc, .docx, .jpg, .png' disabled={!formEdit} onChange={(e) => { setEmployeeDoc(e.target.files) }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Conditional Date Selection Row */}
                        {(selectedTimeline === "monthly" || selectedTimeline === "one_time") && (
                            <Row justify="center">
                                <Col>
                                    <Form.Item
                                        label={selectedTimeline === "monthly" ? "Select Month & Year" : "Select Date"}
                                        name="subscription_date"
                                        
                                    >
                                        <DatePicker
                                            picker={selectedTimeline === "monthly" ? "month" : undefined}
                                            format={selectedTimeline === "monthly" ? "MMMM YYYY" : "YYYY-MM-DD"}
                                            placeholder={selectedTimeline === "monthly" ? "Select Month & Year" : "Select Date"}
                                            value={selectedDate ? moment(selectedDate) : null}
                                            onChange={(date, dateString) => setSelectedDate(dateString)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        {/* Show From Date & To Date if Free Trial is selected */}
                        {selectedTimeline === "free_trial" && (
                            <Row gutter={16} justify="center">
                                <Col>
                                    <Form.Item
                                        label="From Date"
                                        name="from_date"
                                       
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Select From Date"
                                            value={fromDate ? moment(fromDate) : null}
                                            onChange={(date, dateString) => {
                                                setFromDate(dateString);
                                                form.setFieldsValue({ from_date: dateString }); // Ensure form value is updated
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label="To Date"
                                        name="to_date"
                                        
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Select To Date"
                                            value={toDate ? moment(toDate) : null}
                                            onChange={(date, dateString) => {
                                                setToDate(dateString);
                                                form.setFieldsValue({ to_date: dateString }); // Ensure form value is updated
                                            }}
                                            disabledDate={(current) => fromDate && current.isBefore(moment(fromDate))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}



                        {/* Third row: Submit button */}
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={!formEdit}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>


                {/* //edit subscription */}
                <Modal
                    title="Edit Subscription"
                    width={1100}
                    open={IsEditModalView}
                    footer={false}
                    onCancel={editSubscModalClose}
                >
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={edit_subscription}
                        initialValues={{
                            subscrition_detail: viewModalData.detail,
                            subscrition_info: viewModalData?.info,
                            subscrition_timeline: viewModalData?.timeline,
                        }}
                    >
                        <Row gutter={24}>
                            <Col xs={24} sm={12}>
                            <Form.Item
                                    label="Subscription Name"
                                    name="subscrition_detail"
                                    rules={[{ required: true, message: "Subscription Name is required" }]}
                                >
                                    <Input placeholder="Subscription Name" disabled={!formEdit} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label="Info"
                                    name="subscrition_info"
                                    rules={[{ required: true, message: "Info is required" }]}
                                >
                                    <TextArea placeholder="Enter info..." disabled={!formEdit} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label="Timeline"
                                    name="subscrition_timeline"
                                    rules={[{ required: true, message: "Please select a timeline" }]}
                                >
                                    <Select disabled size="large"  >
                                        <Option value="monthly">Monthly</Option>
                                        <Option value="yearly">Yearly</Option>
                                        <Option value="free_trial">Free Trial</Option>
                                        <Option value="one_time">One Time</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item label="Add Invoice" name="subscrition_invoice" >
                                    <input type="file" accept='.pdf, .doc, .docx, .jpg, .png' disabled={!formEdit} onChange={(e) => { setEmployeeDoc(e.target.files) }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Conditional Date Selection */}
                        {/* {(viewModalData?.timeline === "monthly" || viewModalData?.timeline === "one_time" || viewModalData?.timeline === "free_trial") && (
                            <Row justify="center">
                                <Col>
                                    <Form.Item label={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"} name="subscription_date" required>
                                        <DatePicker
                                            picker={viewModalData?.timeline === "monthly" ? "month" : undefined}
                                            format={viewModalData?.timeline === "monthly" ? "MMMM YYYY" : "YYYY-MM-DD"}
                                            placeholder={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                                            value={selectedDate ? moment(selectedDate) : null}
                                            onChange={(date, dateString) => setSelectedDate(dateString)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )} */}
                        {/* {(viewModalData?.timeline === "monthly" || viewModalData?.timeline === "one_time") && (
                            <Row justify="center">
                                <Col>
                                    <Form.Item
                                        label={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                                        name="subscription_date"
                                        
                                    >
                                        <DatePicker
                                            picker={viewModalData?.timeline === "monthly" ? "month" : undefined}
                                            format={viewModalData?.timeline === "monthly" ? "MMMM YYYY" : "YYYY-MM-DD"}
                                            placeholder={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                                            value={selectedDate ? moment(selectedDate) : null}
                                            onChange={(date, dateString) => setSelectedDate(dateString)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )} */}
{/* {(viewModalData?.timeline === "monthly" || viewModalData?.timeline === "one_time") && (
    <Row justify="center">
        <Col>
            <Form.Item
                label={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                name="subscription_date"
            >
                <DatePicker
                    picker={viewModalData?.timeline === "monthly" ? "month" : undefined}
                    format={viewModalData?.timeline === "monthly" ? "MMMM YYYY" : "YYYY-MM-DD"}
                    placeholder={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                    value={selectedDate ? moment(selectedDate) : null}
                    onChange={(date, dateString) => setSelectedDate(dateString)}
                    disabledDate={(current) => {
                        console.log("Current Date:", current.format("YYYY-MM-DD")); // Debugging

                        // Check if invoice array exists
                        if (!viewModalData?.invoice || viewModalData?.invoice.length === 0) {
                            return false; // No dates to disable
                        }

                        // Extract invoice dates (first date from range)
                        const disabledDates = viewModalData.invoice.map((invoice) => {
                            console.log("Checking Invoice:", invoice); // Debugging

                            if (invoice?.invoice_date) {
                                // Extract first date from the range
                                const dateStr = invoice.invoice_date.split(" to ")[0]; // Take the first date
                                return moment(new Date(dateStr)).startOf(
                                    viewModalData?.timeline === "monthly" ? "month" : "day"
                                );
                            }
                            return null;
                        }).filter(Boolean); // Remove null values

                        // Disable the invoice dates
                        return disabledDates.some(disabledDate =>
                            current.isSame(disabledDate, viewModalData?.timeline === "monthly" ? "month" : "day")
                        );
                    }}
                />
            </Form.Item>
        </Col>
    </Row>
)} */}

{(viewModalData?.timeline === "monthly" || viewModalData?.timeline === "one_time") && (
    <Row justify="center">
        <Col>
            <Form.Item
                label={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                name="subscription_date"
                // initialValue={viewModalData?invo}
            >
                <DatePicker
                    picker={viewModalData?.timeline === "monthly" ? "month" : undefined}
                    format={viewModalData?.timeline === "monthly" ? "MMMM YYYY" : "YYYY-MM-DD"}
                    placeholder={viewModalData?.timeline === "monthly" ? "Select Month & Year" : "Select Date"}
                    value={selectedDate ? moment(selectedDate) : null}
                    onChange={(date, dateString) => setSelectedDate(dateString)}
                    disabledDate={(current) => {
                        console.log("Current Date:", current.format("YYYY-MM-DD")); // Debugging

                        // Check if invoice array exists
                        if (!viewModalData?.invoice || viewModalData?.invoice.length === 0) {
                            return false; // No dates to disable
                        }

                        // Extract invoice dates (first date from range)
                        const disabledDates = viewModalData.invoice.map((invoice) => {
                            console.log("Checking Invoice:", invoice); // Debugging

                            if (invoice?.invoice_date) {
                                // Extract first date from the range
                                const dateStr = invoice.invoice_date.split(" to ")[0]; // Take the first date
                                return moment(new Date(dateStr)).startOf(
                                    viewModalData?.timeline === "monthly" ? "month" : "day"
                                );
                            }
                            return null;
                        }).filter(Boolean); // Remove null values

                        // Disable the invoice dates
                        return disabledDates.some(disabledDate =>
                            current.isSame(disabledDate, viewModalData?.timeline === "monthly" ? "month" : "day")
                        );
                    }}
                />
            </Form.Item>
        </Col>
    </Row>
)}



                        {/* Show From Date & To Date if Free Trial is selected */}
                        {viewModalData?.timeline === "free_trial" && (
                            <Row gutter={16} justify="center">
                                <Col>
                                    <Form.Item label="From Date" name="from_date" >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Select From Date"
                                            value={fromDate ? moment(fromDate) : null}
                                            onChange={(date, dateString) => setFromDate(dateString)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="To Date" name="to_date" required>
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Select To Date"
                                            value={toDate ? moment(toDate) : null}
                                            onChange={(date, dateString) => setToDate(dateString)}
                                            disabledDate={(current) => fromDate && current.isBefore(moment(fromDate))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        {/* Show Previous Invoices */}
                        {viewModalData?.invoice && (
                            <Row>
                                <Col span={24}>
                                    <div style={{ marginBottom: "16px" }}>
                                        <h4>Previous Invoices:</h4>
                                        <ul>
                                            {viewModalData.invoice.map((invoice, index) => (
                                                <li key={index} style={{ display: 'flex', gap: '20px' }}>

                                                    {
                                                        invoice.invoice_file !== '' ? <>
                                                            <a
                                                                href={`${process.env.REACT_APP_API_URL}/uploads/subscription-invoice/${invoice.invoice_file}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {invoice.invoice_timeline.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                                            </a>
                                                            {invoice.invoice_date!==undefined?
                                                            <p>{formatInvoiceDate(invoice)}</p>:'no'
                                                            
                                                            }                                                        </> : ''
                                                    }
                                                    
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {/* Submit Button */}
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={!formEdit}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>


                {/* view modal */}
                <Modal
                    title={`View Subscription`}
                    width={1100}
                    open={IsViewModalView}
                    footer={false}
                    onCancel={viewSubscModalClose}
                >
                    <Form
                        form={form}
                        layout="horizontal"
                        initialValues={{
                            subscrition_detail: viewModalData?.detail,
                            subscrition_info: viewModalData?.info,
                            subscrition_timeline: viewModalData?.timeline,
                        }}
                    >
                        {/* Row 1: Detail and Info side by side */}
                        <Row gutter={16}>
                            <Col sm={24} md={12}>
                                {/* <Form.Item label="Details" name="subscrition_detail">
                                    <TextArea
                                        placeholder="Detail.."
                                        value={viewModalData?.detail}  // Default value if viewModalData is not available
                                        disabled
                                    />
                                </Form.Item> */}
                                <Form.Item
                                    label="Subscription Name"
                                    name="subscrition_detail"
                                   
                                    // rules={[{ required: true, message: "Subscription Name is required" }]}
                                >
                                    <Input placeholder="Subscription Name"   value={viewModalData?.detail} 
                                    disabled/>
                                </Form.Item>
                            </Col>

                            <Col sm={24} md={12}>
                                <Form.Item label="Info" name="subscrition_info">
                                    <TextArea
                                        placeholder="Info.."
                                        value={viewModalData?.info || ''}  // Default value if viewModalData is not available
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Row 2: Timeline */}
                        <Row gutter={16}>
                            <Col sm={24}>
                                <Form.Item label="Time Line" name="subscrition_timeline">
                                    <Select
                                        disabled
                                        size="large"
                                        value={viewModalData?.timeline}
                                    >
                                        <Option value="monthly">Monthly</Option>
                                        <Option value="yearly">Yearly</Option>
                                        <Option value="free_trial">Free Trial</Option>
                                        <Option value="one_time">One Time</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Row 3: Previous Invoices */}
                        <Row gutter={16}>
                            <Col span={24}>
                                {viewModalData && viewModalData?.invoice && (
                                    <div style={{ marginBottom: "16px" }}>
                                        {viewModalData.invoice?<>
                                        <h4>Previous Invoices:</h4>
                                        <ul>
                                            {viewModalData.invoice.map((invoice, index) => (
                                                <li key={index} style={{ display: 'flex', gap: '20px' }}>
                                                    {/* Invoice Link */}
                                                    {
                                                        invoice.invoice_file !== '' ? <>
                                                            <a
                                                                href={`${process.env.REACT_APP_API_URL}/uploads/subscription-invoice/${invoice.invoice_file}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {invoice.invoice_timeline.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                                            </a>
                                                            <p>{formatInvoiceDate(invoice)}</p>
                                                        </> : ''
                                                    }

                                                    {/* Formatted Invoice Date */}
                                                   
                                                </li>
                                            ))}
                                        </ul></>:''}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Table
                    columns={columns}
                    dataSource={subscriptionData}
                    pagination={{
                        showSizeChanger: true,
                        ...pagination, total: totalRecords,
                        onChange: (page, pageSize) => handleTableChange(page, pageSize),
                    }}
                    footer={() => {
                        return (
                            <label>
                                Total Records Count is {totalRecords}
                            </label>
                        );
                    }}
                />
            </Card>



        </>
    )
}


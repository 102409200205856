import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Input,
  Table,
  Tag,
  Button,
  message,
  Form,
  Row,
  Select,
  DatePicker,
  Col,
  Modal,
  Popconfirm,
  Card,
  Tooltip,
  TimePicker,
  InputNumber,
  Checkbox,
  Upload,
  Radio,
  Popover,
} from "antd";
import Service from "../../service";
import { useParams, useLocation } from "react-router-dom";
import {
  UploadOutlined,
  EyeOutlined,
  DownloadOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showAuthLoader,
  hideAuthLoader,
  userData,
} from "../../appRedux/actions/Auth";
import TextArea from "antd/lib/input/TextArea";
import ImgCrop from "antd-img-crop";
import imageCompression from "browser-image-compression";
import ElsnerLogo from "../../assets/images/Logowhite.svg";
import { footerText } from "util/config";
import axios from "axios";

const UploadDocsCandidate = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let token = query.get("token");
  const [fileList, setFileList] = useState([]);
  const [imageUrlArr, setImageUrlArr] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState();
  const [filename, setFileName] = useState([]);
  const [placeinfo, setPlaceinfo] = useState({});
  //
  const [sameAsAbove, setSameAsAbove] = useState(false);

  //
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [alluploadeddocs, setalluploadeddocs] = useState([]);
  const [previewImage, setPreviewImage] = useState("");

  const [previewTitle, setPreviewTitle] = useState("");
  const [onBoardemployeeDetails, setonBoardEmployeeDetails] = useState({});
  const [flag, setFlag] = useState(1);
  const [empDocsData, setEmpDocsData] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);

  console.log(onBoardemployeeDetails, "userData");

  const { TextArea } = Input;
  const { id } = useParams();

  const [form] = Form.useForm();
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    Getcountry();
    Getstate();
    Getcity();
    getEmployeeDetails();
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const Getcountry = async () => {
    axios("https://countriesnow.space/api/v0.1/countries/positions")
      .then(function (response) {
        setCountry(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getstate = async (name) => {
    axios
      .post("https://countriesnow.space/api/v0.1/countries/states", {
        country: name,
      })
      .then(function (response) {
        setState(response?.data?.data?.states);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getcity = async (state) => {
    axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: placeinfo?.country,
        state: state,
      })
      .then(function (response) {
        setCity(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getEmployeeDetails = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: `${Service.getEmployeeDetailsByToken}/${token}`,
      });
      if (response.data && response.data.data.length !== 0) {
        console.log(
          "🚀 ~ file: UploadDocsCandidate.js:151 ~ getEmployeeDetails ~ response.data.data:",
          response.data.data
        );
        let { birthdate, initialName, ...userData } = response.data.data.user;
        setonBoardEmployeeDetails(userData);
        if (response.data.data.docs.employee_doc) {
          setUploadedData(response.data.data.docs.employee_doc);
        }
       // Set initial form values, including birthdate
// form.setFieldsValue({
//   birthdate: birthdate ? moment(birthdate,'DD-MM-YYYY') : null, // Convert to moment
// });
        if (initialName == "Mr") {
          form.setFieldsValue("gender", "Male");
        }
        if (initialName == "Mrs") {
          form.setFieldsValue("gender", "Female");
        }
        // console.log(userData,'userData',response.data.data,response.data.data.user.birthdate);
        
        // form.setFieldsValue(userData);

        // handleSameAsAboveChange
        setPlaceinfo({
          country: userData.country,
          state: userData.state,
          city: userData.city,
          district: userData.district,
          pin_code: userData.pin_code,
          address: userData.address,
        })
        if ((userData.address===userData.address2)&&(userData.address!==undefined&&userData.address2!==undefined)) {
          // handleSameAsAboveChange()
          setSameAsAbove(true)
          // placeinfo
        }

        let formattedDOB = moment(response?.data?.data?.user?.birthdate); // Convert to moment

        if (response?.data?.data?.user?.birthdate) {
          form.setFieldsValue({
            birthdate: formattedDOB, // Always set the moment object
          });
        }
        
form.setFieldsValue({
  // birthdate: response?.data?.data?.user?.birthdate??formeteDOB,
  aadhar_number: userData.aadhar_number,
  pan_number: userData.pan_number,
  country: userData.country,
  state: userData.state,
  city: userData.city,
  district: userData.district,
  pin_code: userData.pin_code,
  address: userData.address,
  
  // Present address fields
  PresentCountry: userData.country2,
  PresentState: userData.state2,
  PresentCity: userData.city2,
  PresentDistrict: userData.district2,
  PresentPinCode: userData.pin_code2,
  PresentAddress: userData.address2,


  // Bank details
  bank_name: userData.bank_name,
  acc_holder_name: userData.acc_holder_name,
  bank_branch: userData.bank_branch,
  ifsc: userData.ifsc,
  acc_number: userData.acc_number,
  cheque_number: userData.cheque_number,

  // Other details
  marital_status: userData.marital_status,
  gender: userData.gender,
  mothername: userData.mothername,
  fathername: userData.fathername,
  hiddenTalent: userData.hiddenTalent,
});
        dispatch(hideAuthLoader());
      }
      if (
        response.data &&
        response.data.data.length == 0 &&
        response.data.message.includes("You already submit documents")
      ) {
        setFlag(2);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const getCompressedFile = async (imageFile) => {
    const options = {
      maxSizeMB: 5,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpg" || file.type === "image/jpeg";
    // file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/JPEG file!");
    }
    const isLtM = file.size / 1024 / 1024 < 2;
    if (!isLtM) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng;
  };

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      // console.log(info.fileList[0], "infoofoof");
      handleUpload([info.fileList[0]?.originFileObj], "Latest Photograph");
    }
  };

  const uploaderProps = {
    name: "file",
    listType: "picture-card",
    accept: "image/*",
    maxCount: 1,
    fileList: fileList,
    beforeUpload: beforeUpload,
    onChange: handleChange,
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    },
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        const compressedFile = await getCompressedFile(file);
        imageUrlArr.push({
          file,
          compressedFile: compressedFile,
        });
        setImageUrlArr([...imageUrlArr]);
      } catch (error) {
        console.error("Upload Error:", error); // Log any errors
        onError(error);
      }
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const index_2 = imageUrlArr.findIndex(
        (item) => item.file?.uid === file.uid || item.uid === file.uid
      );
      if (index_2 > -1) {
        imageUrlArr.splice(index_2, 1);
        setImageUrlArr([...imageUrlArr]);
      }
      fileList.splice(index, 1);
      setFileList([...fileList]);
    },
  };

  const handleUpload = async (values, field) => {
    try {
      const file = values[0];
      const fileName = file.name.toLowerCase();
      const isValidFile = [".pdf", ".doc", ".docx", ".jpg", ".jpeg"].some(
        (ext) => fileName.endsWith(ext)
      );

      if (!isValidFile) {
        message.error("Invalid file format!"); // Or display the error message in another way
        return; // Exit from the function if the file is not valid
      }
      dispatch(showAuthLoader());
      const formData = new FormData();
      if (values) {
        if (field === "Latest Photograph") {
          formData.append("attachment", values?.[0], "emp_img");
        } else {
          formData.append("attachment", values?.[0], "other");
        }
      }
      setUploadedDocuments([...uploadedDocuments, formData.get("attachment")]);

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.uploadDocs,
        body: formData,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        setFileName(response.data.data);
        addOrUpdateLabelValue(field, response.data.data);
        setalluploadeddocs([
          ...alluploadeddocs,
          { [field]: response.data.data },
        ]);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  async function addOrUpdateLabelValue(doc_type, filename) {
    const existingItemIndex = uploadedData.findIndex(
      (item) => item.doc_type === doc_type
    );
    console.log(doc_type, existingItemIndex);

    if (existingItemIndex === -1) {
      setUploadedData([...uploadedData, { doc_type, filename }]);
    } else {
      const findandupdate = uploadedData?.map((c) => {
        if (c?.doc_type === doc_type) {
          return {
            doc_type,
            filename,
          };
        } else {
          return c;
        }
      });
      setUploadedData(findandupdate);
      //   uploadedData[existingItemIndex].filename = filename;
    }
  }
  const handleClickSubmit = async (values) => {
    try {  
      let photographExists = uploadedData.some(
        (item) =>
          item.doc_type === "Latest Photograph" && item.status !== "Rejected"
      );

      let latestPhotograph = uploadedData
        .filter((item) => item.doc_type === "Latest Photograph")
        .sort(/* sorting logic based on upload time */)[0];

      if (
        !photographExists ||
        (latestPhotograph && latestPhotograph.status === "Rejected")
      ) {
        message.error("Please upload latest photograph");
        return;
      }

      dispatch(showAuthLoader()); 
      const reqbody = {
        // name: values?.name,
        aadhar_number: values?.aadhar_number,
        pan_number: values?.pan_number,
        country: values?.country,
        state: values?.state,
        city: values?.city,
        district: values?.district,
        pin_code: values?.pin_code,
        address: values?.address,
       //
       Present_country: values?.PresentCountry,
        Present_state: values?.PresentState,
        Present_city: values?.PresentCity,
        Present_district: values?.PresentDistrict,
        Present_pin_code: values?.PresentPinCode,
        Present_address: values?.PresentAddress,
       // 
     
        bank_name: values?.bank_name,
        acc_holder_name: values?.acc_holder_name,
        bank_branch: values?.bank_branch,
        ifsc: values?.ifsc,
        acc_number: values?.acc_number,
        cheque_number: values?.cheque_number,
        documents: uploadedData,
        marital_status: values?.marital_status,
        gender: values?.gender,
        birthdate: values?.birthdate,
        mothername: values?.mothername,
        fathername: values?.fathername,
        hiddenTalent: values?.hiddenTalent,
      };
      const params = id; 
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.submiteveryDoc + "/" + token,
        body: reqbody,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        setFlag(3);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleSameAsAboveChange = (e) => {
    const checked = e.target.checked;
    setSameAsAbove(checked);
  
    if (checked) {
      const updatedFields = {
        PresentCountry: placeinfo.country || "",
        PresentState: placeinfo.state || "",
        PresentDistrict: placeinfo.district || "",
        PresentCity: placeinfo.city || "",
        PresentAddress: placeinfo.address || "",
        PresentPinCode: placeinfo.pinCode || "",
      };
  
      // Update state and form values
      setPlaceinfo((prev) => ({
        ...prev,
        ...updatedFields,
      }));
      form.setFieldsValue(updatedFields); // Explicitly set form fields
    } else {
      const clearedFields = {
        PresentCountry: "",
        PresentState: "",
        PresentDistrict: "",
        PresentCity: "",
        PresentAddress: "",
        PresentPinCode: "",
      };
  
      // Clear state and form values
      setPlaceinfo((prev) => ({
        ...prev,
        ...clearedFields,
      }));
      form.setFieldsValue(clearedFields); // Explicitly clear form fields
    }
  };
  
  
  

  const isAadharCardApproved = uploadedData?.some(
    (doc) => doc.doc_type === "aadhar_card" && doc.status === "Approved"
  );
  const isPanCardApproved = uploadedData?.some(
    (doc) => doc.doc_type === "pan_card" && doc.status === "Approved"
  );
  const isSallarySlipApproved = uploadedData?.some(
    (doc) => doc.doc_type === "salary_slip" && doc.status === "Approved"
  );
  const isBankStatementApproved = uploadedData?.some(
    (doc) => doc.doc_type === "bank_statement" && doc.status === "Approved"
  );
  const isAppraisalDoc = uploadedData?.some(
    (doc) => doc.doc_type === "last_appraisal_docs" && doc.status === "Approved"
  );
  const isEduCertficateApproved = uploadedData?.some(
    (doc) => doc.doc_type === "edu_certificate" && doc.status === "Approved"
  );
  const isEleBillApproved = uploadedData?.some(
    (doc) => doc.doc_type === "electricity_bill" && doc.status === "Approved"
  );
  const isPhotoApproved = uploadedData?.some(
    (doc) => doc.doc_type === "Latest Photograph" && doc.status === "Approved"
  );

  const uploadButton = (
    // <ImgCrop showGrid rotationSlider showReset>
      <Upload disabled={isPhotoApproved} {...uploaderProps}>
        {fileList.length < uploaderProps.maxCount && "+ Upload Image"}
      </Upload>
    // </ImgCrop>
  );

  return (
    <div className="onboarding-candidate">
      <div className="elsner_onboarding_header">
        <div className="logo">
          <a href="/">
            <img src={ElsnerLogo} alt="Elsner Logo" />
          </a>
        </div>
      </div>
      {flag === 1 && (
        <Card title="Upload Documents For Onboarding">
          <div className="employee-details">
            <Form
              name="basic"
              form={form}
              onFinish={handleClickSubmit}
              {...formItemLayout}
            >
              <div className="employee-main-information">
                <Row>
                  <Col sm={24} lg={12} xl={8}>
                    <div className="employee-info-1">
                      <Form.Item
                        label="First Name:"
                        name="first_name"
                        // disabled={true}
                      >
                        {/* <Input /> */}
                        {onBoardemployeeDetails?.first_name}
                      </Form.Item>
                      <Form.Item
                        label="Last Name:"
                        name="last_name"
                        // disabled={true}
                      >
                        {/* <Input /> */}
                        {onBoardemployeeDetails?.last_name}
                      </Form.Item>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Marital Status"
                        name="marital_status"
                        rules={[
                          {
                            required: true,
                            message: "Please select marital status!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="Married">Married</Option>
                          <Option value="Unmarried">Unmarried</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Birth Date"
                        name="birthdate"
                        rules={[
                          {
                            required: true,
                            message: "Please enter birth date!",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          disabledDate={(current) =>
                            current && current > moment().endOf("day")
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label="Aadhar Card"
                        name="aadhar_card"
                        rules={
                          isAadharCardApproved
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "Please upload Aadhar Card!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        <Input
                          disabled={isAadharCardApproved}
                          type="file"
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          onChange={(e) => {
                            handleUpload(e.target.files, "aadhar_card");
                            // setAttachmentFile(e.target.files);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Aadhar Card Number"
                        name="aadhar_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Aadhar Number!",
                          },
                          ({}) => ({
                            validator(_, value) {
                              const aadharNumber = value?.replace(/\s/g, "");
                              if (/[^0-9]/.test(aadharNumber)) {
                                return Promise.reject(
                                  "Aadhar Number should only contain numbers."
                                );
                              }
                              if (aadharNumber.length !== 12) {
                                return Promise.reject(
                                  "Aadhar Number must be 12 digits."
                                );
                              }
                              if (/^[01]/.test(aadharNumber)) {
                                return Promise.reject(
                                  "Aadhar Number should not start with 0 or 1."
                                );
                              }
                              if (!/^(\d{4}\s){2}\d{4}$/.test(value)) {
                                return Promise.reject("c");
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          maxLength={14}
                          placeholder="eg. 1234 1234 1234"
                          type="text"
                        />
                      </Form.Item>

                      <Form.Item
                        label="PAN Card "
                        name="pan_card"
                        rules={
                          isPanCardApproved
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "Please upload PAN Card!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        <Input
                          type="file"
                          disabled={isPanCardApproved}
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          onChange={(e) => {
                            handleUpload(e.target.files, "pan_card");
                            // setAttachmentFile(e.target.files);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="PAN Number"
                        name="pan_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter PAN number!",
                          },
                          {
                            pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                            message: "Invalid PAN number format!",
                          },
                          {
                            len: 10,
                            message: "PAN number must be 10 characters long!",
                          },
                          {
                            whitespace: true,
                            message:
                              "PAN number should not contain white spaces!",
                          },
                        ]}
                      >
                        <Input placeholder="eg. ABCTY1234D" />
                      </Form.Item>
{/* //Address */}
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Country",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          onChange={(name) => {
                            setPlaceinfo({ ...placeinfo, country: name });
                            // form.setFieldsValue({ state: "" });
                            Getstate(name);
                            // filtercountry(name);
                          }}
                          // optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {country?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?.name}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please Select State",
                          },
                        ]}
                      >
                        <Select
                          // ref={stateref}
                          disabled={placeinfo?.country ? false : true}
                          size="large"
                          showSearch
                          onChange={(state) => {
                            // form.setFieldsValue({ city: "" });
                            setPlaceinfo({ ...placeinfo, state: state });
                            Getcity(state);
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {state?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?.name}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="District"
                        name="district"
                        rules={[
                          {
                            required: true,
                            message: "Please Select District",
                          },
                          {
                            whitespace: true,
                            message: "District should not spaces!",
                          },
                          {
                            max: 20,
                            message:
                              "district should be less than 20 characters",
                          },
                        ]}
                        onChange={(e) => {
                          setPlaceinfo({
                            ...placeinfo,
                            district: e.target.value,
                          });
                        }}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="City/Village"
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please Select City/Village",
                          },
                        ]}
                      >
                        <Select
                          disabled={placeinfo?.state ? false : true}
                          size="large"
                          showSearch
                          onChange={(value) => {
                            setPlaceinfo({ ...placeinfo, city: value });
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {city?.map((item, index) => (
                            <Option
                              key={index}
                              value={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Address",
                          },
                          {
                            max: 50,
                            message:
                              "address should be less than 50 characters",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || typeof value !== "string") {
                                return Promise.resolve();
                              }

                              if (/<[a-z][\s\S]*>/i.test(value)) {
                                return Promise.reject(
                                  new Error("HTML tags are not allowed!")
                                );
                              }

                              if (/^\s*$/.test(value)) {
                                return Promise.reject(
                                  new Error("Only spaces are not allowed!")
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                        onChange={(e) => {
                          setPlaceinfo({
                            ...placeinfo,
                            address: e.target.value,
                          });
                        }}
                      >
                        <TextArea rows={3} />
                      </Form.Item>
                      <Form.Item
                        label="Pin Code"
                        name="pin_code"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Pin-code",
                          },
                          {
                            pattern: /^[0-9]{6}$/,
                            message:
                              "Invalid Pin Code! It should be a 6 digit number.",
                          },
                        ]}
                        onChange={(e) => {
                          setPlaceinfo({
                            ...placeinfo,
                            pinCode: e.target.value,
                          });
                        }}
                      >
                        <Input />
                      </Form.Item>

      <Form.Item>
        <Checkbox onChange={handleSameAsAboveChange} checked={sameAsAbove}>
        Same as Permanent Address
        </Checkbox>
      </Form.Item>
                      <Form.Item
                        label="Present Country"
                        name="PresentCountry"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Country",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          onChange={(name) => {
                            setPlaceinfo({ ...placeinfo, permanentCountry: name });
                            Getstate(name);
                          }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {country?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?.name}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Present State"
                        name="PresentState"
                        rules={[
                          {
                            required: true,
                            message: "Please Select State",
                          },
                        ]}
                      >
                        <Select
                          disabled={placeinfo?.permanentCountry ? false : true}
                          size="large"
                          showSearch
                          onChange={(state) => {
                            setPlaceinfo({ ...placeinfo, permanentState: state });
                            Getcity(state);
                          }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {state?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?.name}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Present District"
                        name="PresentDistrict"
                        rules={[
                          {
                            required: true,
                            message: "Please Select District",
                          },
                          {
                            whitespace: true,
                            message: "District should not have spaces!",
                          },
                          {
                            max: 20,
                            message: "District should be less than 20 characters",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            setPlaceinfo({
                              ...placeinfo,
                              permanentDistrict: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Present City/Village"
                        name="PresentCity"
                        rules={[
                          {
                            required: true,
                            message: "Please Select City/Village",
                          },
                        ]}
                      >
                        <Select
                          disabled={placeinfo?.permanentState ? false : true}
                          size="large"
                          showSearch
                          onChange={(value) => {
                            setPlaceinfo({ ...placeinfo, permanentCity: value });
                          }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {city?.map((item, index) => (
                            <Option
                              key={index}
                              value={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Present Address"
                        name="PresentAddress"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Address",
                          },
                          {
                            max: 50,
                            message: "Address should be less than 50 characters",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || typeof value !== "string") {
                                return Promise.resolve();
                              }

                              if (/<[a-z][\s\S]*>/i.test(value)) {
                                return Promise.reject(new Error("HTML tags are not allowed!"));
                              }

                              if (/^\s*$/.test(value)) {
                                return Promise.reject(new Error("Only spaces are not allowed!"));
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TextArea
                          rows={3}
                          onChange={(e) => {
                            setPlaceinfo({
                              ...placeinfo,
                              permanentAddress: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Present Pin Code"
                        name="PresentPinCode"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Pin Code",
                          },
                          {
                            pattern: /^[0-9]{6}$/,
                            message: "Invalid Pin Code! It should be a 6-digit number.",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            setPlaceinfo({
                              ...placeinfo,
                              permanentPinCode: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
      {/* Permanent Address Fields */}
      {/* <Form.Item
        label="Permanent Country"
        name="permanentCountry"
        rules={[{ required: true, message: "Please Select Country" }]}
      >
        <Select
          size="large"
          showSearch
          value={placeinfo.permanentCountry}
          disabled={sameAsAbove}
          onChange={(name) =>
            setPlaceinfo({ ...placeinfo, permanentCountry: name })
          }
        >
          {/* Options here */}
        {/* </Select>
      </Form.Item> */}

      {/* Similar code for Permanent State, District, City, Address, and Pin Code */}


                      {/* // */}
                    </div>
                  </Col>

                  <Col sm={12} lg={12} xl={9}>
                    <div className="employee-info">
                      {/* {experienceType === "Experience" && ( */}
                      <Form.Item
                        label="Father Name"
                        name="fathername"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Father Name!",
                          },
                          {
                            whitespace: true,
                            message: "father name should not be spaces!",
                          },
                          {
                            max: 20,
                            message:
                              "Father name should be less than 20 characters",
                          },
                          {
                            pattern: /^[A-Za-z\s]*$/,
                            message:
                              "Father name should only contain letters and spaces",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Mother Name"
                        name="mothername"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Mother Name!",
                          },
                          {
                            whitespace: true,
                            message: "mother name should not be spaces!",
                          },
                          {
                            max: 20,
                            message:
                              "Mother name should be less than 20 characters",
                          },
                          {
                            pattern: /^[A-Za-z\s]*$/,
                            message:
                              "Mother name should only contain letters and spaces",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Last month salary slip(exp. only)"
                        name="salary_slip"
                        rules={
                          isSallarySlipApproved ||
                          onBoardemployeeDetails.experience !== "Experience"
                            ? [
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                            : [
                                {
                                  required: true,
                                  message:
                                    "Please upload last month salary slip!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        <Input
                          type="file"
                          disabled={isSallarySlipApproved}
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          onChange={(e) => {
                            handleUpload(e.target.files, "salary_slip");
                          }}
                        />
                      </Form.Item>
                      {onBoardemployeeDetails.experience === "Experience" ? (
                        <Form.Item
                          label="Last Appraisal Documents"
                          name="last_appraisal_docs"
                          rules={
                            isAppraisalDoc ||
                            onBoardemployeeDetails.experience !== "Experience"
                              ? [
                                  () => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }

                                      const file = value;
                                      const fileName = file.toLowerCase();
                                      const isValidFile = [
                                        ".pdf",
                                        ".doc",
                                        ".docx",
                                        ".jpg",
                                        ".jpeg",
                                      ].some((ext) => fileName.endsWith(ext));
                                      console.log(fileName.endsWith());
                                      if (!isValidFile) {
                                        return Promise.reject(
                                          new Error(
                                            "Only .pdf, .doc, .docx files are accepted!"
                                          )
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]
                              : [
                                  () => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }

                                      const file = value;
                                      const fileName = file.toLowerCase();
                                      const isValidFile = [
                                        ".pdf",
                                        ".doc",
                                        ".docx",
                                        ".jpg",
                                        ".jpeg",
                                      ].some((ext) => fileName.endsWith(ext));
                                      console.log(fileName.endsWith());
                                      if (!isValidFile) {
                                        return Promise.reject(
                                          new Error(
                                            "Only .pdf, .doc, .docx files are accepted!"
                                          )
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]
                          }
                        >
                          <Input
                            type="file"
                            accept=".pdf, .doc, .docx, .jpg, .jpeg"
                            disabled={isAppraisalDoc}
                            onChange={(e) => {
                              handleUpload(
                                e.target.files,
                                "last_appraisal_docs"
                              );
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <></>
                      )}

                      <Form.Item
                        label="Bank Statement(Exp. only):"
                        name="bank_statement"
                        rules={
                          isBankStatementApproved ||
                          onBoardemployeeDetails.experience !== "Experience"
                            ? [
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                            : [
                                {
                                  required: true,
                                  message: "Please upload Bank Statement!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        <Input
                          type="file"
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          disabled={isBankStatementApproved}
                          onChange={(e) => {
                            handleUpload(e.target.files, "bank_statement");
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Bank Name"
                        name="bank_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter bank name!",
                          },
                          {
                            pattern: /^[A-Za-z\s]+$/,
                            message:
                              "Bank name should only contain alphabetic characters and spaces!",
                          },
                          {
                            whitespace: true,
                            message: "Bank name should not be spaces!",
                          },
                          {
                            max: 20,
                            message:
                              "Bank name should be less than 20 characters",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Account Holder Name"
                        name="acc_holder_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter account holder name!",
                          },
                          {
                            pattern: /^[A-Za-z\s]+$/,
                            message:
                              "Account holder name should only contain alphabetic characters and spaces!",
                          },
                          {
                            whitespace: true,
                            message: "acc. holder name should not be spaces!",
                          },
                          {
                            max: 30,
                            message:
                              "Acc. holder name should be less than 30 characters",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Bank Branch Name"
                        name="bank_branch"
                        rules={[
                          {
                            required: true,
                            message: "Please enter branch name!",
                          },
                          {
                            whitespace: true,
                            message: "branch name should not be spaces!",
                          },
                          {
                            max: 20,
                            message:
                              "Branch name should be less than 20 characters",
                          },
                          {
                            pattern: /^[A-Za-z\s]*$/,
                            message:
                              "Bank Branch name should only contain letters and spaces",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="IFSC Code"
                        name="ifsc"
                        rules={[
                          {
                            required: true,
                            message: "Please enter IFSC code!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Bank Account Number"
                        name="acc_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter account number!",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: "Please enter only numbers.",
                          },
                          {
                            min: 9,
                            max: 18,
                            message:
                              "Bank account number must be between 9 and 18 digits.",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: "Special characters are not allowed.",
                          },
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>

                      <Form.Item
                        label="Cheque Number"
                        name="cheque_number"
                        rules={[
                          onBoardemployeeDetails?.isCheque === "true"
                            ? {
                                required: true,
                                message: "Please enter cheque number!",
                              }
                            : {
                                pattern: /^[0-9]*$/,
                                message: "Please enter only numbers.",
                              },
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>

                      <Form.Item
                        label="Latest Education Certificate: "
                        name="edu_certificate"
                        rules={
                          isEduCertficateApproved
                            ? []
                            : [
                                {
                                  required: true,
                                  message:
                                    "Please upload Latest Education Certificate ",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        {/* <Upload type="primary">
                                    <Button  onClick={handleUpload} icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload> */}

                        <Input
                          type="file"
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          disabled={isEduCertficateApproved}
                          onChange={(e) => {
                            handleUpload(e.target.files, "edu_certificate");
                            // setAttachmentFile(e.target.files);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Electricity Bill "
                        name="electricity_bill"
                        rules={
                          isEleBillApproved
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "Please upload Electricity bill!",
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    const file = value;
                                    const fileName = file.toLowerCase();
                                    const isValidFile = [
                                      ".pdf",
                                      ".doc",
                                      ".docx",
                                      ".jpg",
                                      ".jpeg",
                                    ].some((ext) => fileName.endsWith(ext));
                                    console.log(fileName.endsWith());
                                    if (!isValidFile) {
                                      return Promise.reject(
                                        new Error(
                                          "Only .pdf, .doc, .docx files are accepted!"
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]
                        }
                      >
                        {/* <Upload type="primary">
                                    <Button  onClick={handleUpload} icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>  */}

                        <Input
                          type="file"
                          disabled={isEleBillApproved}
                          accept=".pdf, .doc, .docx, .jpg, .jpeg"
                          onChange={(e) => {
                            handleUpload(e.target.files, "electricity_bill");
                            // setAttachmentFile(e.target.files);
                          }}
                        />
                      </Form.Item>

                      {/* <Form.Item
                                            label="Cheque Number"
                                            name="Cheque_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please upload Cheque for cheque number!',
                                                },
                                            ]}
                                        >
                                            
                                            <Input
                                                type="file"
                                                onChange={(e) => {
                                                    handleUpload(e.target.files, 'Cheque_number')
                                                    // setAttachmentFile(e.target.files);
                                                }}
                                            />
                                        </Form.Item> */}
                      <Form.Item label="Any Hidden Talent" name="hiddenTalent">
                        <Input placeholder="e.g. singing, dancing, painting" />
                      </Form.Item>

                      <Form.Item
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please upload your latest photograph!",
                          },
                        ]}
                        label="Latest Photograph"
                      >
                        {uploadButton}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form.Item {...tailFormItemLayout} className="employee-btns">
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      )}
      {flag === 2 && (
        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            margin: "10px",
          }}
        >
          You have already uploaded the documents
        </div>
      )}
      {flag === 3 && (
        <div
          style={{
            color: "green",
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            margin: "10px",
          }}
        >
          Thank you for submitting documents
        </div>
      )}

      <div className="elsner_onboarding_footer">
        <p>{footerText}</p>
      </div>
    </div>
  );
};

export default UploadDocsCandidate;

export const personalDetailsTab = "personalDetailsTab";
export const empDetailsTab = "empDetailsTab";
export const documentTab = "documentTab";
export const contactTab = "contactTab";
export const bankingTab = "bankingTab";
export const additionalInfoTab = "additionalInfoTab";
export const employeeOverview = 'employeeOverview';

export const dataSource = [
  {
    key: "1",
    name: "01",
    documents: ["Aadhar Card"],
    comments: "----",
    expirydate: "12 Nov. 2024",
    status: "Pending",
  },
  {
    key: "2",
    name: "02",
    documents: ["PAN Card"],
    comments: "----",
    expirydate: "12 Nov. 2024",
    status: "Done",
  },
  {
    key: "3",
    name: "03",
    documents: ["Check Declaration"],
    comments: "----",
    expirydate: "12 Nov. 2024",
    status: "Done",
  },
  {
    key: "4",
    name: "04",
    documents: ["NDA"],
    comments: "----",
    expirydate: "12 Nov. 2024",
    status: "pending",
  },
];

export const contactData = [
  {
    name: "Smit Bharti",
    contactNo: 9988776655,
    relationship: "brother",
  },
  {
    name: "Rahul Mandaliya",
    contactNo: 8492948582,
    relationship: "brother",
  },
  {
    name: "Ravish Thakore",
    contactNo: 7838472839,
    relationship: "brother",
  },
  {
    name: "Akshay Bhavsar",
    contactNo: 9384582958,
    relationship: "brother",
  },
  {
    name: "Mihir Jadi",
    contactNo: 9482748847,
    relationship: "brother",
  },
  {
    name: "Shubham Sharma",
    contactNo: 7845905765,
    relationship: "brother",
  }
];

export const DocumentTypes = [
  {
    value: "Aadhar Card",
    label: "Aadhar Card",
  },
  {
    value: "Pan Card",
    label: "Pan Card",
  },
  {
    value: "Offer Letter",
    label: "Offer Letter",
  },
  {
    value: "Educational Certificate",
    label: "Educational Certificate",
  },
  {
    value: "Cheque Receipt (Optional)",
    label: "Cheque Receipt (Optional)",
  },
  {
    value: "Cheque Declaration (Optional)",
    label: "Cheque Declaration (Optional)",
  },
  {
    value: "Tenure Agreements (Optional)",
    label: "Tenure Agreements (Optional)",
  },
  {
    value: "NDA",
    label: "NDA",
  },
  {
    value: "Appointment Letter",
    label: "Appointment Letter",
  },
  {
    value: "PF Form 11",
    label: "PF Form 11",
  },
  {
    value: "Salary Slips - Previous Company (Optional)",
    label: "Salary Slips - Previous Company (Optional)",
  },
  {
    value: "Experience Letter - Previous Company (Optional)",
    label: "Experience Letter - Previous Company (Optional)",
  },
  {
    value: "Education Proof",
    label: "Education Proof",
  },
  {
    value: "Probation Confirmation Letter",
    label: "Probation Confirmation Letter",
  },
  {
    value: "Other Documents (Optional)",
    label: "Other Documents (Optional)",
  },
];

export const handleSpecialCharacters = (event) => {
  // do not allow special characters in input
  const charCode = event.which || event.keyCode;
  if (
    (charCode >= 65 && charCode <= 90) || // A-Z
    (charCode >= 97 && charCode <= 122) || // a-z
    (charCode >= 48 && charCode <= 57) // 0-9
  ) {
    return; // Allow alphanumeric characters
  }
  event.preventDefault(); // Prevent input of special characters
};


export const itiFields = ["Electrician ITI Fitter", "Automobile Draughtsman Civil", "Welder ITI Mechanic"]

export const diplomaFields = ['Automobile/Automotive engineering', 'Civil engineering', 'Computer engineering']

export const graduation = ['BE', 'Bsc']

export const subField = [
  {
    type: "BE",
    fields: ['Civil engineering', 'Computer engineering', 'Mechanical engineering', 'IT engineering']
  },
  {
    type: "Bsc",
    fields: ['Bsc Maths', 'Bsc IT', 'Bsc Chemical']
  }
]

export const subField2 = [
  {
    type: "ME",
    fields: ['Civil engineering', 'Computer engineering', 'Mechanical engineering', 'IT engineering']
  },
  {
    type: "MSc",
    fields: ['MSc Maths', 'MSc IT', 'MSc Chemical']
  }
]

export const relationshipArry = ['Brother', 'Sister', 'Father', 'Mother', 'Husband', 'Wife']

export const capitalizeFirstLetter = (value) => {
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
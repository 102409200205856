import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import SubMenu from "antd/lib/menu/SubMenu";
// import { SiHomebridge } from "react-icons/si";
import {


  FaHouseUser,
  FaUserCheck,
  FaUserAltSlash,
  FaUserCog,
  FaRegFileArchive,
  FaFileAlt,
  FaFileExport,
  FaFilePowerpoint,
  FaFileInvoice,
  FaUserFriends,
  FaChevronDown,
  FaChevronUp,
  FaRegSun,
  FaRandom,
  FaFantasyFlightGames,
  FaRegHandshake,
  FaRadiation,
  FaDatabase,
  FaComment,
  FaTachometerAlt,
  FaFax,
  FaDraftingCompass,
  FaPaperPlane,
} from "react-icons/fa";
import {
  BsFillFileEarmarkPersonFill,
  BsFillTicketDetailedFill,
  BsPersonWorkspace,
  BsFillAwardFill,
  BsFillFileEarmarkMedicalFill,
  BsClipboard2CheckFill,
  BsFillBagCheckFill,
} from "react-icons/bs";
import { HiOutlineDocumentReport, HiDocumentReport } from "react-icons/hi";
import {
  GiRank3,
  GiStairsGoal,
  GiOnTarget,
  GiProgression,
} from "react-icons/gi";
import { ImTicket, ImTree } from "react-icons/im";
import { AiOutlineDashboard } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import { MdOutlinePendingActions } from "react-icons/md";

import { getRoles } from "../../util/getRoles";
import config from "../../permission/config.json";
import { getResource } from "../../util/getResourcePermission";
// import { footerText } from "util/config";

// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const SidebarContent = ({
  sidebarCollapsed,
  setSidebarCollapsed,
  ...props
}) => {
  let { themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  let location = useLocation();
  let { authUser } = useSelector(({ auth }) => auth);

  const departmentArray = ["656eaa41679a64fd2b1dfa45", "656eaa41679a64fd2b1dfa47", "656eaa41679a64fd2b1dfa48", "656eaa41679a64fd2b1dfa5c"];
  const hasBillReimbursementAccess = departmentArray.includes(authUser?.subdepartment_id?._id);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const userdata = JSON.parse(localStorage.getItem("user_data"))


  const [openKeys, setOpenKeys] = useState([]);

  const [click10, setClick10] = useState(false);
  const [click11, setClick11] = useState(false);
  const [click12, setClick12] = useState(false);
  const [click13, setClick13] = useState(false);
  const [click14, setClick14] = useState(false);
  const [click15, setClick15] = useState(false);

  const handlesubmenu = (item) => {
    if (item.title == "Team Info") {
      setClick11(!click11);
      if (openKeys?.includes("sub16")) {
        setOpenKeys([]);
      } else {
        setOpenKeys(["sub16"]);
      }
    } else if (item.title == "Your Info") {
      setClick10(!click10);
      if (openKeys?.includes("sub15")) {
        setOpenKeys([]);
      } else {
        setOpenKeys(["sub15"]);
      }
    } else {
      setClick13(!click13);

      if (openKeys?.includes("sub18")) {
        setOpenKeys([]);
      } else {
        setOpenKeys(["sub18"]);
      }
    }
  };

  const User = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    {
      key: "manage-leave-application",
      path: "/leave-application",
      icon: <FaFileAlt />,
      title: "sidebar.leave-application",
      submenu: false,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      key: "resignation",
      path: "/resignation",
      icon: <BsPersonWorkspace />,
      title: "sidebar.resignation",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "Attendance Regularization",
      path: "/employee-attendence-regularization",
      icon: <FaUserCheck />,
      title: "sidebar.Attendence_Regularization",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    {
      key: "tickets",
      path: "/tickets",
      icon: <ImTicket />,
      title: "sidebar.tickets",
      submenu: false,
      permission: [config.RESOURCES.TICKET],
    },
    {
      key: "assets",
      path: "/user-assets",
      icon: <FaFileExport />,
      title: "sidebar.user-assets",
      submenu: false,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      key: "user-reports",
      path: "/user-reports",
      icon: <HiDocumentReport />,
      title: "sidebar.user-reports",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "individual-ranking",
      path: "/individual-rank",
      icon: <FaDraftingCompass />,
      title: "sidebar.individual-rank",
      submenu: false,
      permission: [config.RESOURCES.RANKING],
    },
    {
      key: "goals",
      path: "/goals",
      icon: <GiOnTarget />,
      title: "sidebar.create-goals",
      submenu: false,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      key: "Job Opening",
      path: "/jobs/openings",
      icon: <BsFillBagCheckFill />,
      title: "sidebar.admin-jobopening",
      submenu: false,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      key: "organization-policy",
      path: "/organization-policy",
      icon: <BsClipboard2CheckFill />,
      title: "sidebar.organization-policies",
      submenu: false,
      permission: [],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "employee-master",
      path: "/employee-master",
      icon: <FaHouseUser />,
      title: "sidebar.employee-master",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "pendingtask",
      path: "/pending-task-list",
      icon: <MdOutlinePendingActions />,
      title: "sidebar.pending-task",
      submenu: false,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },

    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "resignation-employee-list",
      path: "/resignation-employee-list",
      icon: <FaUserCheck />,
      title: "sidebar.resignation-employee-list",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    // departmentArray

    {
      key: "Bill Reimbursement",
      path: "/bill-reimbursement",
      icon: <HiDocumentReport />,
      title: "sidebar.bill-reimbursement",
      submenu: false,
      permission: [config.RESOURCES.BILLREIMBURSEMENT],
    }
  ];

  const Account = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    {
      key: "manage-leave-application",
      path: "/leave-application",
      icon: <FaFileAlt />,
      title: "sidebar.leave-application",
      submenu: false,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      key: "resignation",
      path: "/resignation",
      icon: <BsPersonWorkspace />,
      title: "sidebar.resignation",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "Attendance Regularization",
      path: "/employee-attendence-regularization",
      icon: <FaUserCheck />,
      title: "sidebar.Attendence_Regularization",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    {
      key: "tickets",
      path: "/tickets",
      icon: <ImTicket />,
      title: "sidebar.tickets",
      submenu: false,
      permission: [config.RESOURCES.TICKET],
    },
    {
      key: "assets",
      path: "/user-assets",
      icon: <FaFileExport />,
      title: "sidebar.user-assets",
      submenu: false,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      key: "user-reports",
      path: "/user-reports",
      icon: <HiDocumentReport />,
      title: "sidebar.user-reports",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "individual-ranking",
      path: "/individual-rank",
      icon: <FaDraftingCompass />,
      title: "sidebar.individual-rank",
      submenu: false,
      permission: [config.RESOURCES.RANKING],
    },
    {
      key: "goals",
      path: "/goals",
      icon: <GiOnTarget />,
      title: "sidebar.create-goals",
      submenu: false,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      key: "Job Opening",
      path: "/jobs/openings",
      icon: <BsFillBagCheckFill />,
      title: "sidebar.admin-jobopening",
      submenu: false,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      key: "organization-policy",
      path: "/organization-policy",
      icon: <BsClipboard2CheckFill />,
      title: "sidebar.organization-policies",
      submenu: false,
      permission: [],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "employee-master",
      path: "/employee-master",
      icon: <FaHouseUser />,
      title: "sidebar.employee-master",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "pendingtask",
      path: "/pending-task-list",
      icon: <MdOutlinePendingActions />,
      title: "sidebar.pending-task",
      submenu: false,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },

    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "resignation-employee-list",
      path: "/resignation-employee-list",
      icon: <FaUserCheck />,
      title: "sidebar.resignation-employee-list",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      className: "fas fa-ellipsis-h main-submenu",
      key: "sub18",
      title: "More",
      expandIcon: <FaChevronDown />,
      submenu: true,
      subMenuInfo: [
        {
          key: "subscription",
          path: "/subscription",
          icon: <HiDocumentReport />,
          title: "sidebar.subscription",
          submenu: false,
          permission: [config.RESOURCES.SUBSCRIPTION],
        },
        {
          key: "Bill Reimbursement",
          path: "/bill-reimbursement",
          icon: <HiDocumentReport />,
          title: "sidebar.bill-reimbursement",
          submenu: false,
          permission: [config.RESOURCES.BILLREIMBURSEMENT],
        }]
    }
  ];

  const Auditor = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    {
      key: "manage-leave-application",
      path: "/leave-application",
      icon: <FaFileAlt />,
      title: "sidebar.leave-application",
      submenu: false,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      key: "resignation",
      path: "/resignation",
      icon: <BsPersonWorkspace />,
      title: "sidebar.resignation",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "Attendance Regularization",
      path: "/employee-attendence-regularization",
      icon: <FaUserCheck />,
      title: "sidebar.Attendence_Regularization",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    {
      key: "tickets",
      path: "/tickets",
      icon: <ImTicket />,
      title: "sidebar.tickets",
      submenu: false,
      permission: [config.RESOURCES.TICKET],
    },
    {
      key: "assets",
      path: "/user-assets",
      icon: <FaFileExport />,
      title: "sidebar.user-assets",
      submenu: false,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      key: "user-reports",
      path: "/user-reports",
      icon: <HiDocumentReport />,
      title: "sidebar.user-reports",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "individual-ranking",
      path: "/individual-rank",
      icon: <FaDraftingCompass />,
      title: "sidebar.individual-rank",
      submenu: false,
      permission: [config.RESOURCES.RANKING],
    },
    {
      key: "goals",
      path: "/goals",
      icon: <GiOnTarget />,
      title: "sidebar.create-goals",
      submenu: false,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      key: "Job Opening",
      path: "/jobs/openings",
      icon: <BsFillBagCheckFill />,
      title: "sidebar.admin-jobopening",
      submenu: false,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      key: "organization-policy",
      path: "/organization-policy",
      icon: <BsClipboard2CheckFill />,
      title: "sidebar.organization-policies",
      submenu: false,
      permission: [],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },

    ...(getResource(config.RESOURCES.AUDIT_MASTER, ['read']) ||
      getResource(config.RESOURCES.AUDITS, ['read']) ||
      getResource(config.RESOURCES.AUDITS_PERFORMANCE, ['read']) ||
      getResource(config.RESOURCES.AUDIT_HEAD, ['read'])
      ?
      [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub20",
          title: "Audit",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "Audit Master",
              path: "/auditmaster",
              icon: <FaHouseUser />,
              title: "Audits Master",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_MASTER],
            },
            {
              key: "Audits",
              path: "/audits",
              icon: <FaFileInvoice />,
              title: "Audits",
              submenu: false,
              permission: [config.RESOURCES.AUDITS],
            },
            {
              key: "auditperformance",
              path: "/auditperformance",
              icon: <FaUserFriends />,
              title: "Audit Performance",
              submenu: false,
              permission: [config.RESOURCES.AUDITS_PERFORMANCE],
            },
            {
              key: "auditHead",
              path: "/auditHead",
              icon: <FaUserFriends />,
              title: "Audit Head",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_HEAD],
            },
          ],
        }
      ] : []
    ),
    {
      key: "employee-master",
      path: "/employee-master",
      icon: <FaHouseUser />,
      title: "sidebar.employee-master",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "pendingtask",
      path: "/pending-task-list",
      icon: <MdOutlinePendingActions />,
      title: "sidebar.pending-task",
      submenu: false,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },

    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },

    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },

    {
      key: "resignation-employee-list",
      path: "/resignation-employee-list",
      icon: <FaUserCheck />,
      title: "sidebar.resignation-employee-list",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
  ];

  const Reporting_Manager = [
    {
      key: "Admin Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.Admin_Dashboard",
      submenu: false,
      permission: [],
    },
    ...(getResource(config.RESOURCES.LEAVE, ['read']) ||
      getResource(config.RESOURCES.RANKING, ['read']) ||
      getResource(config.RESOURCES.ATTENDANCE_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.MY_ASSETS, ['read'])
      ?
      [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub15",
          title: "Your Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "manage-leave-application",
              path: "/leave-application",
              icon: <FaFileAlt />,
              title: "sidebar.leave-application",
              submenu: false,
              permission: [config.RESOURCES.LEAVE],
            },
            {
              key: "individual-ranking",
              path: "/individual-rank",
              icon: <FaDraftingCompass />,
              title: "sidebar.individual-rank",
              submenu: false,
              permission: [config.RESOURCES.RANKING],
            },
            {
              key: "Attendance Regularization",
              path: "/employee-attendence-regularization",
              icon: <FaUserCheck />,
              title: "sidebar.Attendence_Regularization",
              submenu: false,
              permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
            },
            {
              key: "assets",
              path: "/user-assets",
              icon: <FaFileExport />,
              title: "sidebar.user-assets",
              submenu: false,
              permission: [config.RESOURCES.MY_ASSETS],
            },
          ],
        }
      ] : []
    ),
    ...(getResource(config.RESOURCES.EMPLOYEE_MASTER, ['read']) ||
      getResource(config.RESOURCES.EMPLOYEE_IN_OUT, ['read']) ||
      getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.TICKET, ['read']) ||
      getResource(config.RESOURCES.TEAM_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.LEAVE_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.PENDING_TASK, ['read'])
      ? [
        {
          className: "fas fa-users main-submenu",
          key: "sub16",
          title: "Team Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "employee-master",
              path: "/employee-master",
              icon: <FaFileAlt />,
              title: "sidebar.employee-master",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_MASTER],
            },
            {
              key: "employee-in-out",
              path: "/employee-in-out",
              icon: <FaUserCheck />,
              title: "sidebar.Employee-In-Out",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
            },
            {
              key: "resignation-employee-list",
              path: "/resignation-employee-list",
              icon: <FaUserCheck />,
              title: "sidebar.resignation-employee-list",
              submenu: false,
              permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
            },
            {
              key: "tickets",
              path: "/tickets",
              icon: <ImTicket />,
              title: "sidebar.tickets",
              submenu: false,
              permission: [config.RESOURCES.TICKET],
            },
            {
              key: "ManageTeamRegularization",
              path: "/manage-team-regularization",
              icon: <FaUserFriends />,
              title: "sidebar.ManageTeamRegularization",
              submenu: false,
              permission: [config.RESOURCES.TEAM_REGULARIZATION],
            },
            {
              key: "user-leave-approval",
              path: "/user-leave-approve",
              icon: <FaFileExport />,
              title: "sidebar.user-leave-management",
              submenu: false,
              permission: [config.RESOURCES.LEAVE_MANAGEMENT],
            },
            {
              key: "pendingtask",
              path: "/pending-task-list",
              icon: <MdOutlinePendingActions />,
              title: "sidebar.pending-task",
              submenu: false,
              permission: [config.RESOURCES.PENDING_TASK],
            },
          ],
        }] : []),

    ...(userdata?._id == "63b6ca8870f2b5b3002cb446"
      ?
      [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub20",
          title: "Audit",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "Audit Master",
              path: "/auditmaster",
              icon: <FaHouseUser />,
              title: "Audits Master",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_MASTER],
            },
            {
              key: "Audits",
              path: "/audits",
              icon: <FaFileInvoice />,
              title: "Audits",
              submenu: false,
              permission: [config.RESOURCES.AUDITS],
            },
            {
              key: "auditperformance",
              path: "/auditperformance",
              icon: <FaUserFriends />,
              title: "Audit Performance",
              submenu: false,
              permission: [config.RESOURCES.AUDITS_PERFORMANCE],
            },
            {
              key: "auditHead",
              path: "/auditHead",
              icon: <FaUserFriends />,
              title: "Audit Head",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_HEAD],
            },
          ],
        }
      ] : []),
    {
      className: "fas fa-ellipsis-h main-submenu",
      key: "sub18",
      title: "More",
      expandIcon: <FaChevronDown />,
      submenu: true,
      subMenuInfo: [
        {
          key: "subscription",
          path: "/subscription",
          icon: <HiDocumentReport />,
          title: "sidebar.subscription",
          submenu: false,
          permission: [config.RESOURCES.SUBSCRIPTION],
        },
        {
          key: "Job Opening",
          path: "/jobs/openings",
          icon: <BsFillBagCheckFill />,
          title: "sidebar.admin-jobopening",
          submenu: false,
          permission: [config.RESOURCES.JOB_OPENING],
        },
        {
          key: "organization-policy",
          path: "/organization-policy",
          icon: <BsClipboard2CheckFill />,
          title: "sidebar.organization-policies",
          submenu: false,
          permission: [],
        },
        {
          key: "goals",
          path: "/goals",
          icon: <GiOnTarget />,
          title: "sidebar.create-goals",
          submenu: false,
          permission: [config.RESOURCES.MY_GOAL],
        },
        {
          key: "user-reports",
          path: "/user-reports",
          icon: <HiDocumentReport />,
          title: "sidebar.user-reports",
          submenu: false,
          permission: [config.RESOURCES.ATTENDANCE_REPORT],
        },

      ],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    // {
    //   key: "resignation",
    //   path: "/resignation",
    //   icon: <BsPersonWorkspace />,
    //   title: "sidebar.resignation",
    //   submenu: false,
    //   permission: [config.RESOURCES.SEPARATION],
    // },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    // {
    //   key: "admin-reports",
    //   path: "/admin-reports",
    //   icon: <BsFillFileEarmarkPersonFill />,
    //   title: "sidebar.admin-reports",
    //   submenu: false,
    //   permission: [config.RESOURCES.EMPLOYEE_REPORT],
    // },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
  ];

  const Assistant_Reporting_Manager = [
    {
      key: "Admin Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.Admin_Dashboard",
      submenu: false,
      permission: [],
    },
    ...(getResource(config.RESOURCES.LEAVE, ['read']) ||
      getResource(config.RESOURCES.SEPARATION, ['read']) ||
      getResource(config.RESOURCES.RANKING, ['read']) ||
      getResource(config.RESOURCES.ATTENDANCE_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.MY_ASSETS, ['read'])
      ? [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub15",
          title: "Your Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "manage-leave-application",
              path: "/leave-application",
              icon: <FaFileAlt />,
              title: "sidebar.leave-application",
              submenu: false,
              permission: [config.RESOURCES.LEAVE],
            },
            {
              key: "resignation",
              path: "/resignation",
              icon: <BsPersonWorkspace />,
              title: "sidebar.resignation",
              submenu: false,
              permission: [config.RESOURCES.SEPARATION],
            },
            {
              key: "individual-ranking",
              path: "/individual-rank",
              icon: <FaDraftingCompass />,
              title: "sidebar.individual-rank",
              submenu: false,
              permission: [config.RESOURCES.RANKING],
            },
            {
              key: "Attendance Regularization",
              path: "/employee-attendence-regularization",
              icon: <FaUserCheck />,
              title: "sidebar.Attendence_Regularization",
              submenu: false,
              permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
            },
            {
              key: "assets",
              path: "/user-assets",
              icon: <FaFileExport />,
              title: "sidebar.user-assets",
              submenu: false,
              permission: [config.RESOURCES.MY_ASSETS],
            },
          ],
        }
      ] : []),

    ...(getResource(config.RESOURCES.EMPLOYEE_MASTER, ['read']) ||
      getResource(config.RESOURCES.EMPLOYEE_IN_OUT, ['read']) ||
      getResource(config.RESOURCES.TICKET, ['read']) ||
      getResource(config.RESOURCES.TEAM_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.LEAVE_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.PENDING_TASK, ['read'])
      ? [
        {
          className: "fas fa-users main-submenu",
          key: "sub16",
          title: "Team Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "employee-master",
              path: "/employee-master",
              icon: <FaFileAlt />,
              title: "sidebar.employee-master",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_MASTER],
            },
            {
              key: "employee-in-out",
              path: "/employee-in-out",
              icon: <FaUserCheck />,
              title: "sidebar.Employee-In-Out",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
            },

            {
              key: "tickets",
              path: "/tickets",
              icon: <ImTicket />,
              title: "sidebar.tickets",
              submenu: false,
              permission: [config.RESOURCES.TICKET],
            },
            {
              key: "ManageTeamRegularization",
              path: "/manage-team-regularization",
              icon: <FaUserFriends />,
              title: "sidebar.ManageTeamRegularization",
              submenu: false,
              permission: [config.RESOURCES.TEAM_REGULARIZATION],
            },
            {
              key: "user-leave-approval",
              path: "/user-leave-approve",
              icon: <FaFileExport />,
              title: "sidebar.user-leave-management",
              submenu: false,
              permission: [config.RESOURCES.LEAVE_MANAGEMENT],
            },
            {
              key: "pendingtask",
              path: "/pending-task-list",
              icon: <MdOutlinePendingActions />,
              title: "sidebar.pending-task",
              submenu: false,
              permission: [config.RESOURCES.PENDING_TASK],
            },
          ],
        }
      ] : []),
    {
      className: "fas fa-ellipsis-h main-submenu",
      key: "sub18",
      title: "More",
      expandIcon: <FaChevronDown />,
      submenu: true,
      subMenuInfo: [
        {
          key: "Job Opening",
          path: "/jobs/openings",
          icon: <BsFillBagCheckFill />,
          title: "sidebar.admin-jobopening",
          submenu: false,
          permission: [config.RESOURCES.JOB_OPENING],
        },
        {
          key: "organization-policy",
          path: "/organization-policy",
          icon: <BsClipboard2CheckFill />,
          title: "sidebar.organization-policies",
          submenu: false,
          permission: [],
        },
        {
          key: "goals",
          path: "/goals",
          icon: <GiOnTarget />,
          title: "sidebar.create-goals",
          submenu: false,
          permission: [config.RESOURCES.MY_GOAL],
        },
        {
          key: "user-reports",
          path: "/user-reports",
          icon: <HiDocumentReport />,
          title: "sidebar.user-reports",
          submenu: false,
          permission: [config.RESOURCES.ATTENDANCE_REPORT],
        },
      ],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    // {
    //   key: "MY-IN-OUT",
    //   path: "/my-in-out",
    //   icon: <FaHouseUser />,
    //   title: "sidebar.MY-IN-OUT",
    //   submenu: false,
    //   permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    // },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
  ];

  const Network_Team = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    // {
    //   key: "MY-IN-OUT",
    //   path: "/my-in-out",
    //   icon: <FaHouseUser />,
    //   title: "sidebar.MY-IN-OUT",
    //   submenu: false,
    //   permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    // },
    {
      key: "manage-leave-application",
      path: "/leave-application",
      icon: <FaFileAlt />,
      title: "sidebar.leave-application",
      submenu: false,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      key: "resignation",
      path: "/resignation",
      icon: <BsPersonWorkspace />,
      title: "sidebar.resignation",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "Attendance Regularization",
      path: "/employee-attendence-regularization",
      icon: <FaUserCheck />,
      title: "sidebar.Attendence_Regularization",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    {
      key: "tickets",
      path: "/tickets",
      icon: <ImTicket />,
      title: "sidebar.tickets",
      submenu: false,
      permission: [config.RESOURCES.TICKET],
    },
    {
      key: "assets",
      path: "/user-assets",
      icon: <FaFileExport />,
      title: "sidebar.user-assets",
      submenu: false,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      key: "user-reports",
      path: "/user-reports",
      icon: <HiDocumentReport />,
      title: "sidebar.user-reports",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "individual-ranking",
      path: "/individual-rank",
      icon: <FaDraftingCompass />,
      title: "sidebar.individual-rank",
      submenu: false,
      permission: [config.RESOURCES.RANKING],
    },
    {
      key: "goals",
      path: "/goals",
      icon: <GiOnTarget />,
      title: "sidebar.create-goals",
      submenu: false,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      key: "Job Opening",
      path: "/jobs/openings",
      icon: <BsFillBagCheckFill />,
      title: "sidebar.admin-jobopening",
      submenu: false,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      key: "organization-policy",
      path: "/organization-policy",
      icon: <BsClipboard2CheckFill />,
      title: "sidebar.organization-policies",
      submenu: false,
      permission: [],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "employee-master",
      path: "/employee-master",
      icon: <FaHouseUser />,
      title: "sidebar.employee-master",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "pendingtask",
      path: "/pending-task-list",
      icon: <MdOutlinePendingActions />,
      title: "sidebar.pending-task",
      submenu: false,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },

    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "resignation-employee-list",
      path: "/resignation-employee-list",
      icon: <FaUserCheck />,
      title: "sidebar.resignation-employee-list",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },

  ];

  const HR = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    ...getResource(config.RESOURCES.EMPLOYEE_IN_OUT, ['read']) ||
      getResource(config.RESOURCES.LEAVE, ['read']) ||
      getResource(config.RESOURCES.RANKING, ['read']) ||
      getResource(config.RESOURCES.MY_ASSETS, ['read'])
      ? [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub15",
          title: "Your Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "MY-IN-OUT",
              path: "/my-in-out",
              icon: <FaHouseUser />,
              title: "sidebar.MY-IN-OUT",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
            },
            {
              key: "manage-leave-application",
              path: "/leave-application",
              icon: <FaFileAlt />,
              title: "sidebar.leave-application",
              submenu: false,
              permission: [config.RESOURCES.LEAVE],
            },
            {
              key: "individual-ranking",
              path: "/individual-rank",
              icon: <FaDraftingCompass />,
              title: "sidebar.individual-rank",
              submenu: false,
              permission: [config.RESOURCES.RANKING],
            },
            {
              key: "assets",
              path: "/user-assets",
              icon: <FaFileExport />,
              title: "sidebar.user-assets",
              submenu: false,
              permission: [config.RESOURCES.MY_ASSETS],
            },

            {
              key: "Attendance Regularization",
              path: "/employee-attendence-regularization",
              icon: <FaUserCheck />,
              title: "sidebar.Attendence_Regularization",
              submenu: false,
              permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
            },

          ]
        }
      ] : [],

    ...getResource(config.RESOURCES.EMPLOYEE_MASTER, ['read']) ||
      getResource(config.RESOURCES.EMPLOYEE_IN_OUT, ['read']) ||
      getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.EMPLOYEE_REPORT, ['read']) ||
      getResource(config.RESOURCES.TICKET, ['read']) ||
      getResource(config.RESOURCES.ADMIN_LEAVE_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.PENDING_TASK, ['read']) ||
      getResource(config.RESOURCES.ADMIN_REGULARIZATION, ['read'])
      ? [
        {
          className: "fas fa-users main-submenu",
          key: "sub16",
          title: "Team Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "employee-master",
              path: "/employee-master",
              icon: <FaHouseUser />,
              title: "sidebar.employee-master",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_MASTER],
            },
            {
              key: "employee-in-out",
              path: "/employee-in-out",
              icon: <FaUserCheck />,
              title: "sidebar.Employee-In-Out",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
            },
            {
              key: "resignation-employee-list",
              path: "/resignation-employee-list",
              icon: <FaUserCheck />,
              title: "sidebar.resignation-employee-list",
              submenu: false,
              permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
            },
            {
              key: "admin-reports",
              path: "/admin-reports",
              icon: <BsFillFileEarmarkPersonFill />,
              title: "sidebar.admin-reports",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_REPORT],
            },
            {
              key: "tickets",
              path: "/tickets",
              icon: <ImTicket />,
              title: "sidebar.tickets",
              submenu: false,
              permission: [config.RESOURCES.TICKET],
            },
            {
              key: "admin-leave-approval",
              path: "/admin-leave-approval",
              icon: <FaFileExport />,
              title: "sidebar.admin-leave-management",
              submenu: false,
              permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
            },

            {
              key: "pendingtask",
              path: "/pending-task-list",
              icon: <MdOutlinePendingActions />,
              title: "sidebar.pending-task",
              submenu: false,
              permission: [config.RESOURCES.PENDING_TASK],
            },
            {
              key: "admin-regularizatrion",
              path: "/admin-regularizatrion",
              icon: <FaUserCog />,
              title: "sidebar.admin-regularizatrion",
              submenu: false,
              permission: [config.RESOURCES.ADMIN_REGULARIZATION],
            },
          ]
        }
      ] : [],

    ...getResource(config.RESOURCES.ACHIEVEMENTS, ['read']) ||
      getResource(config.RESOURCES.SETTINGS, ['read']) ||
      getResource(config.RESOURCES.DEPARTMENTS, ['read']) ||
      getResource(config.RESOURCES.DESIGNATION, ['read']) ||
      getResource(config.RESOURCES.HOLIDAYS, ['read'])
      ?
      [
        {
          className: "fas fa-chart-line main-submenu",
          key: "sub17",
          title: "Management",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "achivements",
              path: "/achivements",
              icon: <BsFillAwardFill />,
              title: "sidebar.achivements",
              submenu: false,
              permission: [config.RESOURCES.ACHIEVEMENTS],
            },
            {
              key: "permission",
              path: "/permission-manager",
              icon: <BsFillAwardFill />,
              title: "sidebar.permission",
              submenu: false,
              permission: [config.RESOURCES.SETTINGS],
            },
            {
              key: "admin-department",
              path: "/admin-department",
              icon: <FaUserCog />,
              title: "sidebar.Department",
              submenu: false,
              permission: [config.RESOURCES.DEPARTMENTS],
            },
            {
              key: "admin-designation",
              path: "/admin-designation",
              icon: <FaUserCog />,
              title: "sidebar.designation",
              submenu: false,
              permission: [config.RESOURCES.DESIGNATION],
            },

            {
              key: "holidays",
              path: "/holidays",
              icon: <FaRegFileArchive />,
              title: "sidebar.holidays",
              submenu: false,
              permission: [config.RESOURCES.HOLIDAYS],
            },

          ]
        }
      ] : [],

    ...(getResource(config.RESOURCES.ON_BOARDING_INITIATION, ['read']) ||
      getResource(config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST, ['read']) ||
      getResource(config.RESOURCES.NEW_JOINEES, ['read']) ?
      [
        {
          className: "fas fa-user-friends main-submenu",
          key: "sub19",
          title: "Onboarding",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "initiation",
              path: "/onboarding-initiation",
              icon: <FaPaperPlane />,
              title: "sidebar.onboarding-initiation",
              submenu: false,
              permission: [config.RESOURCES.ON_BOARDING_INITIATION],
            },
            {
              key: "doc-approve-list",
              path: "/doc-approve-list",
              icon: <FaRegFileArchive />,
              title: "sidebar.doc-approve-list",
              submenu: false,
              permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
            },
            {
              key: "new-joinees-list",
              path: "/new-joinees-list",
              icon: <FaUserFriends />,
              title: "sidebar.new-joinees-list",
              submenu: false,
              permission: [config.RESOURCES.NEW_JOINEES],
            },
          ],
        }
      ] : []),





    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    ...(getResource(config.RESOURCES.AUDIT_MASTER, ['read']) ||
      getResource(config.RESOURCES.AUDITS, ['read']) ||
      getResource(config.RESOURCES.AUDITS_PERFORMANCE, ['read']) ||
      getResource(config.RESOURCES.AUDIT_HEAD, ['read'])
      ?
      [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub20",
          title: "Audit",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "Audit",
              path: "/audit-history",
              icon: <FaFileInvoice />,
              title: "Audit History",
              submenu: false,
              permission: [config.RESOURCES.AUDITS],
            },
            {
              key: "Audit Master",
              path: "/auditmaster",
              icon: <FaHouseUser />,
              title: "Audits Master",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_MASTER],
            },
            {
              key: "Audits",
              path: "/audits",
              icon: <FaFileInvoice />,
              title: "Audits",
              submenu: false,
              permission: [config.RESOURCES.AUDITS],
            },
            {
              key: "auditperformance",
              path: "/auditperformance",
              icon: <FaUserFriends />,
              title: "Audit Performance",
              submenu: false,
              permission: [config.RESOURCES.AUDITS_PERFORMANCE],
            },
            {
              key: "auditHead",
              path: "/auditHead",
              icon: <FaUserFriends />,
              title: "Audit Head",
              submenu: false,
              permission: [config.RESOURCES.AUDIT_HEAD],
            },

          ],
        }
      ] : []
    ),

    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },


    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },

    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },

    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    ...getResource(config.RESOURCES.POLICIES, ['read']) ||
      getResource(config.RESOURCES.MY_GOAL, ['read']) ||
      getResource(config.RESOURCES.JOB_OPENING, ['read']) ||
      getResource(config.RESOURCES.BILLREIMBURSEMENT, ['read'])
      ? [
        {
          className: "fas fa-ellipsis-h main-submenu",
          key: "sub18",
          title: "More",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [

            {
              key: "policies",
              path: "/policies",
              icon: <BsFillFileEarmarkMedicalFill />,
              title: "sidebar.policies",
              submenu: false,
              permission: [config.RESOURCES.POLICIES],
            },

            {
              key: "goals",
              path: "/goals",
              icon: <GiOnTarget />,
              title: "sidebar.create-goals",
              submenu: false,
              permission: [config.RESOURCES.MY_GOAL],
            },
            {
              key: "Job Opening",
              path: "/jobs/openings",
              icon: <BsFillBagCheckFill />,
              title: "sidebar.admin-jobopening",
              submenu: false,
              permission: [config.RESOURCES.JOB_OPENING],
            },
            {
              key: "leave-master",
              path: "/admin-leave-master",
              icon: <FaUserCog />,
              title: "sidebar.admin-leave-master",
              submenu: false,
              permission: [config.RESOURCES.LEAVE_MASTER],
            },
            {
              key: "employee-leave-balance",
              path: "/employee-leave-balance",
              icon: <FaHouseUser />,
              title: "sidebar.employee-leave-balance",
              submenu: false,
              permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
            },
            {
              key: "Bill Reimbursement",
              path: "/bill-reimbursement",
              icon: <HiDocumentReport />,
              title: "sidebar.bill-reimbursement",
              submenu: false,
              permission: [config.RESOURCES.BILLREIMBURSEMENT],
            }
          ]
        }
      ] : [],

  ];

  const Intern = [
    {
      key: "User Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.User_Dashboard",
      submenu: false,
      permission: [],
    },
    {
      key: "manage-leave-application",
      path: "/leave-application",
      icon: <FaFileAlt />,
      title: "sidebar.leave-application",
      submenu: false,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      key: "resignation",
      path: "/resignation",
      icon: <BsPersonWorkspace />,
      title: "sidebar.resignation",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "Attendance Regularization",
      path: "/employee-attendence-regularization",
      icon: <FaUserCheck />,
      title: "sidebar.Attendence_Regularization",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    {
      key: "tickets",
      path: "/tickets",
      icon: <ImTicket />,
      title: "sidebar.tickets",
      submenu: false,
      permission: [config.RESOURCES.TICKET],
    },
    {
      key: "assets",
      path: "/user-assets",
      icon: <FaFileExport />,
      title: "sidebar.user-assets",
      submenu: false,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      key: "user-reports",
      path: "/user-reports",
      icon: <HiDocumentReport />,
      title: "sidebar.user-reports",
      submenu: false,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "individual-ranking",
      path: "/individual-rank",
      icon: <FaDraftingCompass />,
      title: "sidebar.individual-rank",
      submenu: false,
      permission: [config.RESOURCES.RANKING],
    },
    {
      key: "goals",
      path: "/goals",
      icon: <GiOnTarget />,
      title: "sidebar.create-goals",
      submenu: false,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      key: "Job Opening",
      path: "/jobs/openings",
      icon: <BsFillBagCheckFill />,
      title: "sidebar.admin-jobopening",
      submenu: false,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      key: "organization-policy",
      path: "/organization-policy",
      icon: <BsClipboard2CheckFill />,
      title: "sidebar.organization-policies",
      submenu: false,
      permission: [],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "employee-master",
      path: "/employee-master",
      icon: <FaHouseUser />,
      title: "sidebar.employee-master",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "pendingtask",
      path: "/pending-task-list",
      icon: <MdOutlinePendingActions />,
      title: "sidebar.pending-task",
      submenu: false,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },

    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "employee-in-out",
      path: "/employee-in-out",
      icon: <FaUserCheck />,
      title: "sidebar.Employee-In-Out",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      key: "resignation-employee-list",
      path: "/resignation-employee-list",
      icon: <FaUserCheck />,
      title: "sidebar.resignation-employee-list",
      submenu: false,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      key: "ManageTeamRegularization",
      path: "/manage-team-regularization",
      icon: <FaUserFriends />,
      title: "sidebar.ManageTeamRegularization",
      submenu: false,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      key: "user-leave-approval",
      path: "/user-leave-approve",
      icon: <FaFileExport />,
      title: "sidebar.user-leave-management",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
  ];
  const Recruiter = [
    {
      key: "Admin Dashboard",
      path: "/dashboard",
      icon: <AiOutlineDashboard />,
      title: "sidebar.Admin_Dashboard",
      submenu: false,
      permission: [],
    },
    ...(getResource(config.RESOURCES.LEAVE, ['read']) ||
      getResource(config.RESOURCES.SEPARATION, ['read']) ||
      getResource(config.RESOURCES.RANKING, ['read']) ||
      getResource(config.RESOURCES.ATTENDANCE_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.MY_ASSETS, ['read'])
      ? [
        {
          className: "fas fa-info-circle main-submenu",
          key: "sub15",
          title: "Your Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "manage-leave-application",
              path: "/leave-application",
              icon: <FaFileAlt />,
              title: "sidebar.leave-application",
              submenu: false,
              permission: [config.RESOURCES.LEAVE],
            },
            {
              key: "resignation",
              path: "/resignation",
              icon: <BsPersonWorkspace />,
              title: "sidebar.resignation",
              submenu: false,
              permission: [config.RESOURCES.SEPARATION],
            },
            {
              key: "individual-ranking",
              path: "/individual-rank",
              icon: <FaDraftingCompass />,
              title: "sidebar.individual-rank",
              submenu: false,
              permission: [config.RESOURCES.RANKING],
            },
            {
              key: "Attendance Regularization",
              path: "/employee-attendence-regularization",
              icon: <FaUserCheck />,
              title: "sidebar.Attendence_Regularization",
              submenu: false,
              permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
            },
            {
              key: "assets",
              path: "/user-assets",
              icon: <FaFileExport />,
              title: "sidebar.user-assets",
              submenu: false,
              permission: [config.RESOURCES.MY_ASSETS],
            },
          ],
        }
      ] : []),

    ...(getResource(config.RESOURCES.EMPLOYEE_MASTER, ['read']) ||
      getResource(config.RESOURCES.EMPLOYEE_IN_OUT, ['read']) ||
      getResource(config.RESOURCES.TICKET, ['read']) ||
      getResource(config.RESOURCES.TEAM_REGULARIZATION, ['read']) ||
      getResource(config.RESOURCES.LEAVE_MANAGEMENT, ['read']) ||
      getResource(config.RESOURCES.PENDING_TASK, ['read'])
      ? [
        {
          className: "fas fa-users main-submenu",
          key: "sub16",
          title: "Team Info",
          expandIcon: <FaChevronDown />,
          submenu: true,
          subMenuInfo: [
            {
              key: "employee-master",
              path: "/employee-master",
              icon: <FaFileAlt />,
              title: "sidebar.employee-master",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_MASTER],
            },
            {
              key: "employee-in-out",
              path: "/employee-in-out",
              icon: <FaUserCheck />,
              title: "sidebar.Employee-In-Out",
              submenu: false,
              permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
            },

            {
              key: "tickets",
              path: "/tickets",
              icon: <ImTicket />,
              title: "sidebar.tickets",
              submenu: false,
              permission: [config.RESOURCES.TICKET],
            },
            {
              key: "ManageTeamRegularization",
              path: "/manage-team-regularization",
              icon: <FaUserFriends />,
              title: "sidebar.ManageTeamRegularization",
              submenu: false,
              permission: [config.RESOURCES.TEAM_REGULARIZATION],
            },
            {
              key: "user-leave-approval",
              path: "/user-leave-approve",
              icon: <FaFileExport />,
              title: "sidebar.user-leave-management",
              submenu: false,
              permission: [config.RESOURCES.LEAVE_MANAGEMENT],
            },
            {
              key: "pendingtask",
              path: "/pending-task-list",
              icon: <MdOutlinePendingActions />,
              title: "sidebar.pending-task",
              submenu: false,
              permission: [config.RESOURCES.PENDING_TASK],
            }
          ],
        }
      ] : []),

    {
      key: "Audits",
      path: "/audit-history",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [],
    },
    {
      key: "organisationtree",
      path: "/organization-view",
      icon: <ImTree />,
      title: "sidebar.organisation-tree",
      submenu: false,
      permission: [],
    },
    // {
    //   key: "MY-IN-OUT",
    //   path: "/my-in-out",
    //   icon: <FaHouseUser />,
    //   title: "sidebar.MY-IN-OUT",
    //   submenu: false,
    //   permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    // },
    {
      key: "last-working-day-emps",
      path: "/last-working-day-emps",
      icon: <BsPersonWorkspace />,
      title: "Last Working Day List",
      submenu: false,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      key: "admin-reports",
      path: "/admin-reports",
      icon: <BsFillFileEarmarkPersonFill />,
      title: "sidebar.admin-reports",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      key: "inventory",
      path: "/inventory",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.user-assetstabpane",
      submenu: false,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      key: "managetickets",
      path: "/manage-tickets",
      icon: <BsFillTicketDetailedFill />,
      title: "sidebar.manage-tickets",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      key: "Audit Master",
      path: "/auditmaster",
      icon: <FaHouseUser />,
      title: "Audit Master",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "auditperformance",
      path: "/auditperformance",
      icon: <FaUserFriends />,
      title: "Audit Performance",
      submenu: false,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      key: "auditHead",
      path: "/audithead",
      icon: <FaUserFriends />,
      title: "Audit Head",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      key: "admin-leave-approval",
      path: "/admin-leave-approval",
      icon: <FaFileExport />,
      title: "sidebar.admin-leave-management",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      key: "admin-regularizatrion",
      path: "/admin-regularizatrion",
      icon: <FaUserCog />,
      title: "sidebar.admin-regularizatrion",
      submenu: false,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      key: "achivements",
      path: "/achivements",
      icon: <BsFillAwardFill />,
      title: "sidebar.achivements",
      submenu: false,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      key: "permission",
      path: "/permission-manager",
      icon: <BsFillAwardFill />,
      title: "sidebar.permission",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "admin-department",
      path: "/admin-department",
      icon: <FaUserCog />,
      title: "sidebar.Department",
      submenu: false,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      key: "admin-designation",
      path: "/admin-designation",
      icon: <FaUserCog />,
      title: "sidebar.designation",
      submenu: false,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      key: "leave-master",
      path: "/admin-leave-master",
      icon: <FaUserCog />,
      title: "sidebar.admin-leave-master",
      submenu: false,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      key: "employee-leave-balance",
      path: "/employee-leave-balance",
      icon: <FaHouseUser />,
      title: "sidebar.employee-leave-balance",
      submenu: false,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      key: "initiation",
      path: "/onboarding-initiation",
      icon: <FaPaperPlane />,
      title: "sidebar.onboarding-initiation",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      key: "doc-approve-list",
      path: "/doc-approve-list",
      icon: <FaRegFileArchive />,
      title: "sidebar.doc-approve-list",
      submenu: false,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      key: "new-joinees-list",
      path: "/new-joinees-list",
      icon: <FaUserFriends />,
      title: "sidebar.new-joinees-list",
      submenu: false,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      key: "holidays",
      path: "/holidays",
      icon: <FaRegFileArchive />,
      title: "sidebar.holidays",
      submenu: false,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      key: "policies",
      path: "/policies",
      icon: <BsFillFileEarmarkMedicalFill />,
      title: "sidebar.policies",
      submenu: false,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      key: "Audits",
      path: "/audits",
      icon: <FaFileInvoice />,
      title: "Audits",
      submenu: false,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      key: "appsettings",
      path: "/app-setting",
      icon: <FaRegSun />,
      title: "sidebar.app-setting",
      submenu: false,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      key: "view-employee-details",
      path: "/viewEmployeeDetails",
      icon: <FaHouseUser />,
      title: "sidebar.employee-details",
      submenu: false,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    {
      className: "fas fa-ellipsis-h main-submenu",
      key: "sub18",
      title: "More",
      expandIcon: <FaChevronDown />,
      submenu: true,
      subMenuInfo: [
        {
          key: "Job Opening",
          path: "/jobs/openings",
          icon: <BsFillBagCheckFill />,
          title: "sidebar.admin-jobopening",
          submenu: false,
          permission: [config.RESOURCES.JOB_OPENING],
        },
        {
          key: "organization-policy",
          path: "/organization-policy",
          icon: <BsClipboard2CheckFill />,
          title: "sidebar.organization-policies",
          submenu: false,
          permission: [],
        },
        {
          key: "goals",
          path: "/goals",
          icon: <GiOnTarget />,
          title: "sidebar.create-goals",
          submenu: false,
          permission: [config.RESOURCES.MY_GOAL],
        },
        {
          key: "user-reports",
          path: "/user-reports",
          icon: <HiDocumentReport />,
          title: "sidebar.user-reports",
          submenu: false,
          permission: [config.RESOURCES.ATTENDANCE_REPORT],
        },
      ],
    }
  ];

  // useEffect(() => {
  //   if (window.location.pathname == "/organization-view") {
  //     setSidebarCollapsed(true);
  //     setOpenKeys([]);
  //   } else {
  //     setSidebarCollapsed(true);
  //   }
  // }, [window.location.pathname]);


  return (
    <>
      <Sider collapsible collapsed={ sidebarCollapsed } onCollapse={ setSidebarCollapsed } className="Sidebar">
        <SidebarLogo />

        { getRoles(["Admin"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">


              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                // onOpenChange={ (e) => { } }
              >
                <Menu.Item
                  // title="Admin Dashboard"
                  title={ <span><IntlMessages id="Dashboard" /></span> }
                  key="Admin Dashboard"
                  className={
                    location.pathname?.includes("/dashboard")
                      ? "ant-menu-item-active"
                      : ""
                  }
                >
                  <NavLink to="/dashboard">
                    <AiOutlineDashboard />
                    <span>
                      <IntlMessages id="sidebar.Admin_Dashboard" />
                    </span>
                  </NavLink>
                </Menu.Item>

                { getRoles(["Admin"]) && (
                  <SubMenu
                    // onTitleMouseLeave={ () => {
                    //   if (sidebarCollapsed && openKeys?.includes("sub15")) {
                    //     setOpenKeys([]);
                    //   }
                    // } }
                    // onTitleMouseEnter={ () => {
                    //   if (sidebarCollapsed && !openKeys?.includes("sub15")) {
                    //     setOpenKeys(["sub15"]);
                    //   }
                    // } }
                    className="fas fa-info-circle main-submenu"
                    key="sub15"
                    title="Your Info"
                    // expandIcon={click10 ? <FaChevronUp /> : <FaChevronDown />}
                    expandIcon={ <FaChevronDown /> }
                    onTitleClick={() => setOpenKeys(openKeys.includes("sub15") ? [] : ["sub15"])}
                  >
                    <Menu.Item
                      // title="MY-IN-OUT"
                      title={ <span><IntlMessages id="MY-IN-OUT" /></span> }
                      key="MY-IN-OUT"
                      className={
                        location.pathname?.includes("/my-in-out")
                          ? "ant-menu-item-active "
                          : ""
                      }
                    >
                      <NavLink to="/my-in-out">
                        <FaHouseUser />
                        <span>
                          <IntlMessages id="sidebar.MY-IN-OUT" />
                        </span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      title={ <span><IntlMessages id="sidebar.leave-application" /></span> }
                      key="manage-leave-application"
                      className={
                        location.pathname?.includes("/leave-application")
                          ? "ant-menu-item-active"
                          : ""
                      }
                    >
                      <NavLink to="/leave-application">
                        <FaFileAlt />
                        <span>
                          <IntlMessages id="sidebar.leave-application" />
                        </span>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item
                      title={ <span><IntlMessages id="sidebar.individual-rank" /></span> }
                      key="individual-ranking"
                      className={
                        location.pathname?.includes("/individual-rank")
                          ? "ant-menu-item-active"
                          : ""
                      }
                    >
                      <NavLink to="/individual-rank">
                        <FaDraftingCompass />
                        <span>
                          <IntlMessages id="sidebar.individual-rank" />
                        </span>
                      </NavLink>
                    </Menu.Item>
                    {/* SIDEBAR KPI  */ }
                    {/* <Menu.Item
                        key="testkpis"
                        className={
                          location.pathname?.includes("/Kpis")
                            ? // ||
                            // location.pathname?.includes("/user-view-kpi")
                            // location.pathname?.includes("/tickets")
                            "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to="/Kpis">
                          <GiStairsGoal />
                          <span>
                            <IntlMessages id="sidebar.test-kpis" />
                          </span>
                        </NavLink>
                      </Menu.Item> */}
                  </SubMenu>
                ) }

                <SubMenu
                  className="fas fa-users main-submenu"
                  key="sub16"
                  title="Team Info"
                  // expandIcon={click11 ? <FaChevronUp /> : <FaChevronDown />}
                  expandIcon={ <FaChevronDown /> }
                  onTitleClick={() => setOpenKeys(openKeys.includes("sub16") ? [] : ["sub16"])}

                >
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.employee-master" /></span> }
                    key="employee-master"
                    className={
                      location.pathname?.includes("/employee-master")
                        ? "ant-menu-item-active "
                        : ""
                    }
                  >
                    <NavLink to="/employee-master">
                      <FaHouseUser />
                      <span>
                        <IntlMessages id="sidebar.employee-master" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.Employee-In-Out" /></span> }
                    key="employee-in-out"
                    className={
                      location.pathname?.includes("/employee-in-out")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/employee-in-out">
                      <FaUserCheck />
                      <span>
                        <IntlMessages id="sidebar.Employee-In-Out" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.resignation-employee-list" /></span> }
                    key="resignation-employee-list"
                    className={
                      location.pathname?.includes("/resignation-employee-list")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/resignation-employee-list">
                      <FaUserCheck />
                      <span>
                        <IntlMessages id="sidebar.resignation-employee-list" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.admin-reports" /></span> }
                    key="admin-reports"
                    className={
                      location.pathname?.includes("/admin-reports") ||
                        location.pathname?.includes("/employee-reports") ||
                        location.pathname?.includes("/leave-reports") ||
                        location.pathname?.includes("/attendence-reports") ||
                        location.pathname?.includes("/letter")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-reports">
                      <BsFillFileEarmarkPersonFill />
                      <span>
                        <IntlMessages id="sidebar.admin-reports" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.tickets" /></span> }
                    key="tickets"
                    className={
                      location.pathname?.includes("/tickets") ||
                        location.pathname?.includes("/tickets")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/employee-tickets">
                      <ImTicket />
                      <span>
                        <IntlMessages id="sidebar.tickets" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.admin-leave-management" /></span> }

                    key="admin-leave-approval"
                    className={
                      location.pathname?.includes("/admin-leave-approval")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-leave-approval">
                      <FaFileExport />
                      <span>
                        <IntlMessages id="sidebar.admin-leave-management" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key="pendingtask"
                    className={
                      location.pathname?.includes("/pending-task-list")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/pending-task-list">
                      <MdOutlinePendingActions />
                      <span>
                        <IntlMessages id="sidebar.pending-task" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.admin-regularizatrion" /></span> }

                    key="admin-regularizatrion"
                    className={
                      location.pathname?.includes("/admin-regularizatrion")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-regularizatrion">
                      <FaUserCog />
                      <span>
                        <IntlMessages id="sidebar.admin-regularizatrion" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  className="fas fa-chart-line main-submenu"
                  key="sub17"
                  title="Management"
                  // expandIcon={click12 ? <FaChevronUp /> : <FaChevronDown />}
                  expandIcon={ <FaChevronDown /> }
                  onTitleClick={() => setOpenKeys(openKeys.includes("sub17") ? [] : ["sub17"])}
                >
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.achivements" /></span> }

                    key="achivements"
                    className={
                      location.pathname?.includes("/achivements")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/achivements">
                      <BsFillAwardFill />
                      <span>
                        <IntlMessages id="sidebar.achivements" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.permission" /></span> }

                    key="permission"
                    className={
                      location.pathname?.includes("/permission-manager")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/permission-manager">
                      <BsFillAwardFill />
                      <span>
                        <IntlMessages id="sidebar.permission" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.Department" /></span> }

                    key="admin-department"
                    className={
                      location.pathname?.includes("/admin-department")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-department">
                      <FaUserCog />
                      <span>
                        <IntlMessages id="sidebar.Department" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.designation" /></span> }

                    key="admin-designation"
                    className={
                      location.pathname?.includes("/admin-designation")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-designation">
                      <FaUserCog />
                      <span>
                        <IntlMessages id="sidebar.designation" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.admin-leave-master" /></span> }

                    key="leave-master"
                    className={
                      location.pathname?.includes("/admin-leave-master")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/admin-leave-master">
                      <FaUserCog />
                      <span>
                        <IntlMessages id="sidebar.admin-leave-master" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.employee-leave-balance" /></span> }

                    key="employee-leave-balance"
                    className={
                      location.pathname?.includes("/employee-leave-balance")
                        ? "ant-menu-item-active "
                        : ""
                    }
                  >
                    <NavLink to="/employee-leave-balance">
                      <FaHouseUser />
                      <span>
                        <IntlMessages id="sidebar.employee-leave-balance" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  className="fas fa-user-friends main-submenu"
                  key="sub19"
                  title="Onboarding"
                  // expandIcon={click14 ? <FaChevronUp /> : <FaChevronDown />}
                  expandIcon={ <FaChevronDown /> }
                  onTitleClick={() => setOpenKeys(openKeys.includes("sub19") ? [] : ["sub19"])}
                >
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.onboarding-initiation" /></span> }

                    key="initiation"
                    className={
                      location.pathname?.includes("/onboarding-initiation")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/onboarding-initiation">
                      <FaPaperPlane />
                      <span>
                        <IntlMessages id="sidebar.onboarding-initiation" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="doc-approve-list"
                    title={ <span><IntlMessages id="sidebar.doc-approve-list" /></span> }

                    className={
                      location.pathname?.includes("/doc-approve-list")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/doc-approve-list">
                      <FaRegFileArchive />
                      <span>
                        <IntlMessages id="sidebar.doc-approve-list" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.new-joinees-list" /></span> }

                    key="new-joinees-list"
                    className={
                      location.pathname?.includes("/new-joinees-list")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/new-joinees-list">
                      <FaUserFriends />
                      <span>
                        <IntlMessages id="sidebar.new-joinees-list" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>


                {/* Audit Module Sidebar */ }
                <SubMenu
                  className="fas fa-info-circle main-submenu"
                  key="sub20"
                  title="Audit"
                  // expandIcon={ click14 ? <FaChevronUp /> : <FaChevronDown /> }
                  expandIcon={ <FaChevronDown /> }
                  onTitleClick={() => setOpenKeys(openKeys.includes("sub20") ? [] : ["sub20"])}
                >
                  <Menu.Item
                    title={ <span><IntlMessages id="Audits Master" /></span> }

                    key="Audit Master"
                    className={
                      location.pathname?.includes("/auditmaster")
                        ? "ant-menu-item-active "
                        : ""
                    }
                  >
                    <NavLink to="/auditmaster">
                      <FaHouseUser />
                      <span>
                        <IntlMessages id="Audits Master" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="Audits" /></span> }

                    key="Audits"
                    className={
                      location.pathname?.includes("/audits")
                        ? "ant-menu-item-active "
                        : ""
                    }
                  >
                    <NavLink to="/audits">
                      <FaFileInvoice />
                      <span>
                        <IntlMessages id="Audits" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="Audit Performance" /></span> }

                    key="auditperformance"
                    className={
                      location.pathname?.includes("/auditperformance")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/auditperformance">
                      <FaUserFriends />
                      <span>
                        <IntlMessages id="Audit Performance" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="Audit Head" /></span> }

                    key="auditHead"
                    className={
                      location.pathname?.includes("/audithead")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/audithead">
                      <FaUserFriends />
                      <span>
                        <IntlMessages id="Audit Head" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item
                  title={ <span><IntlMessages id="sidebar.organisation-tree" /></span> }

                  key="organisationtree"
                  className={
                    location.pathname?.includes("/organization-view")
                      ? "ant-menu-item-active"
                      : ""
                  }
                >
                  <NavLink to="/organization-view">
                    <ImTree />
                    <span>
                      <IntlMessages id="sidebar.organisation-tree" />
                    </span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item
                  title={ <span><IntlMessages id="sidebar.app-setting" /></span> }

                  key="appsettings"
                  className={
                    location.pathname?.includes("/app-setting")
                      ? "ant-menu-item-active"
                      : ""
                  }
                >
                  <NavLink to="/app-setting">
                    <FaRegSun />
                    <span>
                      <IntlMessages id="sidebar.app-setting" />
                    </span>
                  </NavLink>
                </Menu.Item>

                <SubMenu
                  className="fas fa-ellipsis-h main-submenu"
                  key="sub18"
                  title="More"
                  // expandIcon={click13 ? <FaChevronUp /> : <FaChevronDown />}
                  expandIcon={ <FaChevronDown /> }
                  onTitleClick={() => setOpenKeys(openKeys.includes("sub18") ? [] : ["sub18"])}
                >
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.holidays" /></span> }

                    key="holidays"
                    className={
                      location.pathname?.includes("/holidays")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/holidays">
                      <FaRegFileArchive />
                      <span>
                        <IntlMessages id="sidebar.holidays" />
                      </span>
                    </NavLink>



                    {/* {
              key: "subscription",
              path: "/subscription",
              icon: <HiDocumentReport />,
              title: "Add subscription ",
              submenu: false,
              permission: [config.RESOURCES.SUBSCRIPTION],
            }, */}
                  </Menu.Item>



                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.subscription" /></span> }

                    key="subscription"
                    className={
                      location.pathname?.includes("/subscription")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/subscription">
                      <BsFillFileEarmarkMedicalFill />
                      <span>
                        <IntlMessages id="sidebar.subscription" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  {
                    (getResource(config.RESOURCES.BILLREIMBURSEMENT, ["read"]) || departmentArray.includes(authUser?.subdepartment_id._id?.toString())
                    ) &&
                    <Menu.Item
                      key="Bill-Reimbursement"
                      className={
                        location.pathname?.includes("/bill-reimbursement")
                          ? "ant-menu-item-active"
                          : ""
                      }
                    >
                      <NavLink to="/bill-reimbursement">
                        <BsFillFileEarmarkMedicalFill />
                        <span>
                          <IntlMessages id="sidebar.bill-reimbursement" />
                        </span>
                      </NavLink>
                    </Menu.Item>
                  }


                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.policies" /></span> }

                    key="policies"
                    className={
                      location.pathname?.includes("/policies")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/policies">
                      <BsFillFileEarmarkMedicalFill />
                      <span>
                        <IntlMessages id="sidebar.policies" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.create-goals" /></span> }

                    key="goals"
                    className={
                      location.pathname?.includes("/goals") ||
                        location.pathname?.includes("/goals")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/goals">
                      <GiOnTarget />
                      <span>
                        <IntlMessages id="sidebar.create-goals" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.admin-jobopening" /></span> }

                    key="Job Opening"
                    className={
                      location.pathname?.includes("/jobs/openings")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/jobs/openings">
                      <BsFillBagCheckFill />
                      <span>
                        <IntlMessages id="sidebar.admin-jobopening" />
                      </span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    title={ <span><IntlMessages id="sidebar.user-assetstabpane" /></span> }

                    key="inventory"
                    className={
                      location.pathname?.includes("/inventory")
                        ? "ant-menu-item-active"
                        : ""
                    }
                  >
                    <NavLink to="/inventory">
                      <BsFillTicketDetailedFill />
                      <span>
                        <IntlMessages id="sidebar.user-assetstabpane" />
                      </span>
                    </NavLink>
                  </Menu.Item>

                  {/* SIDEBAR KPI  */ }
                  {/* {isCommonHrTeamUser && (
                      <Menu.Item
                        key="testkpis"
                        className={
                          location.pathname?.includes("/Kpis")
                            ? // ||
                            // location.pathname?.includes("/user-view-kpi")
                            // location.pathname?.includes("/tickets")
                            "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to="/Kpis">
                          <GiStairsGoal />
                          <span>
                            <IntlMessages id="sidebar.test-kpis" />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    )} */}
                </SubMenu>
              </Menu>

            </CustomScrollbars>
          </div>
        ) : getRoles(["Reporting Manager"]) ? (
          <div className="gx-sidebar-content sidebar-menu">

<CustomScrollbars className="gx-layout-sider-scrollbar">
  <Menu
    openKeys={openKeys}
    selectedKeys={[selectedKeys]}
    theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
    mode="inline"
    triggerSubMenuAction="hover"
    onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
  >
    {Reporting_Manager?.map((item) => {
      console.log("rmmm====", item);
      if (item.submenu) {
        const permission =
          item?.permission?.length > 0
            ? getResource(item?.permission[0], ["read"]) || userdata?._id == "63b6ca8870f2b5b3002cb446"
            : true;

        return (
          permission && (
            <SubMenu
              className={item.className}
              key={item.key}
              title={item.title}
              expandIcon={item.expandIcon}
              onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])} // Only one submenu at a time
            >
              {item.subMenuInfo.map((subItem) => {
                const submenuPermission =
                  subItem?.permission?.length > 0
                    ? getResource(subItem?.permission[0], ["read"]) || userdata?._id == "63b6ca8870f2b5b3002cb446"
                    : true;

                return (
                  submenuPermission && (
                    <Menu.Item
                      title={<span><IntlMessages id={subItem.title} /></span>}
                      key={subItem.key}
                      className={location.pathname === subItem.path ? "ant-menu-item-active" : ""}
                    >
                      <NavLink to={subItem.path}>
                        {subItem.icon}
                        <span>
                          <IntlMessages id={subItem.title} />
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )
                );
              })}
            </SubMenu>
          )
        );
      } else {
        const permission =
          item?.permission?.length > 0 ? getResource(item?.permission[0], ["read"]) : true;

        return (
          permission && (
            <Menu.Item
              title={<span><IntlMessages id={item.title} /></span>}
              key={item.key}
              className={location.pathname?.includes(item.path) ? "ant-menu-item-active" : ""}
            >
              <NavLink to={item.path}>
                {item.icon}
                <span>
                  <IntlMessages id={item.title} />
                </span>
              </NavLink>
            </Menu.Item>
          )
        );
      }
    })}
  </Menu>
</CustomScrollbars>


          </div>
        ) : getRoles(["Assistance Reporting Manager"]) ? (
          <div className="gx-sidebar-content sidebar-menu">

            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Assistant_Reporting_Manager?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (item.submenu) {
                    return (
                      permission && <SubMenu
                        className={ item.className }
                        key={ item.key }
                        title={ item.title }
                        expandIcon={ item.expandIcon }
                        // onTitleClick={ () => handlesubmenu(item) }
                        onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                      >
                        { item.subMenuInfo.map((subItem) => {
                          const submenupermission =
                            subItem?.permission?.length > 0
                              ? getResource(subItem?.permission[0], ["read"])
                              : true;
                          return (
                            submenupermission && (
                              <Menu.Item
                                title={ <span><IntlMessages id={ subItem.title } /></span> }

                                key={ subItem.key }
                                className={
                                  location.pathname?.includes(subItem.path)
                                    ? "ant-menu-item-active"
                                    : ""
                                }
                              >
                                <NavLink to={ subItem.path }>
                                  { subItem.icon }
                                  <span>
                                    <IntlMessages id={ subItem.title } />
                                  </span>
                                </NavLink>
                              </Menu.Item>
                            )
                          );
                        }) }
                      </SubMenu>
                    );
                  } else {
                    const permission =
                      item?.permission?.length > 0
                        ? getResource(item?.permission[0], ["read"])
                        : true;
                    return (
                      permission && <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>

          </div>
        ) : getRoles(["Network Team"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Network_Team?.map((item) => {
                  if (item.submenu) {
                    const permission =
                      item?.permission?.length > 0
                        ? getResource(item?.permission[0], ["read"])
                        : true;
                    return (
                      permission && <SubMenu
                        className={ item.className }
                        key={ item.key }
                        title={ item.title }
                        expandIcon={ item.expandIcon }
                        // onTitleClick={
                        //   item.title == "Team Info"
                        //     ? () => {
                        //       setClick11(!click11);
                        //       if (openKeys?.includes("sub16")) {
                        //         setOpenKeys([]);
                        //       } else {
                        //         setOpenKeys(["sub16"]);
                        //       }
                        //     }
                        //     : item.title == "Your Info"
                        //       ? () => {
                        //         setClick10(!click10);
                        //         if (openKeys?.includes("sub15")) {
                        //           setOpenKeys([]);
                        //         } else {
                        //           setOpenKeys(["sub15"]);
                        //         }
                        //       }
                        //       : () => {
                        //         setClick13(!click13);

                        //         if (openKeys?.includes("sub18")) {
                        //           setOpenKeys([]);
                        //         } else {
                        //           setOpenKeys(["sub18"]);
                        //         }
                        //       }
                        // }
                        onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                      >
                        { item.subMenuInfo.map((subItem) => {
                          const submenupermission =
                            subItem?.permission?.length > 0
                              ? getResource(subItem?.permission[0], ["read"])
                              : true;
                          return (
                            submenupermission && (
                              <Menu.Item
                                title={ <span><IntlMessages id={ subItem.title } /></span> }

                                key={ subItem.key }
                                className={
                                  location.pathname?.includes(subItem.path)
                                    ? "ant-menu-item-active"
                                    : ""
                                }
                              >
                                <NavLink to={ subItem.path }>
                                  { subItem.icon }
                                  <span>
                                    <IntlMessages id={ subItem.title } />
                                  </span>
                                </NavLink>
                              </Menu.Item>
                            )
                          );
                        }) }
                      </SubMenu>
                    );
                  }
                  else {
                    const permission =
                      item?.permission?.length > 0
                        ? getResource(item?.permission[0], ["read"])
                        : true;
                    return (
                      permission && <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["User"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ `openKeys` }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { User?.map((item) => {
                  let billKey = item.key === 'Bill Reimbursement'
                  let billrembmt = false
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (billKey && hasBillReimbursementAccess) {
                    billrembmt = true
                  } else { billrembmt = false }

                  if (item.submenu) {
                    return (
                      permission && (
                        <SubMenu
                          className={ item.className }
                          key={ item.key }
                          title={ item.title }
                          expandIcon={ item.expandIcon }
                          // onTitleClick={
                          //   item.title == "Team Info"
                          //     ? () => {
                          //       setClick11(!click11);
                          //       if (openKeys?.includes("sub16")) {
                          //         setOpenKeys([]);
                          //       } else {
                          //         setOpenKeys(["sub16"]);
                          //       }
                          //     }
                          //     : item.title == "Your Info"
                          //       ? () => {
                          //         setClick10(!click10);

                          //         if (openKeys?.includes("sub15")) {
                          //           setOpenKeys([]);
                          //         } else {
                          //           setOpenKeys(["sub15"]);
                          //         }
                          //       }
                          //       : () => {
                          //         setClick13(!click13);

                          //         if (openKeys?.includes("sub18")) {
                          //           setOpenKeys([]);
                          //         } else {
                          //           setOpenKeys(["sub18"]);
                          //         }
                          //       }
                          // }
                          onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                        >
                          { item.subMenuInfo.map((subItem) => {
                            const submenupermission =
                              subItem?.permission?.length > 0
                                ? getResource(subItem?.permission[0], ["read"])
                                : true;
                            return (
                              submenupermission && (
                                <Menu.Item
                                  title={ <span><IntlMessages id={ subItem.title } /></span> }

                                  key={ subItem.key }
                                  className={
                                    location.pathname?.includes(subItem.path)
                                      ? "ant-menu-item-active"
                                      : ""
                                  }
                                >
                                  <NavLink to={ subItem.path }>
                                    { subItem.icon }
                                    <span>
                                      <IntlMessages id={ subItem.title } />
                                    </span>
                                  </NavLink>
                                </Menu.Item>
                              )
                            );
                          }) }
                        </SubMenu>
                      )
                    );
                  } else {
                    console.log(billKey, hasBillReimbursementAccess, 'billKey', billKey && hasBillReimbursementAccess, authUser?.subdepartment_id?._id);

                    return (
                      (permission || billrembmt) &&
                      <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["Auditor"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Auditor?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (item.submenu) {
                    return (
                      permission && <SubMenu
                        className={ item.className }
                        key={ item.key }
                        title={ item.title }
                        expandIcon={ item.expandIcon }
                        // onTitleClick={
                        //   () => {
                        //     setClick15(!click15);
                        //     if (openKeys?.includes("sub20")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub20"]);
                        //     }
                        //   }
                        // }
                        onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                      >
                        { item.subMenuInfo.map((subItem) => {
                          const submenupermission =
                            subItem?.permission?.length > 0
                              ? getResource(subItem?.permission[0], ["read"])
                              : true;
                          return (
                            submenupermission && (
                              <Menu.Item
                                title={ <span><IntlMessages id={ subItem.title } /></span> }

                                key={ subItem.key }
                                className={
                                  location.pathname?.includes(subItem.path)
                                    ? "ant-menu-item-active"
                                    : ""
                                }
                              >
                                <NavLink to={ subItem.path }>
                                  { subItem.icon }
                                  <span>
                                    <IntlMessages id={ subItem.title } />
                                  </span>
                                </NavLink>
                              </Menu.Item>
                            )
                          );
                        }) }
                      </SubMenu>
                    );
                  } else {
                    const permission =
                      item?.permission?.length > 0
                        ? getResource(item?.permission[0], ["read"])
                        : true;
                    return (
                      permission && (
                        <Menu.Item
                          title={ <span><IntlMessages id={ item.title } /></span> }

                          key={ item.key }
                          className={
                            location.pathname?.includes(item.path)
                              ? "ant-menu-item-active"
                              : ""
                          }
                        >
                          <NavLink to={ item.path }>
                            { item.icon }
                            <span>
                              <IntlMessages id={ item.title } />
                            </span>
                          </NavLink>
                        </Menu.Item>
                      )
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["HR"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { HR?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (item.submenu) {
                    return (
                      permission && <SubMenu
                        className={ item.className }
                        key={ item.key }
                        title={ item.title }
                        expandIcon={ item.expandIcon }
                        // onTitleClick={ () => {
                        //   if (item.title === "Your Info") {
                        //     setClick10(!click10);
                        //     if (openKeys.includes("sub15")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub15"]);
                        //     }
                        //   } else if (item.title === "Team Info") {
                        //     setClick11(!click11);
                        //     if (openKeys.includes("sub16")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub16"]);
                        //     }
                        //   }

                        //   else if (item.title === "Onboarding") {
                        //     setClick14(!click14);
                        //     if (openKeys?.includes("sub19")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub19"]);
                        //     }

                        //   }
                        //   else if (item.title === "Audit") {

                        //     setClick15(!click15);
                        //     if (openKeys?.includes("sub20")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub20"]);
                        //     }

                        //   }
                        //   else if (item.title === "Management") {
                        //     setClick12(!click12);

                        //     if (openKeys?.includes("sub17")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub17"]);
                        //     }
                        //   } else if (item.title === "More") {
                        //     setClick13(!click13);
                        //     if (openKeys?.includes("sub18")) {
                        //       setOpenKeys([]);
                        //     } else {
                        //       setOpenKeys(["sub18"]);
                        //     }

                        //   }

                        // } }
                        onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}

                      >
                        { item.subMenuInfo.map((subItem) => {
                          const submenupermission =
                            subItem?.permission?.length > 0
                              ? getResource(subItem?.permission[0], ["read"])
                              : true;
                          return (
                            submenupermission && (
                              <Menu.Item
                                title={ <span><IntlMessages id={ subItem.title } /></span> }

                                key={ subItem.key }
                                className={
                                  location.pathname?.includes(subItem.path)
                                    ? "ant-menu-item-active"
                                    : ""
                                }
                              >
                                <NavLink to={ subItem.path }>
                                  { subItem.icon }
                                  <span>
                                    <IntlMessages id={ subItem.title } />
                                  </span>
                                </NavLink>
                              </Menu.Item>
                            )
                          );
                        }) }
                      </SubMenu>
                    );
                  } else {
                    return (permission &&
                      <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["Intern"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Intern?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  return (
                    permission && (
                      <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    )
                  );
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["Account Team"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Account?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (item.submenu) {
                    return (
                      permission && (
                        <SubMenu
                          className={ item.className }
                          key={ item.key }
                          title={ item.title }
                          expandIcon={ item.expandIcon }
                          // onTitleClick={
                          //   item.title == "Team Info"
                          //     ? () => {
                          //       setClick11(!click11);
                          //       if (openKeys?.includes("sub16")) {
                          //         setOpenKeys([]);
                          //       } else {
                          //         setOpenKeys(["sub16"]);
                          //       }
                          //     }
                          //     : item.title == "Your Info"
                          //       ? () => {
                          //         setClick10(!click10);

                          //         if (openKeys?.includes("sub15")) {
                          //           setOpenKeys([]);
                          //         } else {
                          //           setOpenKeys(["sub15"]);
                          //         }
                          //       }
                          //       : () => {
                          //         setClick13(!click13);

                          //         if (openKeys?.includes("sub18")) {
                          //           setOpenKeys([]);
                          //         } else {
                          //           setOpenKeys(["sub18"]);
                          //         }
                          //       }
                          // }
                          onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                        >
                          { item.subMenuInfo.map((subItem) => {
                            const submenupermission =
                              subItem?.permission?.length > 0
                                ? getResource(subItem?.permission[0], ["read"])
                                : true;
                            return (
                              submenupermission && (
                                <Menu.Item
                                  title={ <span><IntlMessages id={ subItem.title } /></span> }

                                  key={ subItem.key }
                                  className={
                                    location.pathname?.includes(subItem.path)
                                      ? "ant-menu-item-active"
                                      : ""
                                  }
                                >
                                  <NavLink to={ subItem.path }>
                                    { subItem.icon }
                                    <span>
                                      <IntlMessages id={ subItem.title } />
                                    </span>
                                  </NavLink>
                                </Menu.Item>
                              )
                            );
                          }) }
                        </SubMenu>
                      )
                    );
                  } else {
                    return (
                      permission &&
                      <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }

                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : getRoles(["Recruiter"]) ? (
          <div className="gx-sidebar-content sidebar-menu">
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                // defaultOpenKeys={[defaultOpenKeys]}
                openKeys={ openKeys }
                selectedKeys={ [selectedKeys] }
                theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
                mode="inline"
                triggerSubMenuAction="hover"
                onOpenChange={(keys) => setOpenKeys(keys.length ? [keys[keys.length - 1]] : [])} // Ensure only one submenu is open
                >
                { Recruiter?.map((item) => {
                  const permission =
                    item?.permission?.length > 0
                      ? getResource(item?.permission[0], ["read"])
                      : true;
                  if (item.submenu) {
                    return (
                      permission && <SubMenu
                        className={ item.className }
                        key={ item.key }
                        title={ item.title }
                        expandIcon={ item.expandIcon }
                        // onTitleClick={ () => handlesubmenu(item) }
                        onTitleClick={() => setOpenKeys(openKeys.includes(item.key) ? [] : [item.key])}
                      >
                        { item.subMenuInfo.map((subItem) => {
                          const submenupermission =
                            subItem?.permission?.length > 0
                              ? getResource(subItem?.permission[0], ["read"])
                              : true;
                          return (
                            submenupermission && (
                              <Menu.Item
                                title={ <span><IntlMessages id={ subItem.title } /></span> }

                                key={ subItem.key }
                                className={
                                  location.pathname?.includes(subItem.path)
                                    ? "ant-menu-item-active"
                                    : ""
                                }
                              >
                                <NavLink to={ subItem.path }>
                                  { subItem.icon }
                                  <span>
                                    <IntlMessages id={ subItem.title } />
                                  </span>
                                </NavLink>
                              </Menu.Item>
                            )
                          );
                        }) }
                      </SubMenu>
                    );
                  } else {
                    const permission =
                      item?.permission?.length > 0
                        ? getResource(item?.permission[0], ["read"])
                        : true;
                    return (
                      permission && <Menu.Item
                        title={ <span><IntlMessages id={ item.title } /></span> }
                        key={ item.key }
                        className={
                          location.pathname?.includes(item.path)
                            ? "ant-menu-item-active"
                            : ""
                        }
                      >
                        <NavLink to={ item.path }>
                          { item.icon }
                          <span>
                            <IntlMessages id={ item.title } />
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  }
                }) }
              </Menu>
            </CustomScrollbars>
          </div>
        ) : (
          <></>
        ) }
      </Sider>
    </>
  );
};

export default SidebarContent;

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Table,
    Tag,
    Form,
    Input,
    Modal,
    Button,
    Card,
    Row,
    Select,
    Col,
    message,
} from "antd";
import Service from "../../service";
import { AliyunOutlined, EyeOutlined, VerifiedOutlined, CheckCircleOutlined, DownloadOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { object } from "prop-types";
import { useDispatch } from "react-redux";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions/Auth";
import moment from 'moment'


const DocumentsApproveList = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [empData, setEmpData] = useState([]);
    const [positionlist, setpositionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [IsModalView, setIsModalView] = useState(false);
    let [filterData, setFilterData] = useState([]);
    const [listing, setListing] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };
    const getpositionDropdownList = async () => {
        try {
            dispatch(showAuthLoader());
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.empdesignationDropdownList,
            });
            // dispatch(hideAuthLoader());
            if (response.data && response.data.data) {
                setpositionList(response.data.data);
                dispatch(hideAuthLoader());
            }
            else {
                message.error(response.data.message);
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            dispatch(hideAuthLoader());
            console.log(error);
        }
    };
    const Search = Input.Search;
    const [form] = Form.useForm();
    const openEventModalView = () => {
        // setEvent_record(record);
        setIsModalView(true);
    };
    const closeEventModalView = () => {
        // setEvent_record(record);
        form.resetFields();
        setIsModalView(!IsModalView);
        // setFilterData([]);
    };
    const columns = [
        // {
        //     title: "Sno.",
        //     dataIndex: "_id",
        //     key: "_id",
        //     render: (text, record, index) => {
        //         return <span>{index + 1}</span>;
        //     },
        // },

        {
            title: "Candidate Name",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => {
                // const eventType = record?.leave;

                return <span style={ { textTransform: "capitalize" } }>{ record?.initialName + " " + record?.first_name + " " + record?.last_name }</span>;
                // <span>{record.event_type?.event_type}</span>
            },
        },
        {
            title: "Candidate Email",
            dataIndex: "email",
            key: "email",
            render: (text, record, index) => {
                return <span>{ record?.email }</span>;
            },
        },
        {
            title: "Candidate Contact",
            dataIndex: "contact",
            key: "contact",
            render: (text, record, index) => {
                return <span style={ { textTransform: "capitalize" } }>{ record?.contact_number }</span>;
            },
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            render: (text, record, index) => {
                return <span style={ { textTransform: "capitalize" } }>{ record?.department_name }</span>;
            },
        },
        {
            title: "Sub Department",
            dataIndex: "subdepartment",
            key: "subdepartment",
            render: (text, record, index) => {
                return <span style={ { textTransform: "capitalize" } }>{ record?.sub_department_name }</span>;
            },
        },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            render: (text, record, index) => {
                return <span style={ { textTransform: "capitalize" } }>{ record?.designation_name }</span>;
            },
        },
        {
            title: "Experience",
            dataIndex: "experience",
            key: "experience",
            render: (text, record, index) => {
                return <span style={ { textTransform: "capitalize" } }>{ record?.experience }</span>;
            },
        },

        {
            title: "Deadline Other Docs",
            dataIndex: "deadline",
            render: (text, record, index) => {
                const deadlineDate = moment(record.deadline_other_docs);
                let deadlineMessage = '-';
                let color;

                if (deadlineDate.isValid()) {
                    const currentDate = moment();
                    const daysRemaining = deadlineDate.diff(currentDate, 'days');

                    if (daysRemaining === 0) {
                        deadlineMessage = 'Today';
                        color = 'orange'
                    } else if (daysRemaining < 0) {
                        deadlineMessage = 'Missed';
                        color = 'red'
                    } else {
                        deadlineMessage = `${daysRemaining} day${daysRemaining > 1 ? 's' : ''}`;
                        color = 'blue'

                    }
                }

                return (
                    <div style={ { display: "flex", flexWrap: "wrap" } }>
                        { record.isOtherDocsApprove ? <Tag color="green">Docs Approve</Tag> : <Tag color={ color }>{ deadlineMessage }</Tag> }
                    </div>
                );
            },
        },
        {
            title: "Deadline Sign Docs",
            dataIndex: "deadline",
            render: (text, record, index) => {
                const deadlineDate = moment(record.deadline_sign_docs);
                let deadlineMessage = '-';
                let color;
                if (deadlineDate.isValid()) {
                    const currentDate = moment();
                    const daysRemaining = deadlineDate.diff(currentDate, 'days');

                    if (daysRemaining === 0) {
                        deadlineMessage = 'Today';
                        color = 'orange'
                    } else if (daysRemaining < 0) {
                        deadlineMessage = 'Missed';
                        color = 'red'
                    } else {
                        deadlineMessage = `${daysRemaining} day${daysRemaining > 1 ? 's' : ''}`;
                        color = 'blue'
                    }
                }

                return (
                    <div style={ { display: "flex", flexWrap: "wrap" } }>
                        { record.isSignDocsApprove ? <Tag color="green">Docs Approve</Tag> : <Tag color={ color }>{ deadlineMessage }</Tag> }
                    </div>
                );
            },
        },


        {
            title: "Employee Status",
            dataIndex: "isEmployee",
            key: "isEmployee",
            render: (text, record, index) => {

                return (
                    <div style={ { display: "flex", flexWrap: "wrap" } }>
                        { record.isEmployee ? <Tag color='#468ae3'>Joined as a new employee</Tag> : <Tag color='cyan'>Not an employee yet</Tag> }
                    </div>
                );
            },
        },

        {
            title: "Approval Requested",
            dataIndex: "approval",
            render: (text, record, index) => (
                <div
                    style={ {
                        display: "flex",
                        flexwrap: "wrap",
                    } }
                >
                    <Link to={ `/doc-approve-reject/${record._id}` }>
                        <Button type="link success" title="View">
                            <EyeOutlined style={ { fontSize: "18px" } } onClick={ () => handleDocumentApproval(record?._id) } />
                        </Button>
                    </Link>
                </div>
            ),
        },

    ];
    const handleDocumentApproval = async (id) => {
        try {
            // const params = _id;
            dispatch(showAuthLoader());
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.viewdocsList + "/" + id,
            });
            if (response.data && response.data.data) {
                dispatch(hideAuthLoader());
            }
            else {
                message.error(response.data.message)
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            console.log(error);
            dispatch(hideAuthLoader());
        }
    }
    const getFooterDetails = () => {
        return (
            <label>
                Total Records Count is { pagination.total > 0 ? pagination.total : 0 }
            </label>
        );
    };

    const handleTableChange = (page, filters, sorter) => {
        setPagination({ ...pagination, ...page });
    };

    useEffect(() => {
        onboardedEmpListing();
        getpositionDropdownList();
        getDepartmentList();
    }, [pagination.current, filterData, searchText, pagination.pageSize])
    const getDepartmentList = async values => {
        try {
            dispatch(showAuthLoader());
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.empdepartmentDropdownList,
            });
            dispatch(hideAuthLoader());
            if (response.data && response.data.data) {
                setDepartmentList(response.data.data);
            }
            else {
                message.error(response.data.message)
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            dispatch(hideAuthLoader());
            console.log(error);
        }
    };
    const onboardedEmpListing = async () => {
        try {
            dispatch(showAuthLoader());
            let reqBody = {
                pageNum: pagination.current,
                pageLimit: pagination.pageSize,
            }
            if (searchText && searchText !== "") {
                reqBody.search = searchText;
            }
            if (filterData) {

                if (filterData?.designation_id?.length > 0) {
                    reqBody.designation_name = filterData.designation_id;
                }
                if (filterData?.department_name?.length > 0) {
                    reqBody.department_name = filterData.department_name;
                }
                if (filterData?.experience?.length > 0) {
                    reqBody.type = filterData.experience;
                }

            }
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.onboardedEmps,
                body: reqBody
            });

            if (response.data && response.data.data) {
                // if (filterData?.isActive == true) {
                setPagination({
                    ...pagination,
                    total: response.data.metaData.totalFilteredCount,
                    deactivetotal: response.data.metaData.totaldUsers,
                });
                setListing(response.data.data);
                // }
                dispatch(hideAuthLoader());
            }
            else {
                setPagination({
                    ...pagination,
                    total: response.data.metaData.totalFilteredCount,
                });
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            dispatch(hideAuthLoader());
            console.log(error);
        }
    };
    const searchRef = useRef();
    const onSearch = (value) => {
        setSearchText(value);
        setPagination({ ...pagination, current: 1 });
    };

    const filterEmp = async (values) => {
        if (
            (values.application_from_date &&
                values.application_to_date == undefined) ||
            "" ||
            null
        ) {
            message.error("Please select Application To date");
            return;
        }
        if (
            (values.application_to_date &&
                values.application_from_date == undefined) ||
            "" ||
            null
        ) {
            message.error("Please select Application from date");
            return;
        }
        setFilterData(values);
        setIsModalView(!IsModalView);
    };
    return (
        <Card className="employee-card" title="Document Approval">
            <Search
                ref={ searchRef }
                placeholder="Search..."
                onSearch={ onSearch }
                // onKeyUp={resetSearchFilter}
                style={ { width: 200 } }
                className="mr2"
            />
            <Button
                onClick={ () => openEventModalView() }
                className="mr2  filter-btn"
                type="primary"
            >
                Filter
            </Button>
            <Button
                onClick={ () => {
                    form.resetFields();
                    setFilterData([]);
                } }
                className="mr2 ant-delete"
                disabled={ filterData.length != 0 ? false : true }
            >
                Clear Filter
            </Button>
            <Modal
                title="Filter"
                width={ 1100 }
                visible={ IsModalView }
                okText="Update"
                footer={ false }
                onCancel={ closeEventModalView }
            >
                <Form form={ form } { ...formItemLayout }
                    onFinish={ filterEmp }
                >
                    <div className="add-registration leave_form">
                        { " " }
                        <Row>
                            <Col sm={ 24 } md={ 12 }>
                                <Form.Item label="Department" name="department_name">
                                    <Select
                                        size="large"
                                        defaultValue="--Select Department--"
                                        showSearch
                                        // optionFilterProp="children"
                                        filterOption={ (input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={ (optionA, optionB) =>
                                            optionA.children
                                                .toLowerCase()
                                                .localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        { departmentList.map((item, index) => (
                                            <Option
                                                key={ index }
                                                value={ item.department_name }
                                                style={ { textTransform: "capitalize" } }
                                            >
                                                { item.department_name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Designation" name="designation_id">
                                    <Select
                                        size="large"
                                        showSearch
                                        // optionFilterProp="children"
                                        filterOption={ (input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={ (optionA, optionB) =>
                                            optionA.children
                                                .toLowerCase()
                                                .localeCompare(optionB.children.toLowerCase())
                                        }
                                    // getFieldValue
                                    >
                                        {/* <Option key="all" value="all">ALL</Option> */ }

                                        { positionlist.map((item, index) => (
                                            <>
                                                <Option
                                                    key={ index }
                                                    value={ item.designation_name }
                                                    style={ { textTransform: "capitalize" } }
                                                >
                                                    { item.designation_name }
                                                </Option>
                                            </>
                                        )) }
                                        {/* {children} */ }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={ 24 } md={ 12 }>
                                <Form.Item
                                    label="Experience"
                                    name="experience"
                                >
                                    <Select
                                        options={ [
                                            {
                                                value: "Fresher",
                                                label: "Fresher",
                                            },
                                            {
                                                value: "Experience",
                                                label: "Experience",
                                            },
                                        ] }
                                    />
                                </Form.Item>
                            </Col>

                            <Col sm={ 24 } md={ 24 }>
                                <Form.Item
                                    { ...tailFormItemLayout }
                                    className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                                >
                                    <div className="add-leave-button">
                                        { " " }
                                        <Button type="primary" htmlType="submit">
                                            Go
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="ant-delete"
                                            onClick={ () => {
                                                form.resetFields();
                                                setFilterData([]);
                                                // setIsModalView(!IsModalView);
                                            } }
                                            style={ { marginLeft: "10px" } }
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal>
            <Table
                // rowSelection={rowSelection}
                columns={ columns }
                pagination={ {
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                    ...pagination,
                } }
                // rowSelection={rowSelection}
                footer={ getFooterDetails }
                onChange={ handleTableChange }
                // dataSource={empData}
                dataSource={ listing }

            />





        </Card>

    );

};

export default DocumentsApproveList;
import {
  Card,
  Table,
  Tag,
  Input,
  Button,
  Modal,
  Form,
  Select,
  message,
  Tooltip,
  DatePicker,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import Service from "../../service";
import moment from "moment";
import dayjs from 'dayjs';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FormOutlined, CloseOutlined, EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { YearSelect } from "../../util/YearOptions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { getResource } from "../../util/getResourcePermission";
import config from "../../permission/config.json";
import { getRoles } from "../../util/getRoles";

function ListingForResignation() {
  let { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [cancelResignForm] = Form.useForm();
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
  const [isModalOpenView, setIsModalOpenView] = useState(false);
  let [filterData, setFilterData] = useState([]);
  const [isModalOpenFilter, setIsModalOpenFilter] = useState(false);
  const [cancelstatus, setCancelStatus] = useState("");
  const [seachEnabled, setSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [Employees, setEmployees] = useState([]);
  const [ename, setName] = useState("");
  const [noticeDate, setNoticeDate] = useState([]);
  const [empId, setEmpId] = useState("");
  const [empIdtoCancel, setEmpIdtoCancel] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reasonRevoke, setReasonRevoke] = useState("");
  const [reasonCancell, setReasonCancel] = useState("");
  const [showreasonRevoke, setshowReasonRevoke] = useState("");
  const [showReasonCancel, setshowReasonCancel] = useState("");
  const [reasoninputVisible, setIsReasonInputVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [commentsView, setcomments] = useState("");
  const [reasonView, setReason] = useState("");
  const [html, setHtml] = useState([]);
  const { Option } = Select;
  const Search = Input.Search;
  const searchRef = useRef();
  let csvRef = document.getElementById("test-table-xls-button");
  // console.log(html,'html');

  useEffect(() => {
    getEmployeesResignList();
    getcsvData();
  }, [pagination.current, pagination.pageSize, searchText]);

  const showfilterModal = () => {
    setIsModalOpenFilter(true);
  };
  const handleCancelFilter = () => {
    filterForm.resetFields();
    setIsModalOpenFilter(false);
  };
  const onSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };
  const Is_admin_role = authUser?.role_id === "60e68a055f40862222c64ec9";
  const resetSearchFilter = (e) => {
    const keyCode = e && e.keyCode ? e.keyCode : e;
    switch (keyCode) {
      case 8:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      case 46:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      default:
        break;
    }
  };
  const getEmployeesResignList = async (filterData) => {
    try {
      const params = new URLSearchParams(window.location.search);
      let status_filter = params.get("status");
      console.log(
        "🚀 ~ getEmployeesResignList ~ status_filter:",
        status_filter
      );
      dispatch(showAuthLoader());
      const reqBody = {
        pageNum: pagination.current,
        pageLimit: pagination.pageSize,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }

      if (filterData) {
        if (filterData?.month) {
          reqBody.month = filterData.month;
        }
        if (filterData?.year) {
          reqBody.year = filterData.year;
        }
        if (filterData?.branch) {
          reqBody.branch = filterData.branch;
        }
        if (filterData?.resignationStatus) {
          reqBody.resignationStatus = filterData.resignationStatus;
        }
      }
      if (status_filter == "Pending") {
        reqBody.resignationStatus = status_filter;
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getresign,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data?.data?.length > 0) {
        if (filterData?.isActive == true) {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
            deactivetotal: response.data.metaData.totaldUsers,
          });
        } else {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
          });
        }
        setEmployees(response.data.data);
      } else {
        setPagination({
          ...pagination,
          total: 0,
        });
        setEmployees([]);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };
  const getcsvData = async (filterData) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        isExport: true,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }

      if (filterData) {
        if (filterData?.month) {
          reqBody.month = filterData.month;
        }
        if (filterData?.year) {
          reqBody.year = filterData.year;
        }
        if (filterData?.branch) {
          reqBody.branch = filterData.branch;
        }
        if (filterData?.resignationStatus) {
          reqBody.resignationStatus = filterData.resignationStatus;
        }
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getresign,
        body: reqBody,
      });

      dispatch(hideAuthLoader());

      // setHtml(response?.data?.html);
      if (response?.data?.html) setHtml(response?.data?.html);

    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };


  const exportExperienceLetterPdf = async (record) => {

    try {
      dispatch(showAuthLoader());
      const reqBody = {
        employee_id: record?.emp_id,
        last_date: record?.last_date,
      };

      var raw = JSON.stringify({ ...reqBody });

      var requestOptions = {
        method: Service.postMethod,
        body: raw,
        headers: { "Content-Type": "application/json" },
      };
      const API_URL = Service.API_URL + Service.experienceLetter;
      fetch(API_URL, requestOptions)
        .then((response) => {
          response.blob().then((blob) => {
            dispatch(hideAuthLoader());
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Experienceletter.pdf";
            alink.click();
          });
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "createdBy",
      render: (text, record, index) => {
        return <span>{ record?.createdBy }</span>;
      },
    },
    {
      title: "Date Of Resignation",
      dataIndex: "date_of_resignation",
      key: "date_of_resignation",
      render: (text, record, index) => {
        return (
          <span>{ moment(record?.resignation_date).format("DD MMM YYYY") }</span>
        );
      },
    },
    {
      title: "Last Date As Per Notice Period",
      dataIndex: "last_date_notice",
      key: "last_date_notice",
      render: (text, record, index) => {
        return (
          <span>{ moment(record?.last_date_notice).format("DD MMM YYYY") }</span>
        );
      },
    },
    {
      title: "Requested Last Date",
      dataIndex: "request_last_date",
      key: "request_last_date",
      render: (text, record, index) => {
        return (
          <span>
            { record?.request_last_date
              ? moment(record?.request_last_date).format("DD MMM YYYY")
              : " - " }
          </span>
        );
      },
    },
    {
      title: "Last Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text, record, index) => {
        if (record?.last_date) {
          return <span>{ moment(record?.last_date).format("DD MMM YYYY") }</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: "Accepted Date",
      dataIndex: "Accepted Date",
      key: "Accepted Date",
      render: (text, record, index) => {
        const AcceptedRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Accepted"
        );
        if (AcceptedRecord) {
          return (
            <span>
              { moment(AcceptedRecord.created_At).format("DD MMM YYYY") }
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },

    {
      title: "Rejected Date",
      dataIndex: "Rejected Date",
      key: "Rejected Date",
      render: (text, record, index) => {
        const RejectedRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Rejected"
        );
        if (RejectedRecord) {
          return (
            <span>
              { moment(RejectedRecord.created_At).format("DD MMM YYYY") }
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },

    {
      title: "Cancelled Date",
      dataIndex: "Cancelled",
      key: "Cancelled",
      render: (text, record, index) => {
        const cancelledRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Cancelled"
        );

        if (cancelledRecord) {
          return (
            <span>
              { moment(cancelledRecord.created_At).format("DD MMM YYYY") }
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: "Attachments",
      dataIndex: "FeedbackAttachment",
      key: "FeedbackAttachment",
      render: (text, record, index) => {
        if (record?.FeedbackAttachment === "") {
          <span>-</span>;
        }
        return (
          <span>
            <a
              href={ `${Service.Server_Base_URL}/uploads/Feedbacks/${record?.FeedbackAttachment}` }
              target="_blank"
              rel="noopener noreferrer"
            >
              { record?.FeedbackAttachment }
            </a>
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        const status = record?.resignationStatus;
        const color =
          record?.resignationStatus == "Pending"
            ? "processing"
            : record?.resignationStatus == "Accepted"
              ? "success"
              : "error";
        return status ? <Tag color={ color }>{ status }</Tag> : null;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record, index) => (
        <div
          style={ {
            display: "flex",
            flexwrap: "wrap",
          } }
        >
          { getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["update"]) &&
            record?.resignationStatus === "Pending" ? (
            <Button
              type="link success"
              onClick={ () => showModal(record._id, record?.last_date_notice) }
            >
              <FormOutlined style={ { fontSize: "18px" } } />
            </Button>
          ) : (
            <></>
          ) }
          {
            <Tooltip title="Comments">
              <Button
                type="link success"
                onClick={ () =>
                  showModalView(
                    record._id,
                    record?.comments,
                    record?.reason,
                    record?.reasonRevoke,
                    record?.reasonCancel
                  )
                }
              >
                <EyeOutlined style={ { fontSize: "18px" } } />
              </Button>
            </Tooltip>
          }

          { getRoles(["Admin", "HR"]) && record?.last_date && (

            <Tooltip title="Download Documents">
              {/* <Button onClick={() => showModal(record)}> */ }
              <DownloadOutlined
                onClick={ () => exportExperienceLetterPdf(record) }
                style={ { color: "#038FDE", fontSize: "20px", paddingRight: "10px" } }
              />
              {/* </Button> */ }
            </Tooltip>
          ) }

          { getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["update"]) &&
            record?.resignationStatus !== "Rejected" &&
            record?.resignationStatus !== "Cancelled" ? (
            <Button type="link delete">
              <Tooltip title="Cancel Resignation">
                <CloseOutlined
                  onClick={ () => {
                    showModalCancel(
                      record?._id,
                      record?.resignationStatus,
                      record?.createdBy
                    );
                  } }
                />
              </Tooltip>
            </Button>
          ) : (
            <></>
          ) }
        </div>
      ),
    },
  ];

  const showModalView = (id, comments, reason, revoke, cancel) => {
    setIsModalOpenView(true);
    setcomments(comments);
    setReason(reason);
    setshowReasonRevoke(revoke);
    setshowReasonCancel(cancel);
  };
  const handleOkView = () => {
    setIsModalOpenView(false);
  };
  const handleCancelView = () => {
    setIsModalOpenView(false);
    setcomments("");
    setReason("");
    setshowReasonRevoke("");
    setshowReasonCancel("");
  };
  const showModalCancel = (id, status, name) => {
    setIsModalOpenCancel(true);
    setCancelStatus(status);
    setName(name);
    setEmpIdtoCancel(id);
  };

  const handleCancelforCancel = () => {
    setCancelStatus("");
    setName("");
    setEmpIdtoCancel("");
    setIsModalOpenCancel(false);
  };
  const handleCancelResignation = async (id) => {
    try {
      let reqBody = {
        resignationStatus: "Cancelled",
        reasonCancel: reasonCancell,
      };
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: Service.editresign + "/" + id,
        body: reqBody,
      });
      dispatch(hideAuthLoader());
      if (response.data && response.data.data) {
        message.success(response.data.message);
        setIsModalOpenCancel(false);
        getEmployeesResignList();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = (empId, date) => {
    setIsModalOpen(true);
    setNoticeDate(date);
    setEmpId(empId);
  };
  const handleOk = async (values) => {
    console.log("🚀 ~ handleOk ~ values:", values);
    try {
      let reqBody = {};

      if (values.resignationStatus === "Accepted") {
        reqBody = {
          last_date: moment(values?.date).format("YYYY-MM-DD"),
          resignationStatus: values.resignationStatus,
        };
      } else {
        reqBody = {
          resignationStatus: values.resignationStatus,
          reasonRevoke: values?.reasonRevoke,
        };
      }

      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: Service.editresign + "/" + empId,
        body: reqBody,
      });
      dispatch(hideAuthLoader());
      if (response.data && response.data.data) {
        message.success(response.data.message);
        form.resetFields();
        setIsModalOpen(false);
        getEmployeesResignList();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsReasonInputVisible(false);
    setSelectedStatus("");
    setReasonRevoke("");
    setReasonCancel("");
    setIsModalOpen(false);
  };
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    if (value === "Rejected") {
      setIsReasonInputVisible(true);
    } else {
      setIsReasonInputVisible(false);
    }
  };

  const filterEmp = async (values) => {
    setFilterData(values);
    filterForm.resetFields();
    setIsModalOpenFilter(false);
    getcsvData(values);
    getEmployeesResignList(values);
  };
  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };
  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is { pagination.total > 0 ? pagination.total : 0 }
      </label>
    );
  };



  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day') || current.day() === 0 || current.day() === 6;
  };



  return (
    <div>
      <Modal
        title="Reasons"
        visible={ isModalOpenView }
        footer={ false }
        onOk={ handleOkView }
        onCancel={ handleCancelView }
      >
        Reason for resignation : { reasonView === " " ? "-" : reasonView }
        <br />
        <br />
        Comments for resignation : { commentsView === " " ? "-" : commentsView }
        <br />
        <br />
        Reason for revoking :{ " " }
        { showreasonRevoke === " " ? "-" : showreasonRevoke }
        <br />
        <br />
        Reason for Cancelling :{ " " }
        { showReasonCancel === " " ? "-" : showReasonCancel }
      </Modal>
      <Modal
        title="Filter"
        visible={ isModalOpenFilter }
        footer={ false }
        onCancel={ handleCancelFilter }
      >
        <Form form={ filterForm } onFinish={ filterEmp }>
          <div className="filter-data">
            <Form.Item label="Status" name="resignationStatus">
              <Select
                defaultValue="select Status"
                className="filter-branch"
                options={ [
                  {
                    value: "Pending",
                    label: "Pending",
                  },
                  {
                    value: "Accepted",
                    label: "Accepted",
                  },
                  {
                    value: "Cancelled",
                    label: "Cancelled",
                  },
                  {
                    value: "Rejected",
                    label: "Rejected",
                  },
                ] }
              />
            </Form.Item>
            <Form.Item label="Branch" name="branch">
              <Select
                defaultValue="select branch"
                className="filter-branch"
                options={ [
                  {
                    value: "none",
                    label: "All",
                  },
                  {
                    value: "ISKON Office",
                    label: "ISKON Office",
                  },
                  {
                    value: "IFFCO Office",
                    label: "IFFCO Office",
                  },
                  {
                    value: "WFH",
                    label: "WFH",
                  },
                ] }
              />
            </Form.Item>

            <Form.Item
              // initialValue={moment().format("MMMM")}
              name="month"
              label="Month"
            >
              <Select
                defaultValue="--Select Month--"
                options={ [
                  {
                    value: "January",
                    label: "January",
                  },
                  {
                    value: "February",
                    label: "February",
                  },
                  {
                    value: "March",
                    label: "March",
                  },
                  {
                    value: "April",
                    label: "April",
                  },
                  {
                    value: "May",
                    label: "May",
                  },
                  {
                    value: "June",
                    label: "June",
                  },
                  {
                    value: "July",
                    label: "July",
                  },
                  {
                    value: "August",
                    label: "August",
                  },
                  {
                    value: "September",
                    label: "September",
                  },
                  {
                    value: "October",
                    label: "October",
                  },
                  {
                    value: "November",
                    label: "November",
                  },
                  {
                    value: "December",
                    label: "December",
                  },
                ] }
              />
            </Form.Item>
            <Form.Item
              className="search-row"
              // initialValue={moment().format("YYYY")}
              label="Year"
              name="year"
            >
              <Select defaultValue="--Select Year--" options={ YearSelect() } />
            </Form.Item>
          </div>
          <div style={ { textAlign: "center" } }>
            <Button type="primary" htmlType="submit">
              Go
            </Button>
            <Button
              className="ant-delete"
              style={ { marginLeft: "10px" } }
              onClick={ () => {
                filterForm.resetFields();
                setFilterData([]);
              } }
            >
              Clear
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Resignation Status"
        footer={ false }
        visible={ isModalOpenCancel }
        onCancel={ handleCancelforCancel }
      >
        <p>
          Resignation status of <strong>{ ename }</strong> is { cancelstatus }, if
          you wish to cancel it, click here.
        </p>
        <div style={ { textAlign: "center" } }>
          <Form cancelResignForm={ cancelResignForm }>
            <Form.Item
              className="search-row ant-col-xs-24 ant-col-sm-24"
              label="Reason to Cancel"
              name="reasonCancel"
              rules={ [
                {
                  required: true,
                  message: "Please enter a reason to Cancel!",
                },
              ] }
            >
              <TextArea
                value={ reasonCancell }
                onChange={ (e) => setReasonCancel(e.target.value) }
                style={ { height: 60 } }
                rows={ 4 }
                maxLength={ 100 }
              />
            </Form.Item>
            <Button
              type="primary"
              style={ { marginRight: "10px" } }
              onClick={ () => handleCancelResignation(empIdtoCancel) }
            >
              Cancel Resignation
            </Button>
            <Button
              style={ { marginLeft: "10px" } }
              onClick={ handleCancelforCancel }
            >
              Close
            </Button>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Resignation Status"
        visible={ isModalOpen }
        footer={ false }
        onCancel={ handleCancel }
      >
        <div className="resignation-status-model">
          <Form onFinish={ handleOk } form={ form }>
            <Form.Item hidden>{ empId }</Form.Item>

            <Form.Item
              className="search-row ant-col-xs-24 ant-col-sm-24"
              label="Resignation Status"
              name="resignationStatus"
              rules={ [
                {
                  required: true,
                  message: "Please select Resignation status type!",
                },
              ] }
            >
              <Select
                options={ [
                  {
                    value: "Accepted",
                    label: "Accepted",
                  },
                  {
                    value: "Rejected",
                    label: "Rejected",
                  },
                ] }
                onChange={ handleStatusChange }
              />
            </Form.Item>

            { selectedStatus === "Rejected" && (
              <Form.Item
                className="search-row ant-col-xs-24 ant-col-sm-24"
                label="Reason to Revoke"
                name="reasonRevoke"
                rules={ [
                  {
                    required: true,
                    message: "Please enter a reason to revoke!",
                  },
                ] }
              >
                <TextArea
                  value={ reasonRevoke }
                  onChange={ (e) => setReasonRevoke(e.target.value) }
                  style={ { height: 80 } }
                  rows={ 6 }
                  maxLength={ 500 }
                />
              </Form.Item>
            ) }

            { selectedStatus === "Accepted" && (
              <Form.Item
                initialValue={ moment(noticeDate) }
                className="search-row ant-col-xs-24 ant-col-sm-24"
                label="Last Working Date"
                name="date"
                rules={ [
                  {
                    required: true,
                    message: "Please select a date!",
                  },
                ] }
              >


                <DatePicker
                  defaultValue={ moment(noticeDate) }
                  format="DD MMM YYYY"
                  disabledDate={ disabledDate }
                />
              </Form.Item>
            ) }
            <div style={ { textAlign: "center" } }>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button onClick={ handleCancel } style={ { marginLeft: "10px" } }>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Card title="Manage Resignations">
        <div className="steps-content">
          <Search
            ref={ searchRef }
            placeholder="Search..."
            onSearch={ onSearch }
            onKeyUp={ resetSearchFilter }
            style={ { width: 200 } }
            className="mr2 "
          />
          <Button type="primary" className=" filter-btn" onClick={ showfilterModal }>
            Filter
          </Button>

          <Button
            onClick={ () => {
              filterForm.resetFields();
              setFilterData([]);
              getEmployeesResignList();
            } }
            className="mr2 ant-delete"
            disabled={ filterData.length != 0 ? false : true }
          >
            Clear Filter
          </Button>
          { getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["read"]) ? (
            <Link to="/resignation">
              <Button type="primary">Apply for resignation</Button>{ " " }
            </Link>
          ) : (
            <></>
          ) }
          { getResource(config.RESOURCES.LAST_WORKING_DAY_LIST, ["read"]) ? (
            <Link to="/last-working-day-emps">
              <Button type="primary">Last working Day List</Button>{ " " }
            </Link>
          ) : (
            <></>
          ) }
          { getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["read"]) ? (
            <>
              <Link to="/add-resignation-for-user">
                <Button type="primary">Add Resignation</Button>{ " " }
              </Link>
              <Button
                className="ant-btn-primary export-btn"
                // disabled={ eventApiData.length > 0 && html ? false : true }
                onClick={ () => {
                  csvRef.click();
                  getEmployeesResignList(filterData);
                } }
              >
                Export CSV
              </Button>
              <div hidden>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="ant-btn-primary"
                  table="table-to-xls"
                  filename={ "Separation" }
                  sheet="tablexls"
                  buttonText="Export CSV"
                />
                <div dangerouslySetInnerHTML={ { __html: html["html"] } }></div>
              </div>
            </>
          ) : (
            <></>
          ) }
          <Table
            columns={ columns }
            footer={ getFooterDetails }
            onChange={ handleTableChange }
            dataSource={ Employees }
            pagination={ {
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              ...pagination,
            } }
          />
        </div>
      </Card>
    </div>
  );
}

export default ListingForResignation;

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Input,
  Table,
  Tag,
  Button,
  message,
  Form,
  Row,
  Select,
  DatePicker,
  Col,
  Modal,
  Popconfirm,
  Card,
  Tooltip,
  TimePicker,
  InputNumber,
  Checkbox,
  Upload,
  Radio,
  Popover,
  Tabs,
} from "antd";
import Service from "../../service";
import {
  AliyunOutlined,
  EyeOutlined,
  VerifiedOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useParams, Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import TextArea from "antd/lib/input/TextArea";
import { getResource } from "../../util/getResourcePermission";
import config from "../../permission/config.json"

const CandidateApproveReject = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [val, setVal] = useState("All");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [detailsDocs, setDetailsDocs] = useState([]);
  const [employeeData, setemployeeData] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const { id } = useParams();
  const [documentList, setDocumentList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [companyEmailModalOpen, setCompanyEmailModalOpen] = useState(false);
  const [candidateInforModalOpen, setCandidateInfoModalOpen] = useState(false);
  const [totalDocs, setTotalDocs] = useState(5);
  const [onBoardEmpType, setOnBoardEmpType] = useState("");
  const [candidateInforModalOpenEdit, setCandidateInfoModalOpenEdit] =
    useState(false);

  const [form] = Form.useForm();
  const [editinfoform] = Form.useForm();

  const { Option } = Select;
  const { TabPane } = Tabs;
  const [signedDocs, setSignedDocs] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [empDropdownList, setEmpDropdownList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  //start
  const [mAkeEmpBtn, setMAkeEmpBtn] = useState(true)
  //end

  const validateDates = (_, value) => {
    if (value && selectedDate) {
      const startDate = form.getFieldValue("DOJ");
      if (startDate && moment(value).isBefore(startDate, "day")) {
        return Promise.reject(
          new Error("End date cannot be before start date!")
        );
      }
    }
    return Promise.resolve();
  };
  const handleJoiningDateChange = (date) => {
    setSelectedDate(date);

    let end;
    if (onBoardEmpType === "Intern") {
      // For interns, set the end date to 1 year and 6 months after the joining date
      end = date.clone().add(1, "year").add(6, "months");
    } else {
      // For other employee types, set the end date to 1 year after the joining date
      end = date.clone().add(1, "year");
    }

    setEndDate(end);
    form.setFieldsValue({ employeement_end_date: end });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const docTypeFunction = (doc_type) => {
    if (doc_type == "aadhar_card") {
      return "Aadhar Card";
    }
    if (doc_type == "salary_slip") {
      return "Salary Slip";
    }
    if (doc_type == "last_appraisal_docs") {
      return "Last Appraisal Documents";
    }
    if (doc_type == "bank_statement") {
      return "Bank Statement";
    }
    if (doc_type == "pan_card") {
      return "Pan Card";
    }
    if (doc_type == "edu_certificate") {
      return "Education Certificate";
    }
    if (doc_type == "electricity_bill") {
      return "Electricity Bill";
    }
    if (doc_type == "Latest Photograph") {
      return "Latest Photograph";
    }
    if (doc_type.includes("experience_later")) {
      return "Experience Later";
    }
    if (doc_type=='CV File') {
      return "CV File";
    }
   
  };

  const columns = [
    // {
    //   title: "Sno.",
    //   dataIndex: "Sno",
    //   key: "Sno",
    //   render: (text, record, index) => {
    //     return <span>{index + 1}</span>;
    //   },
    // },
    {
      title: "Documents",
      dataIndex: "doc_type",
      key: "doc_type",
      render: (text, record, index) => {
        // const eventType = record?.leave;

        return (
          <span style={{ textTransform: "capitalize" }}>
            {docTypeFunction(record?.doc_type)}
          </span>
        );
        // <span>{record.event_type?.event_type}</span>
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_text, record, index) => {
        return (
          <div className="action-btns" disabled={record?.status !== "Pending"}>
            {record?.doc_type !== "Latest Photograph" ? (
              <Button type="link success" title="View document">

                {
                  record?.doc_type=="CV File"?<>
                    <a
                  href={`${Service.Server_Base_URL}/uploads/onboard-cv/${record?.filename}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <EyeOutlined />
                </a></>:<>
                <a
                  href={`${Service.Server_Base_URL}/uploads/employee_doc/${record?.filename}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <EyeOutlined />
                </a></>
                }
              
              </Button>
            ) : (
              <Button type="link success" title="View document">
                <a
                  href={`${Service.Server_Base_URL}/uploads/emp_images/${record?.filename}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <EyeOutlined />
                </a>
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        const doc_status = record?.status;
        const color =
          record?.status == "Pending"
            ? "processing"
            : record?.status == "Approved"
              ? "success"
              : "error";

        return doc_status ? <Tag color={color}>{doc_status}</Tag> : null;
      },
    },
  ];
  const signDocscolumns = [
    {
      title: "Serial No.",
      dataIndex: "Sno",
      key: "Sno",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "Documents",
      dataIndex: "doc_type",
      key: "doc_type",
      render: (text, record, index) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record?.doc_type}
          </span>
        );
      },
    },
    {
      title: "Document Sign Date",
      dataIndex: "signedDate",
      key: "signedDate",
      render: (text, record, index) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record?.signedDate
              ? moment(record?.signedDate).format("YYYY-MM-DD / hh:mm A")
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        const doc_status = record?.status;
        const color =
          record?.status == "Pending"
            ? "processing"
            : record?.status == "Approve"
              ? "success"
              : "error";

        return doc_status ? <Tag color={color}>{doc_status}</Tag> : null;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_text, record, index) => {
        // if (record?.status !== "Done") {
        //   return false;
        // }
        return (
          <div className="action-btns">
            <Button type="link success" title="View document">
              <a href={`${record?.url}`} target="_blank" rel="noreferrer">
                <EyeOutlined />
              </a>
            </Button>

            {record?.status !== "Approve" && (
              <>
                <Popconfirm
                  title="Are you sure want to Approve?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() =>
                    actionOnSignedDocs(
                      record?.doc_type,
                      record?.uniqueId,
                      "Approve"
                    )
                  }
                >
                  <Button type="link success" title="View document">
                    Approve
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure want to Reject?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() =>
                    actionOnSignedDocs(
                      record?.doc_type,
                      record?.uniqueId,
                      "Reject"
                    )
                  }
                >
                  <Button type="link success" title="View document">
                    Reject
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        );
      },
    },
  ];

  // const getFooterDetails = () => {
  //   return (
  //     <label>
  //       Total Records Count is {pagination.total > 0 ? pagination.total : 0}
  //     </label>
  //   );
  // };

  const actionOnSignedDocs = async (type, docId, status) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        doc_type: type,
        uniqueId: docId,
        status,
      };

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.editSignDocsByAdmin}/${id}`,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        if (response.data.status === 200) {
          message.success(response.data.message);
          getEmployeeDetails();
          dispatch(hideAuthLoader());
        } else {
          message.error(response.data.message);
          dispatch(hideAuthLoader());
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log(newSelectedRowKeys, "newSelectedRowKeys");
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record?.status == "Approved" ? true : false,
    }),
  };

  useEffect(() => {
    getDocListingApprovalRejection();
    getAllEmployeeList();
    getEmployeeDetails();
  }, []);

  const getEmployeeDetails = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: `${Service.getOnboardingEmpDetails}/${id}`,
      });
      if (response.data && response.data.data) {
        setemployeeData(response.data.data);
        setOnBoardEmpType(response.data.data.experience);
        // if (response.data.data.experience == "Intern") {
        //   setTotalDocs(5)
        // }
        setSignedDocs(response.data.data.SignDocs);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };
  console.log("employeeData", employeeData);
  const getDocListingApprovalRejection = async () => {
    try {
      dispatch(showAuthLoader());
      const params = id;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.approveRejectdoc + "/" + params,
      });

      if (response.data && response.data.data) {

        console.log(response.data.data[0].empDetails[0], 'ttt')
        setemployeeData(response.data.data[0].empDetails[0]);


        const docs = response.data.data[0].employee_doc?.map((d, index) => {
          return {
            ...d,
            id: index,
          };
        });

        const excludedTypes = [
          "NDA",
          "Security Cheque",
          "Cheque Declaration",
          "Tenure Commitment Agreement",
          "Offer Letter",
          "Internship Letter",
        ];
        const employee = response.data.data[0].employee_doc.filter(
          (item) => !excludedTypes.includes(item.doc_type)
        );
        setDetailsDocs(employee);

        const newArr = employee
          .filter((item) => !excludedTypes.includes(item.doc_type))
          .map((e, index) => {
            return {
              ...e,
              key: index,
            };
          });
        setDocumentList(newArr);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };


  useEffect(() => {
    editinfoform.setFieldsValue({
      acc_holder_name: employeeData?.acc_holder_name,
      employeement_end_date: employeeData?.employeement_end_date,


      acc_number: employeeData?.acc_number,
      bank_name: employeeData?.bank_name,
      bank_branch: employeeData?.bank_branch,
      cheque_number: employeeData?.cheque_number,
      cheque_amount: employeeData?.cheque_amount,
      DOJ: employeeData?.DOJ,
      employeement_end_date: employeeData?.employeement_end_date

    })
  }, [employeeData])

  const getAllEmployeeList = async () => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        branch: "none",
        is_onboard: true,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.empDropdownList,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        setAllEmployeeList(response.data.data);
        setEmpDropdownList(response.data.data);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };
  const handleSubmit = async () => {
    try {
      dispatch(showAuthLoader());
      let check = detailsDocs.every((item) => item.status === "Approved");
      if (check) {
        setIsModalOpen(true);
        dispatch(hideAuthLoader());
      } else {
        setIsRejectModalOpen(true);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const rejectDocsEmailSent = async (values) => {
    try {
      dispatch(showAuthLoader());
      const params = id;
      const reqBody = {
        documents: detailsDocs,
        rejectReason: values.reason_to_reject_doc,
        reSubmissionDate: values.document_resubmission_date,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.submitTarget + "/" + params,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        setSelectedRowKeys([]);
        message.success(response.data.message);
        getDocListingApprovalRejection();
        setIsRejectModalOpen(false);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const allDocsApprove = async (values) => {
    try {
      dispatch(showAuthLoader());
      let { interview_feedback } = values;
      let feedback = interview_feedback.split(",").map((item) => item.trim());
      const params = id;
      const reqBody = {
        documents: detailsDocs,
        details: { ...values, interview_feedback: feedback },
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.submitTarget + "/" + params,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        setSelectedRowKeys([]);
        message.success(response.data.message);
        getDocListingApprovalRejection();
        setIsModalOpen(false);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const allSignDocumentsApprove = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendDocsToEmp + "/" + id,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleAccept = async () => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        onboarding_empId: id,
        approvalIds: selectedRowKeys,
      };

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.approval,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        setSelectedRowKeys([]);
        message.success(response.data.message);
        getDocListingApprovalRejection();
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleReject = async () => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        onboarding_empId: id,
        rejectedIds: selectedRowKeys,
      };

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.rejection,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        setSelectedRowKeys([]);
        getDocListingApprovalRejection();
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const reSignDocsMailSent = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.reSignDocEmail}/${id}`,
      });
      if (response.data) {
        message.success(response.data.message);
        dispatch(hideAuthLoader());
      } else {
        message.error("Something went wronge!");
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      message.error(error);
      dispatch(hideAuthLoader());
    }
  };



  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelForRejectReason = () => {
    setIsRejectModalOpen(false);
  };
  const handleCancelForCompanyEmaliModal = () => {
    setCompanyEmailModalOpen(false);
  };
  const handleCancelForCandidateInfoModal = () => {
    setCandidateInfoModalOpen(false);
  };
  const handleCancelForEditCandidateInfoModal = () => {
    setCandidateInfoModalOpenEdit(false);
  };
  //Make as employee after all docs approved:
  const OnBoardingToEmployeeModal = async () => {
    setCompanyEmailModalOpen(true);
  };

  const OnBoardingToEmployee = async (value) => {
    try {
      dispatch(showAuthLoader());
      setMAkeEmpBtn(false)
      let reqBody = {
        company_email_emp: value.company_email_emp,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.onBoardingToEmplyee}/${id}`,
        body: reqBody,
      });
      if (response.data && response.data.status == 200) {
        message.success(response.data.message);
        setCompanyEmailModalOpen(false);
        history.push("/employee-master");
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      message.error(error);
      dispatch(hideAuthLoader());
    }
  };

  const EditReportingManager = async (value) => {
    try {
      dispatch(showAuthLoader());
      let reqBody = {
        reporting_manager_id: value?.reporting_person_id,
        buddy_id: value?.buddy,
        acc_number: value?.acc_number,
        bank_name: value?.bank_name,
        bank_branch: value?.bank_branch,
        cheque_number: value?.cheque_number,
        cheque_amount: value?.cheque_amount,
        DOJ: value?.DOJ,
        employeement_end_date: value?.employeement_end_date

      };
      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: `${Service.OnBoardingEditReportingManager}/${id}`,
        body: reqBody,
      });
      if (response.data && response.data.status == 200) {
        message.success(response.data.message);
        // setCompanyEmailModalOpen(false)
        setCandidateInfoModalOpenEdit(false);
        // history.push('/employee-master')
        getEmployeeDetails()
        dispatch(hideAuthLoader());
      } else {
        message.error(response.data.message);
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      message.error(error);
      dispatch(hideAuthLoader());
    }
  };
  //Send mail to TL for assest allocation:
  const senToTLForAssestAllocate = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.sendNotificationToTL}/${id}`,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        getEmployeeDetails();
        dispatch(hideAuthLoader());
      } else {
        message.error("Something went wrong!");
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      message.error(error);
      dispatch(hideAuthLoader());
    }
  };

  //Send Email for deadline date:
  const sendEmailForDeadline = async (docType) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        documentType: docType,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.sendDeadlineMail}/${id}`,
        body: reqBody,
      });
      if (response.data && response.data.data) {
        message.success(response.data.message);
        dispatch(hideAuthLoader());
      } else {
        message.error("Something went wrong!");
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      message.error(error);
      dispatch(hideAuthLoader());
    }
  };

  //View employee info into modal function:
  const viewCandidateInfo = () => {
    setCandidateInfoModalOpen(true);
  };
  const editCandidateInfo = () => {
    setCandidateInfoModalOpenEdit(true);
  };
  const currentDate = moment();
  const oneYearLater = currentDate.add(1, "year");
  // useEffect(()=>{getDocListingApprovalRejection();
  //   getEmployeeDetails()
  // },[])
  const initialDate = oneYearLater;
  return (
    <>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={(key) => setActiveTab(key)}
      >
        <TabPane key="1" tab="Employee Documents">
          <Card
            className="employee-card employee-onboarding"
            title="Document Approval & Rejection"
            extra={
              <Button
                type="primary"
                style={{ margin: 0 }}
                onClick={viewCandidateInfo}
                htmlType="button"
              >
                View Info
              </Button>
            }
          >
            <div className="regular-button-flex">
              <Link to="/doc-approve-list" style={{ flex: "left" }}>
                <Button type="primary">Back</Button>
              </Link>

              <div>
                {selectedRowKeys.length > 0 && (
                  <>
                    <Button className="mr2 ant-approve" onClick={handleAccept}>
                      Approve
                    </Button>

                    <Button className="mr2 ant-reject" onClick={handleReject}>
                      Reject
                    </Button>
                  </>
                )}
              </div>
            </div>

            <Table
              columns={columns}
              pagination={false}
              rowSelection={rowSelection}
              // footer={getFooterDetails}
              onChange={handleTableChange}
              dataSource={documentList}
              rowClassName={(record) =>
                record?.count > 0 ? "disabled-row " : ""
              }
            />
            {getResource(config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST, ["create"]) && !employeeData?.isOtherDocsApprove &&
              employeeData?.deadline_other_docs &&
              (moment().format("DD-MM-YYYY") ===
                moment(employeeData?.deadline_other_docs)
                  .subtract(1, "days")
                  .format("DD-MM-YYYY") ||
                moment().format("DD-MM-YYYY") ===
                moment(employeeData?.deadline_other_docs).format(
                  "DD-MM-YYYY"
                )) && (
                <Button
                  type="primary"
                  onClick={() => sendEmailForDeadline("otherDocs")}
                  style={{ margin: 0 }}
                >
                  Send Mail For Deadline End
                </Button>
              )}

            {getResource(config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST, ["create"]) && !employeeData?.isOtherDocsApprove && (
              <Button
                disabled={
                  documentList.every((item) => item.status == "Pending") ||
                  documentList.some((item) => item.status == "Pending")
                }
                type="primary"
                onClick={handleSubmit}
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            )}

            {!employeeData?.isOtherDocsApprove &&
              employeeData?.deadline_other_docs &&
              moment().format("YYYY-MM-DD") >
              moment(employeeData?.deadline_other_docs).format(
                "YYYY-MM-DD"
              ) && (
                <div style={{ color: "red", fontSize: "18px" }}>
                  Note: Candidate missed the deadline.
                </div>
              )}
          </Card>
        </TabPane>
        <TabPane key="2" tab="Sign Documents">
          <Card
            className="employee-card employee-onboarding"
            title="Signed Documents Approval & Rejection"
            extra={
              <>
                {
                  getResource(config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST, ["update"]) && (
                    <Button
                      type="primary"
                      style={{ margin: "0px" }}
                      onClick={editCandidateInfo}
                      htmlType="button"
                    >
                      Edit Info
                    </Button>
                  )
                }
                &nbsp;&nbsp;&nbsp;
                <Button
                  type="primary"
                  style={{ margin: "0px" }}
                  onClick={viewCandidateInfo}
                  htmlType="button"
                >
                  View Info
                </Button>
              </>
            }
          >
            <Link to="/doc-approve-list" style={{ flex: "left" }}>
              <Button type="primary">Back</Button>
            </Link>
            <Table
              columns={signDocscolumns}
              pagination={false}
              dataSource={signedDocs}
            />
            {console.log(
              "🚀 ~ file: CandidateApproveReject.js:784 ~ {signedDocs.some ~ signedDocs.length:",
              signedDocs.length,
              "---",
              totalDocs
            )}{" "}
            {signedDocs.some((item) => item.status === "Reject") &&
              signedDocs.length == totalDocs && (
                <Button
                  type="primary"
                  onClick={reSignDocsMailSent}
                  style={{ marginTop: "20px" }}
                >
                  Send To Resign
                </Button>
              )}
            {signedDocs.every((item) => item.status === "Approve") &&
              employeeData?.isEmployee === false && (
                // signedDocs.length == totalDocs &&
                // moment().format("DD-MM-YYYY").toString() == employeeData?.DOJ &&
                <Button
                  type="primary"
                  onClick={OnBoardingToEmployeeModal}
                  style={{ margin: "20px" }}
                >
                  Make Employee
                </Button>
              )}
            {employeeData?.deadline_sign_docs &&
              signedDocs.length === totalDocs &&
              (moment().format("DD-MM-YYYY") ===
                moment(employeeData?.deadline_sign_docs)
                  .subtract(1, "days")
                  .format("DD-MM-YYYY") ||
                moment().format("DD-MM-YYYY") ===
                moment(employeeData?.deadline_sign_docs).format(
                  "DD-MM-YYYY"
                )) && (
                <Button
                  type="primary"
                  onClick={() => sendEmailForDeadline("signDocs")}
                  style={{ margin: "20px" }}
                >
                  Send Mail For Deadline End
                </Button>
              )}
            {signedDocs.every((item) => item.status === "Approve") &&
              employeeData?.isSendToTL === false &&
              signedDocs.length == totalDocs && (
                <Button
                  type="primary"
                  onClick={senToTLForAssestAllocate}
                  style={{ margin: "20px" }}
                >
                  Send To TL
                </Button>
              )}
            {signedDocs.some((item) => item.status === "Approve") &&
              !signedDocs.some((item) => item.status === "Reject") &&
              !signedDocs.some((item) => item.status === "Pending") &&
              signedDocs.length == totalDocs && (
                <Button
                  type="primary"
                  onClick={allSignDocumentsApprove}
                  style={{ margin: "20px" }}
                >
                  Send Docs To Employee
                </Button>
              )}
            {!signedDocs.every((item) => item.status === "Approve") &&
              moment().format("YYYY-MM-DD") >
              moment(employeeData?.deadline_sign_docs).format(
                "YYYY-MM-DD"
              ) && (
                <div style={{ color: "red", fontSize: "18px" }}>
                  Note: Candidate missed the deadline.
                </div>
              )}
          </Card>
        </TabPane>
      </Tabs>
      <Modal
        title="Provide a reason to reject documents"
        width={800}
        visible={isRejectModalOpen}
        okText="OK"
        footer={false}
        onCancel={handleCancelForRejectReason}
      >
        <Form
          name="basic"
          form={form}
          onFinish={rejectDocsEmailSent}
          {...formItemLayout}
        >
          <Form.Item
            label="Reason to reject documents"
            name="reason_to_reject_doc"
            rules={[
              {
                required: true,
                message: "Please enter reason!",
              },
              {
                validator: (rule, value) => {
                  if (value && value.trim() === "") {
                    return Promise.reject("Reason should not be just spaces");
                  }
                  return Promise.resolve();
                },
              },
              () => ({
                validator(_, value) {
                  if (!value || typeof value !== "string") {
                    return Promise.resolve();
                  }

                  // Check for HTML tags
                  if (/<[a-z][\s\S]*>/i.test(value)) {
                    return Promise.reject(
                      new Error("HTML tags are not allowed!")
                    );
                  }

                  // Check for whitespace-only strings
                  if (/^\s*$/.test(value)) {
                    return Promise.reject(
                      new Error("Only spaces are not allowed!")
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TextArea type="text" />
          </Form.Item>
          <Form.Item
            label="Document Resubmission Date"
            name="document_resubmission_date"
            rules={[
              {
                required: true,
                message: "Please enter document resubmission date!",
              },
            ]}
          >
            <DatePicker size="large" format="DD-MM-YYYY" />
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Fill in the details"
        width={800}
        visible={isModalOpen}
        okText="OK"
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          onFinish={allDocsApprove}
          {...formItemLayout}
        >
          <Form.Item
            label="Interview Feedback"
            name="interview_feedback"
            rules={[
              {
                required: true,
                message: "Please enter Interview feedback!",
              },

              () => ({
                validator(_, value) {
                  if (!value || typeof value !== "string") {
                    return Promise.resolve();
                  }

                  if (/<[a-z][\s\S]*>/i.test(value)) {
                    return Promise.reject(
                      new Error("HTML tags are not allowed!")
                    );
                  }

                  if (/^\s*$/.test(value)) {
                    return Promise.reject(
                      new Error("Only spaces are not allowed!")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TextArea
              rows={4}
              type="text"
              placeholder="Please add feedback into separate line by comma(,)"
            />
          </Form.Item>
          <Form.Item
            label="Branch"
            name="office_branch"
            rules={[
              {
                required: true,
                message: "Please select office branch!",
              },
            ]}
          >
            <Select size="large">
              <Option value="ISKON Office">ISKCON Office</Option>
              <Option value="IFFCO Office">IFFCO Office</Option>
              <Option value="WFH">WFH</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Offered Salary"
            name="offered_salery"
            rules={[
              {
                required: true,
                message: "Please enter salary!",
              },
              {
                max: 20,
                message: "Salary should be less than 20 characters",
              },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter only numbers.",
              },

              {
                validator: (rule, value) => {
                  if (value && value.trim() === "") {
                    return Promise.reject("Salary should not be just spaces");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              prefix={<i class="fa fa-inr" aria-hidden="true"></i>}
            />
          </Form.Item>
          {/* stipend for intern  */}
          {onBoardEmpType == "Intern" ? (
            <Form.Item
              label="Stipend"
              name="stipend_amount"
              rules={
                onBoardEmpType == "Intern"
                  ? [
                    {
                      required: true,
                      message: "Please enter stipend!",
                    },
                    {
                      max: 20,
                      message: "Stipend should be less than 20 characters",
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numbers.",
                    },

                    {
                      validator: (rule, value) => {
                        if (value && value.trim() === "") {
                          return Promise.reject(
                            "Stipend should not be just spaces"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]
                  : []
              }
            >
              <Input
                type="text"
                prefix={<i class="fa fa-inr" aria-hidden="true"></i>}
              />
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item
            label="CTC (LPA)"
            name="annual_CTC"
            rules={[
              {
                required: true,
                message: "Please enter CTC!",
              },
              {
                max: 20,
                message: "CTC should be less than 20 characters",
              },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter only numbers.",
              },
              {
                validator: (rule, value) => {
                  if (value && value.trim() === "") {
                    return Promise.reject("CTC should not be just spaces");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              prefix={<i class="fa fa-inr" aria-hidden="true"></i>}
              type="text"
            />
          </Form.Item>
          <Form.Item
            label="Cheque Amount"
            name="cheque_amount"
            rules={[
              employeeData?.isCheque === "true"
                ? {
                  required: true,
                  message: "Please enter cheque amount!",
                }
                : ({
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
                {
                  max: 20,
                  message: "Cheque Amount should be less than 20 characters",
                },
                {
                  validator: (rule, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(
                        "Cheque Amount should not be just spaces"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
            ]}
          >
            <Input
              type="text"
              prefix={<i class="fa fa-inr" aria-hidden="true"></i>}
            />
          </Form.Item>
          <Form.Item
            label="Reporting Person"
            name="reporting_person_id"
            rules={[
              {
                required: true,
                message: "Please select reporting person!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              // optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {allEmployeeList.map((item, index) => (
                <>
                  <Option
                    key={index}
                    value={item._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.first_name + " " + item.last_name}
                  </Option>
                </>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Buddy"
            name="buddy"
            rules={
              onBoardEmpType == "Intern"
                ? []
                : [
                  {
                    required: true,
                    message: "Please select buddy for employee !",
                  },
                ]
            }
          >
            <Select
              size="large"
              showSearch
              // optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {empDropdownList.map((item, index) => (
                <>
                  <Option
                    key={index}
                    value={item._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.first_name + " " + item.last_name}
                  </Option>
                </>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Document Generate Date"
            name="document_generate_date"
            rules={[
              {
                required: true,
                message: "Please enter document generate date!",
              },
            ]}
          >
            <DatePicker
              size="large"
              format="DD-MM-YYYY"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            label="Date Of Joining"
            name="DOJ"
            rules={[
              {
                required: true,
                message: "Please enter date of joining!",
              },
            ]}
          >
            <DatePicker
              size="large"
              format="DD-MM-YYYY"
              onChange={handleJoiningDateChange}
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Form.Item>

          <Form.Item
            label="Employment End Date"
            name="employeement_end_date"
            rules={[
              {
                required: true,
                message: "Please enter end date!",
              },
              {
                validator: validateDates,
              },
            ]}
          >
            <DatePicker
              size="large"
              format="DD-MM-YYYY"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            // disabledDate={(current) =>
            //   selectedDate && current && current.isBefore(selectedDate, 'day')}
            />
          </Form.Item>
          <Form.Item
            label="Offer Letter Return Date"
            name="OL_return_date"
            rules={[
              {
                required: true,
                message: "Please enter OL return date!",
              },
            ]}
          >
            <DatePicker
              size="large"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Sign Document Return Deadline"
            name="deadline_sign_docs"
            rules={[
              {
                required: true,
                message: "Please enter signed document return date!",
              },
            ]}
          >
            <DatePicker
              size="large"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Fill the details"
        width={800}
        visible={companyEmailModalOpen}
        okText="OK"
        footer={false}
        onCancel={handleCancelForCompanyEmaliModal}
      >


        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button onClick={viewCandidateInfo}>View Info</Button>
          <Button onClick={editCandidateInfo}>Edit Info</Button>
        </div>

        <Form
          name="basic"
          form={form}
          onFinish={OnBoardingToEmployee}
          {...formItemLayout}
        >
          <Form.Item
            label="Company Email For Employee"
            name="company_email_emp"
            rules={[
              {
                required: true,
                message: "Please enter company email for employee!",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Candidate Info"
        width={800}
        visible={candidateInforModalOpen}
        okText="OK"
        footer={false}
        onCancel={handleCancelForCandidateInfoModal}
      >
        <ul>
          <h3>Personal Info</h3>
          <li>
            Name :{" "}
            {employeeData?.initialName +
              " " +
              employeeData?.first_name +
              " " +
              employeeData?.last_name}
          </li>
          <li>Email : {employeeData.email}</li>
          <li>
            Gender : {employeeData?.initialName == "Mr" ? "Male" : "Female"}
          </li>
          {/* <li>Hidden Talent : {employeeData?.hiddenTalent}</li> */}
          <li>Hidden Talent : {employeeData?.hiddenTalent || " "}</li>

          <li>Contact No. : {employeeData?.contact_number}</li>
          {/* <li>Address : {employeeData?.address + ', ' + employeeData?.pin_code + ', ' + employeeData?.district + ', ' + employeeData?.city + ', ' + employeeData?.state}</li> */}
          <li>
            Address :{" "}
            {(employeeData?.address ? employeeData.address + ", " : "") +
              (employeeData?.pin_code ? employeeData.pin_code + ", " : "") +
              (employeeData?.district ? employeeData.district + ", " : "") +
              (employeeData?.city ? employeeData.city + ", " : "") +
              (employeeData?.state ? employeeData.state : "")}
          </li>

          <li>
            Birth Date : {moment(employeeData?.birthdate).format("DD-MM-YYYY")}
          </li>
          <li>Experience : {employeeData.experience}</li>
          <li>Father Name : {employeeData?.fathername}</li>
          <li>Mother Name : {employeeData?.mothername}</li>
          <li>Marital status : {employeeData?.marital_status}</li>

          <br></br>
          <h4>Document Details</h4>
          <li>Aadhar Card Number: {employeeData?.aadhar_number}</li>
          <li>Pan Card Number: {employeeData?.pan_number}</li>

          <br></br>
          <h4>Bank Details:</h4>
          <li>Account Holder Name : {employeeData?.acc_holder_name}</li>
          <li>Account Number : {employeeData?.acc_number}</li>
          <li>Bank Name : {employeeData?.bank_name}</li>
          <li>Bank Branch : {employeeData?.bank_branch}</li>
          <li>Cheque Number :{employeeData?.cheque_number}</li>
          <li>
            Cheque Amount : <i class="fa fa-inr" aria-hidden="true"></i>
            {employeeData?.cheque_amount}
          </li>
          <br></br>
          <h4>Employement Details</h4>
          <li>Date of Joining : {employeeData?.DOJ}</li>
          <li>Employeement End Date : {employeeData?.employeement_end_date}</li>
        </ul>
      </Modal>

      <Modal
        title="Edit Candidate Info"
        width={800}
        visible={candidateInforModalOpenEdit}
        okText="OK"
        footer={false}
        onCancel={handleCancelForEditCandidateInfoModal}
      >
        {/* <h1>HI</h1> */}
        <Form
          name="basic"
          form={editinfoform}
          onFinish={EditReportingManager}
          {...formItemLayout}
        >
          <Form.Item
            initialValue={employeeData?.reporting_person_id}
            label="Reporting Person"
            name="reporting_person_id"
            rules={[
              {
                required: true,
                message: "Please select reporting person!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              // optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {allEmployeeList.map((item, index) => (
                <>
                  <Option
                    key={index}
                    value={item._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.first_name + " " + item.last_name}
                  </Option>
                </>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            initialValue={employeeData?.buddy}
            label="Buddy"
            name="buddy"
            rules={
              onBoardEmpType == "Intern"
                ? []
                : [
                  {
                    required: true,
                    message: "Please select buddy for employee !",
                  },
                ]
            }
          >
            <Select
              size="large"
              showSearch
              // optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {empDropdownList.map((item, index) => (
                <>
                  <Option
                    key={index}
                    value={item._id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.first_name + " " + item.last_name}
                  </Option>
                </>
              ))}
            </Select>
          </Form.Item>
          {/* {employeeData?.acc_holder_name} */}
          {/* {editinfoform?.acc_holder_name} */}
          <Form.Item
            initialValue={editinfoform?.acc_holder_name}
            label="Account Holder Name"
            name="acc_holder_name"
            rules={[
              {
                required: true,
                message: "Please enter company email for employee!",
              },
            ]}
          >
            <Input type="text" />

          </Form.Item>

          <Form.Item
            initialValue={editinfoform?.acc_number}
            label="Account Number"
            name="acc_number"
            rules={[
              {
                required: true,
                message: "Please select Account Holder Name!",
              },
            ]}
          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.bank_name}
            label="Bank Name"
            name="bank_name"
            rules={

              [
                {
                  required: true,
                  message: "Please select buddy for employee !",
                }
              ]
            }
          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.bank_branch}
            label="Bank Branch"
            name="bank_branch"
            rules={
              [
                {
                  required: true,
                  message: "Please select buddy for employee !",
                }
              ]
            }
          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.cheque_number}
            label="Cheque Number"
            name="cheque_number"

          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.cheque_amount}
            label="Cheque Amount"
            name="cheque_amount"

          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.DOJ}
            label="Date of Joining"
            name="DOJ"
            rules={
              [
                {
                  required: true,
                  message: "Please select buddy for employee !",
                }
              ]
            }
          >
            <Input type="text" />

          </Form.Item>
          <Form.Item
            initialValue={editinfoform?.employeement_end_date}
            label="Employeement End Date"
            name="employeement_end_date"
            rules={

              [
                {
                  required: true,
                  message: "Please select buddy for employee !",
                }
              ]
            }
          >
            <Input type="text" />

          </Form.Item>


          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CandidateApproveReject;

import {
  Card,
  Table,
  Tag,
  Button,
  Modal,
  Form,
  Select,
  message,
  Input,
  DatePicker,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import Service from "../../service";
import moment from "moment";
import {
  EditOutlined,
  EyeOutlined,
  SaveTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { getResource } from "../../util/getResourcePermission";
import config from "../../permission/config.json"
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { YearSelect } from "../../util/YearOptions";

function LWDResign() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  let [flag, setFlag] = useState(false);
  let [editid, setEditid] = useState();
  const [seachEnabled, setSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [isModalOpenFilter, setIsModalOpenFilter] = useState(false);
  const [assets, setAssets] = useState([]);
  const [Employees, setEmployees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { authUser } = useSelector(({ auth }) => auth);
  const [edtitext, setEdittext] = useState({});
  const Search = Input.Search;
  const searchRef = useRef();
  let csvRef = document.getElementById("test-table-xls-button");
  const [html, setHtml] = useState([]);
  console.log(html, 'htmlT');

  const [selDate, setSelDate] = useState(null);
  const handleChangeDate = (date, dateString) => {
    setSelDate(dateString);
  };

  const onSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };
  const showfilterModal = () => {
    setIsModalOpenFilter(true);
  };
  const handleCancelFilter = () => {
    filterForm.resetFields();
    setIsModalOpenFilter(false);
  };
  const handleEdit = async (empId) => {
    console.log(selDate, "selDate");
    try {
      let reqBody = {
        last_date: selDate,
        resignationStatus: "Accepted",
      };

      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.putMethod,
        api_url: Service.editresign + "/" + empId,
        body: reqBody,
      });
      dispatch(hideAuthLoader());
      if (response.data && response.data.data) {
        message.success(response.data.message);
        getEmployeesResignList();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetSearchFilter = (e) => {
    const keyCode = e && e.keyCode ? e.keyCode : e;
    switch (keyCode) {
      case 8:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      case 46:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getEmployeesResignList();
    getcsvData();
  }, [pagination.current, pagination.pageSize, searchText]);

  const getEmployeesResignList = async (filterData) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        pageNum: pagination.current,
        pageLimit: pagination.pageSize,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }
      if (filterData) {
        if (filterData?.month) {
          reqBody.month = filterData.month;
        }
        if (filterData?.year) {
          reqBody.year = filterData.year;
        }
        if (filterData?.branch) {
          reqBody.branch = filterData.branch;
        }
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.last_working,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data?.data?.length > 0) {
        if (filterData?.isActive == true) {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
            deactivetotal: response.data.metaData.totaldUsers,
          });
        } else {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
          });
        }
        const filteredData = response.data.data.filter(
          (record) => record.resignationStatus === "Accepted"
        );
        setEmployees(filteredData);
        setEmployees(response.data.data);
      } else {
        setPagination({
          ...pagination,
          total: 0,
        });
        setEmployees([]);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };
  const getcsvData = async (filterData) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        isExport: true,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }

      if (filterData) {
        if (filterData?.month) {
          reqBody.month = filterData.month;
        }
        if (filterData?.year) {
          reqBody.year = filterData.year;
        }
        if (filterData?.branch) {
          reqBody.branch = filterData.branch;
        }
        if (filterData?.resignationStatus) {
          reqBody.resignationStatus = filterData.resignationStatus;
        }
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.last_working,
        body: reqBody,
      });

      dispatch(hideAuthLoader());

      // setHtml(response?.data?.html);
      if (response?.data?.html) setHtml(response?.data?.html);

    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Employee Code",
      dataIndex: "emp_code",
      key: "emp_code",
      render: (text, record, index) => {
        return <span>{ record?.emp_code }</span>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "createdBy",
      render: (text, record, index) => {
        return <span>{ record?.createdBy }</span>;
      },
    },
    {
      title: "Last Date",
      dataIndex: "last_date",
      key: "last_date",
      // render: (text, record, index) => {
      //   const formattedDate = record?.last_date ? moment(record?.last_date).format("DD MMM YYYY") : '-';
      //   return (
      //     <span>{formattedDate}</span>
      //   );
      // },
      render: (text, record, index) => {
        const position = record?.last_date
          ? moment(record?.last_date).format("DD MMM YYYY")
          : "-";
        return record?._id == editid ? (
          <span style={ { textTransform: "capitalize" } }>
            <DatePicker
              defaultValue={ moment(position) }
              format="YYYY-MM-DD"
              onChange={ handleChangeDate }
            />
          </span>
        ) : (
          <span style={ { textTransform: "capitalize" } }>{ position }</span>
        );
      },
    },
    {
      title: "Reporting Manager",
      dataIndex: "reporting_mgr",
      key: "reporting_mgr",
      render: (text, record, index) => {
        return <span>{ record?.reporting_mgr }</span>;
      },
    },
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      render: (text, record, index) => {
        return <span>{ record?.department_name }</span>;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      render: (text, record, index) => {
        return <span>{ record?.designation }</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <div
          style={ {
            display: "flex",
            flexwrap: "wrap",
          } }
        >
          { flag == true && editid == record?._id ? (
            <>
              <Button type="link edit">
                <SaveTwoTone
                  style={ { fontSize: "18px" } }
                  onClick={ () => {
                    handleEdit(record?._id);
                    setFlag(false);
                    setEditid("");
                  } }
                />
              </Button>
              <Button
                type="link delete"
                title="View"
                onClick={ () => setEditid("") }
              >
                <CloseCircleTwoTone style={ { fontSize: "18px" } } />
              </Button>
            </>
          ) : (
            <>
              <Tooltip title="Assets">
                <Button
                  type="link success"
                  onClick={ () => showModal(record?.assets) }
                >
                  <EyeOutlined style={ { fontSize: "18px" } } />
                </Button>
              </Tooltip>

              { getResource(config.RESOURCES.LAST_WORKING_DAY_LIST, ["update"]) && (
                <Tooltip title="Edit Last Date">
                  <Button type="link success">
                    <EditOutlined
                      style={ { fontSize: "18px" } }
                      onClick={ () => {
                        setEditid(record._id);
                        setFlag(true);
                      } }
                    />
                  </Button>
                </Tooltip>
              ) }
            </>
          ) }
        </div>
      ),
    },
  ];

  const showModal = (record) => {
    setIsModalOpen(true);
    setAssets(record);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setAssets([]);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is { pagination.total > 0 ? pagination.total : 0 }
      </label>
    );
  };
  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };
  const filterEmp = async (values) => {
    setFilterData(values);
    filterForm.resetFields();
    setIsModalOpenFilter(false);
    getEmployeesResignList(values);
    getcsvData(values);

  };
  return (
    <div>
      <Modal
        title="Filter"
        open={ isModalOpenFilter }
        footer={ false }
        onCancel={ handleCancelFilter }
      >
        <Form form={ filterForm } onFinish={ filterEmp }>
          <Form.Item label="Branch" name="branch">
            <Select
              defaultValue="select branch"
              options={ [
                {
                  value: "none",
                  label: "All",
                },
                {
                  value: "ISKON Office",
                  label: "ISKON Office",
                },
                {
                  value: "IFFCO Office",
                  label: "IFFCO Office",
                },
                {
                  value: "WFH",
                  label: "WFH",
                },
              ] }
            />
          </Form.Item>
          <Form.Item
            // initialValue={moment().format("MMMM")}
            name="month"
            label="Month"
          >
            <Select
              defaultValue="--Select Month--"
              options={ [
                {
                  value: "January",
                  label: "January",
                },
                {
                  value: "February",
                  label: "February",
                },
                {
                  value: "March",
                  label: "March",
                },
                {
                  value: "April",
                  label: "April",
                },
                {
                  value: "May",
                  label: "May",
                },
                {
                  value: "June",
                  label: "June",
                },
                {
                  value: "July",
                  label: "July",
                },
                {
                  value: "August",
                  label: "August",
                },
                {
                  value: "September",
                  label: "September",
                },
                {
                  value: "October",
                  label: "October",
                },
                {
                  value: "November",
                  label: "November",
                },
                {
                  value: "December",
                  label: "December",
                },
              ] }
            />
          </Form.Item>
          <Form.Item
            className="search-row"
            // initialValue={moment().format("YYYY")}
            label="Year"
            name="year"
          >
            <Select
              defaultValue="--Select Year--"
              options={ YearSelect() }
            />
          </Form.Item>
          <div style={ { textAlign: "center" } }>
            <Button type="primary" htmlType="submit">
              Go
            </Button>
            <Button
              className="ant-delete"
              style={ { marginLeft: "10px" } }
              onClick={ () => {
                filterForm.resetFields();
                setFilterData([]);
              } }
            >
              Clear
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        footer={ false }
        title="Assets"
        open={ isModalOpen }
        onOk={ handleOk }
        onCancel={ handleCancel }
      >
        { assets.map((asset, index) => (
          <p key={ index }>
            { index + 1 }. { asset }
          </p>
        )) }
      </Modal>
      <Card title="Last Working Day Employees List">
        <div className="steps-content">
          <Search
            ref={ searchRef }
            placeholder="Search..."
            onSearch={ onSearch }
            onKeyUp={ resetSearchFilter }
            style={ { width: 200 } }
            className="mr2  "
          />
          <Button type="primary" className="filter-btn" onClick={ showfilterModal }>
            Filter
          </Button>

          <Button
            onClick={ () => {
              filterForm.resetFields();
              setFilterData([]);
              getEmployeesResignList();
            } }
            className="mr2 ant-delete"
            disabled={ filterData.length != 0 ? false : true }
          >
            Clear Filter
          </Button>
          { getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["read"]) ? (<>
            <Button
              className="ant-btn-primary export-btn"
              // disabled={ eventApiData.length > 0 && html ? false : true }
              onClick={ () => {
                csvRef.click();
                getEmployeesResignList(filterData);
              } }
            >
              Export CSV
            </Button>
            <div hidden>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="ant-btn-primary"
                table="table-to-xls"
                filename={ "Separation" }
                sheet="tablexls"
                buttonText="Export CSV"
              />
              <div dangerouslySetInnerHTML={ { __html: html["html"] } }></div>
            </div>
          </>) : <></> }
          {/* 
          {getResource(config.RESOURCES.SEPARATION_MANAGEMENT, ["read"]) ? (
            <>
              <Link to="/add-resignation-for-user">
                <Button type="primary">Add Resignation</Button>{" "}
              </Link>
              <Button
                className="ant-btn-primary"
                // disabled={ eventApiData.length > 0 && html ? false : true }
                onClick={() => {
                  csvRef.click();
                  // getEmployeesResignList(filterData);
                }}
              >
                Export CSV
              </Button>
              <div hidden>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="ant-btn-primary"
                  table="table-to-xls"
                  filename={"Separation"}
                  sheet="tablexls"
                  buttonText="Export CSV"
                />
                <div dangerouslySetInnerHTML={{ __html: html["html"] }}></div>
              </div>
            </>
          ) : (
            <></>
          )} */}
          <Table
            columns={ columns }
            dataSource={ Employees }
            footer={ getFooterDetails }
            onChange={ handleTableChange }
            pagination={ {
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              ...pagination,
            } }
          ></Table>
        </div>
      </Card>
    </div>
  );
}

export default LWDResign;

import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Space, Table, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { getResource } from '../../util/getResourcePermission';
import config from "../../permission/config.json";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { AiOutlineDelete } from 'react-icons/ai';
import Service from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { hideAuthLoader, showAuthLoader } from '../../appRedux/actions/Auth';
import moment from "moment";
import { Tag } from "antd";
import { getRoles } from '../../util/getRoles';

const BillReimbursement = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const Search = Input.Search;
//   const searchRef = useRef();
  let [filterData, setFilterData] = useState([]);
const [searchRef,setsearchRef]=useState("")
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [reimbursementData, setReimbursementData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [formEdit, setFormEdit] = useState(true);
    const [deletedAttachments, setDeletedAttachments] = useState([]);
    const [departmentArray,setdepartmentArray]=useState(["656eaa41679a64fd2b1dfa45","656eaa41679a64fd2b1dfa47","656eaa41679a64fd2b1dfa48","656eaa41679a64fd2b1dfa5c"])
    // Form instances
    // console.log(departmentArray,'departmentArray');
    
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [viewForm] = Form.useForm();

    const dispatch = useDispatch();
    const { authUser } = useSelector(({ auth }) => auth);


    const getStatusTag = (status) => {

        let color = "default";
        switch (status?.toLowerCase()) {
            case "pending":
                color = "gold";
                break;
            case "approved":
                color = "green";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "blue";
        }
        return <Tag color={color}>{status || "-"}</Tag>;
    };
    const onSelectChange = (selectedKeys) => {
        console.log("Selected Row IDs:", selectedKeys);
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => {
                return <span>{moment(record.date).format('DD MMM YYYY')}</span>;
            },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text, record) => {
                return <span>{record.title || '-'}</span>;
            },
        },

        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            render: (text, record) => {
                return <span>{record?.creatorDetails?.full_name || '-'}</span>;
            },
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            render: (text, record) => {
                return <span>{record?.departmentDetails?.department_name || '-'}</span>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => getStatusTag(record.isStatus),
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex" }}>
                        <Button type="link" title="View"
                            onClick={() => viewReimbursement(record._id)}
                        >
                            <EyeOutlined style={{ fontSize: "18px" }} />
                        </Button>

{record.isStatus=='Approved'?'':
                        <div>
                        {(getRoles(["Admin"]) || authUser._id == record.createdBy ||
                            departmentArray.includes(authUser?.subdepartment_id._id?.toString())) ? (
                            <Button type="link" title="Edit"
                                onClick={() => editReimbursement(record._id)}
                            >
                               
                                <EditOutlined style={{ fontSize: "18px", color: 'green' }} />
                            </Button>
                        ) : ''}

                        </div>}

                        {record.isStatus=='Approved'?'':

<div> {(getResource(config.RESOURCES.BILLREIMBURSEMENT, ["delete"]) ||getRoles(["Admin"]) || authUser._id == record.createdBy ||
                            departmentArray.includes(authUser?.subdepartment_id._id?.toString())) ? (
                            <Popconfirm
                                title="Are you really want to delete this?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deleteReimbursement(record._id)}
                            >
                                <Button type="link" title="Delete">
                                    <AiOutlineDelete style={{ fontSize: "18px", color: 'red' }} />
                                </Button>
                            </Popconfirm>
                        ) : ''}</div>

                        }


                    </div>
                );
            },
        },
    ];

    // Modal handlers
    const openAddModal = () => {
        addForm.resetFields();
        setAttachments([]);
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
        addForm.resetFields();
        setAttachments([]);
        form.resetFields()
    };

    const editReimbursement = async (id) => {
        try {
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.detail_reimbursement + '/' + id,
            });

            if (response && response.data && response.data.data) {
                const reimbursementDetails = response.data.data;

                editForm.setFieldsValue({
                    title: reimbursementDetails.title,
                    date: moment(reimbursementDetails.date),
                    description: reimbursementDetails.description || '',
                });

                setSelectedRecord(reimbursementDetails);
                setIsEditModalOpen(true);
            }
        } catch (error) {
            console.log(error);
            message.error('Failed to fetch reimbursement details');
        }
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        editForm.resetFields();
        setAttachments([]);
        form.resetFields()
        setSelectedRecord({});
    };

    const viewReimbursement = async (id) => {
        try {
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.detail_reimbursement + '/' + id,
            });

            if (response && response.data && response.data.data) {
                const reimbursementDetails = response.data.data;

                viewForm.setFieldsValue({
                    title: reimbursementDetails.title,
                    date: moment(reimbursementDetails.date),
                    description: reimbursementDetails.description || '',
                });

                setSelectedRecord(reimbursementDetails);
                setIsViewModalOpen(true);
            }
        } catch (error) {
            console.log(error);
            message.error('Failed to fetch reimbursement details');
        }
    };

    const closeViewModal = () => {
        setIsViewModalOpen(false);
        viewForm.resetFields();
        form.resetFields()
        setSelectedRecord({});
    };

    // API functions
    const fetchReimbursements = async () => {
        try {
            dispatch(showAuthLoader());

            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.get_reimbursement + `?page=${pagination.current}&limit=${pagination.pageSize}`,

            });

            if (response && response.data) {
                setReimbursementData(response.data.data || []);
                setTotalRecords(response.data.totalData || 0);
            }

            dispatch(hideAuthLoader());
        } catch (error) {
            console.log(error);
            dispatch(hideAuthLoader());
            message.error('Failed to fetch reimbursements');
        }
    };



    const addReimbursement = async (values) => {
        try {
            dispatch(showAuthLoader());
            console.log(values, 'values', authUser._id, Service.add_reimbursement);

            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('date', values.date.format('YYYY-MM-DD'));
            formData.append('description', values.description || '');
            formData.append('createdBy', authUser._id);
            if (fileList.length > 0) {
                fileList.forEach((file) => {
                    if (file.originFileObj) {
                        formData.append('attachments', file.originFileObj);
                    }
                });
            } else {
                message.error('Please upload at least one attachment');
                dispatch(hideAuthLoader());
                return;
            }

            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.add_reimbursement,
                body: formData,
            });

            console.log(response, 't');

            if (response && response.status) {
                message.success('Reimbursement added successfully');
                closeAddModal();
                fetchReimbursements()
                setFileList([]);
            } else {
                message.error(response.message || 'Failed to add reimbursement');
            }

            dispatch(hideAuthLoader());
        } catch (error) {
            console.log(error);
            dispatch(hideAuthLoader());
            message.error('Something went wrong. Please try again');
        }
    };


    const fetchSearchDta = async () => {
        try {
            dispatch(showAuthLoader());
            // const searchValue = searchRef.current?.value || ""; 
            // console.log(searchValue, 'searchRef.current'); // Debugging
    
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: Service.get_reimbursement + `?search=${searchRef}`,

            });

            if (response && response.data) {
                setReimbursementData(response.data.data || []);
                setTotalRecords(response.data.totalData || 0);
            }

            dispatch(hideAuthLoader());
        } catch (error) {
            console.log(error);
            dispatch(hideAuthLoader());
            message.error('Failed to fetch reimbursements');
        }
    };
    useEffect(() => {
        fetchReimbursements()
    }, [])


    const deleteReimbursement = async (id) => {
        try {
            dispatch(showAuthLoader());

            const response = await Service.makeAPICall({
                methodName: Service.deleteMethod,
                api_url: Service.deleteReimbursement,
                body: { id },
            });

            if (response && response.status) {
                message.success('Reimbursement deleted successfully');
                fetchReimbursements();
            } else {
                message.error(response.message || 'Failed to delete reimbursement');
            }

            dispatch(hideAuthLoader());
        } catch (error) {
            console.log(error);
            dispatch(hideAuthLoader());
            message.error('Something went wrong. Please try again');
        }
    };

    const updateReimbursement = async (values) => {
        try {
            dispatch(showAuthLoader());

            if (!values.title || !values.date) {
                dispatch(hideAuthLoader());
                return message.error("Please fill all required fields");
            }

            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("date", values.date.format("YYYY-MM-DD"));
            formData.append("description", values.description || "");
            formData.append("updatedBy", authUser._id);

            // **Append new attachments**
            attachments.forEach((file) => {
                formData.append("attachments", file);
            });

            // **Pass deleted attachments**
            if (deletedAttachments.length > 0) {
                formData.append("deletedAttachments", JSON.stringify(deletedAttachments));
            }
            console.log(formData, 'formData');

            // **Make API call**
            const response = await Service.makeAPICall({
                methodName: Service.putMethod,
                api_url: `${Service.update_reimbursement}/${selectedRecord._id}`,
                body: formData,
            });

            if (response && response.status) {
                message.success(response.data.message);
                closeEditModal();
                fetchReimbursements();
            } else {
                message.error(response.message || "Failed to update reimbursement");
            }
        } catch (error) {
            console.error("Update error:", error);
            message.error("Something went wrong. Please try again");
        } finally {
            dispatch(hideAuthLoader());
        }
    };
    console.log(attachments, 'attachments');

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setAttachments(prevAttachments => [...prevAttachments, ...files]);
    };


    const removeAttachment = (filename) => {
        setDeletedAttachments((prev) => [...prev, filename]);

        setAttachments((prev) =>
            prev.filter((file) => {
                if (file.name) {
                    return file.name !== filename;
                }
                return file !== filename;
            })
        );

        setSelectedRecord((prev) => ({
            ...prev,
            attachments: prev.attachments.filter((att) => att !== filename),
        }));
    };


    const handleTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize: pageSize,
        });
    };
    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const approveReimbursements = async () => {
        try {
            dispatch(showAuthLoader());

            if (selectedRowKeys.length === 0) {
                dispatch(hideAuthLoader());
                return message.error("Please select at least one reimbursement to approve.");
            }

            // **Prepare API body**
            const payload = {
                reimbursementIds: selectedRowKeys,
                status: "Approved",
                updatedBy: authUser._id,
            };

            console.log("📤 Approving Reimbursements:", payload);

            const response = await Service.makeAPICall({
                methodName: Service.putMethod,
                api_url: `${Service.updateReimbursementStatus}`,
                body: JSON.stringify(payload),
            });

            // **Handle response**
            if (response && response.status) {
                message.success(response.data.message);
                setSelectedRowKeys([]);
                fetchReimbursements();
            } else {
                message.error(response.message || "Failed to approve reimbursements.");
            }
        } catch (error) {
            console.error("❌ Approval error:", error);
            message.error("Something went wrong. Please try again.");
        } finally {
            dispatch(hideAuthLoader());
        }
    };
    const rejectReimbursements = async () => {
        try {
            dispatch(showAuthLoader());

            if (selectedRowKeys.length === 0) {
                dispatch(hideAuthLoader());
                return message.error("Please select at least one reimbursement to approve.");
            }

            const payload = {
                reimbursementIds: selectedRowKeys,
                status: "Rejected",
                updatedBy: authUser._id,
            };

            console.log("📤 Approving Reimbursements:", payload);

            const response = await Service.makeAPICall({
                methodName: Service.putMethod,
                api_url: `${Service.updateReimbursementStatus}`,
                body: JSON.stringify(payload),
            });

            if (response && response.status) {
                message.success(response.data.message);
                setSelectedRowKeys([]);
                fetchReimbursements();
            } else {
                message.error(response.message || "Failed to approve reimbursements.");
            }
        } catch (error) {
            console.error("❌ Approval error:", error);
            message.error("Something went wrong. Please try again.");
        } finally {
            dispatch(hideAuthLoader());
        }
    };


    useEffect(() => {
        fetchReimbursements();
    }, [pagination.current, pagination.pageSize]);

    useEffect(()=>{
        if(searchRef==''){
            fetchReimbursements();
        }
    },[searchRef])


    return (
        <>
            <Card title="Bill Reimbursements">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <Search
                              onChange={(e)=>setsearchRef(e.target.value)}
                              placeholder="Search..."
                              onSearch={fetchSearchDta}
                            //   onKeyUp={resetSearchFilter}
                              style={{ width: 200 }}
                              className="mr2"
                            />
                            {/* <Button
                              type="primary"
                            //   onClick={() => openEventModalView()}
                              className="mr2"
                            >
                              Filter
                            </Button>
                            <Button
                              onClick={() => {
                                form.resetFields();
                                setFilterData([]);
                                setVal("All");
                              }}
                              className="mr2"
                              disabled={filterData.length != 0 ? false : true}
                            >
                              Clear Filter
                            </Button> */}
                
                {(getResource(config.RESOURCES.BILLREIMBURSEMENT, ["create"]) || getRoles(["Admin"]) ||
                    departmentArray.includes(authUser?.subdepartment_id._id?.toString()))
                    ? (
                        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 16 }}>
                            <Button onClick={openAddModal} type="primary">
                                Add Reimbursement
                            </Button>
                        </div>
                    ) : ""}
</div>
                {/* Add Reimbursement Modal */}
                <Modal
                    title="Add Bill Reimbursement"
                    width={800}
                    open={isAddModalOpen}
                    footer={null}
                    onCancel={closeAddModal}
                    style={{ top: "40px" }}

                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={addReimbursement}
                    >
                        <div gutter={16} style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Col xs={24} md={12} style={{ paddingLeft: "0" }}>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{ required: true, message: "Please enter title" }]}
                                >
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} style={{ paddingRight: "0" }}>
                                <Form.Item
                                    label="Date"
                                    name="date"
                                    rules={[{ required: true, message: "Please select date" }]}
                                >
                                    <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                        </div>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Description" name="description">
                                    <TextArea rows={4} placeholder="Enter description (optional)" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Attachments"
                                    name="attachments"
                                    rules={[{ required: true, message: "Please upload at least one file" }]}
                                >
                                    <Upload
                                        beforeUpload={() => false} // Prevents auto upload
                                        fileList={fileList}
                                        onChange={handleFileChange}
                                        multiple
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Files</Button>
                                    </Upload>
                                </Form.Item>

                            </Col>
                        </Row>


                        <Row justify="center" style={{ marginTop: 24 }}>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                {/* Edit Reimbursement Modal */}

                <Modal
                    title="Edit Bill Reimbursement"
                    width={800}
                    open={isEditModalOpen}
                    footer={null}
                    onCancel={closeEditModal}
                    style={{ top: "40px" }}
                >
                    <Form form={editForm} onFinish={updateReimbursement} layout="vertical">
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Col xs={24} md={12} style={{ paddingLeft: "0" }} >
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{ required: true, message: 'Please enter title' }]}
                                >
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} style={{ paddingRight: "0px" }}>
                                <Form.Item
                                    label="Date"
                                    name="date"
                                    rules={[{ required: true, message: 'Please select date' }]}
                                >
                                    <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                        </div>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                    <TextArea rows={4} placeholder="Enter description (optional)" />
                                </Form.Item>
                            </Col>
                        </Row>

                        {selectedRecord.attachments && selectedRecord.attachments.length > 0 && (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div style={{ marginBottom: 16 }}>
                                        <h4>Existing Attachments:</h4>
                                        <ul>
                                            {selectedRecord.attachments.map((attachment, index) => (
                                                <li key={index}>
                                                    <a href={`${process.env.REACT_APP_API_URL}/uploads/reimbursements/${attachment}`} target="_blank" rel="noopener noreferrer">
                                                        {attachment}
                                                    </a>
                                                    <Button danger size="small" onClick={() => removeAttachment(attachment)}>
                                                        Remove
                                                    </Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Add New Attachments"
                                    name="new_attachments"
                                >
                                    <div>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleFileUpload}
                                        />
                                        <div style={{ marginTop: 8 }}>
                                            {attachments.length > 0 && (
                                                <div>
                                                    <p>Selected files:</p>
                                                    <ul>
                                                        {attachments.map((file, index) => (
                                                            <li key={index}>{file.name}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginTop: 24 }}>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>


                {/* View Reimbursement Modal */}
                <Modal
                    title="View Bill Reimbursement"
                    width={800}
                    open={isViewModalOpen}
                    footer={[
                        <Button key="close" onClick={closeViewModal}>
                            Close
                        </Button>
                    ]}
                    onCancel={closeViewModal}
                >
                    <Form form={viewForm} layout="vertical">


                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Description" name="description">
                                    <TextArea rows={4} disabled />
                                </Form.Item>
                            </Col>
                        </Row>


                        {selectedRecord.attachments && selectedRecord.attachments.length > 0 && (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div style={{ marginBottom: 16 }}>
                                        <h4>Attachments:</h4>
                                        <ul>
                                            {selectedRecord.attachments.map((attachment, index) => (
                                                <li key={index}>
                                                    <a
                                                        href={`${process.env.REACT_APP_API_URL}/uploads/reimbursements/${attachment}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {attachment.originalname || `Attachment ${index + 1}`}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </Col>
                            </Row>
                        )}

                    </Form>
                </Modal>

                

<div className="inout-header">
          <h4>Bill Reimbursements Records
          </h4>
        </div>
        {getResource(config.RESOURCES.BILLREIMBURSEMENT, ["update"]) ? (
                    <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'end' }}>
                        <Button className="mr2 ant-approve" disabled={selectedRowKeys.length > 0 ? false : true} onClick={approveReimbursements}>
                            Approve
                        </Button>
                        <Button className="mr2 ant-reject" disabled={selectedRowKeys.length > 0 ? false : true} onClick={rejectReimbursements}>
                            Reject
                        </Button>
                    </Space>
                ) : ""}
                <Table
                    columns={columns}
                    dataSource={reimbursementData}
                    rowSelection={{
                        ...rowSelection,
                        getCheckboxProps: (record) => ({
                            disabled: record?.isStatus === "approved", // Disable selection for approved rows
                        }),
                    }}                    rowKey="_id"
                    pagination={{
                        showSizeChanger: true,
                        ...pagination,
                        total: totalRecords,
                        onChange: (page, pageSize) => handleTableChange(page, pageSize),
                    }}
                    footer={() => <div>Total Records: {totalRecords}</div>}
                    rowClassName={(record) =>
                        record?.isStatus == "approved" ? "disabled-row" : ""
                      }
                />
            </Card>
        </>
    );
};
export default BillReimbursement
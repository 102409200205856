import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Form,
  Col,
  Modal,
  Popover,
  message,
  Row,
  Tag,
  Select,
  Input,
} from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import ProfileImage from "../../assets/images/default_profile.jpg";
import PdfIcon from "../../assets/images/pdf_icon.png";
import JobIcon from "../../assets/images/job_icon1.svg";
import { Link, withRouter } from "react-router-dom";
import Service from "../../service";
import { CiBellOn } from "react-icons/ci";
import { BiRefresh } from "react-icons/bi";
import { ImTicket } from "react-icons/im";

import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";

import moment from "moment";
import { notificationAccessBlock } from "../../roles";
import { capitalizeFirstLetter, relationshipArry } from "../../components/EmpInfoManagement/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const formItemLayoutContact = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const exceptThisSymbols = ["e", "E", "+", "-", "."];
const UserProfile = (props) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [dataCount, setCountdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addeditform] = Form.useForm();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [flag, setflag] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const [ModalDestry,setModalDestry]=useState(false)
const location=useLocation()
  const handleVisibleChange = (visible) => {
    setVisible(visible);
    // readNotificationFromUser();
  };

  useEffect(() => {
    // getNotification();
    setInterval(() => {
      // getNotification();
    }, 300000);
  }, []);

  // useEffect(() => {
  //   // console.log(authUser.isPasswordChanged,'chchchchchch')
  //   if (authUser.isPasswordChanged == false) {
  //     // console.log(authUser.isPasswordChanged,'chchchchchch...')

  //     success()
  //   }
  //   if (authUser?.isContactsAdded == false) {

  //     contact()
  //   }
   
   
  // }, [authUser.isPasswordChanged])

  // useEffect(()=>{
  //   if ((authUser?.isEducationDetailsAdded == false
  //     ||authUser?.isExperienceDetailsAdded
  //     ==false)&& location.pathname!=='/my-profile') {
  // // console.log( location.pathname,' location.pathname');
  
  //       EducationAndExperience()
  //       Modal.destroyAll()
  //   }
  // },[location.pathname])


  // Watch for changes in `authUser` instead of just `authUser.isPasswordChanged`

// useEffect(() => {
//     if (
//         (authUser?.isEducationDetailsAdded === false || authUser?.isExperienceDetailsAdded === false) &&
//         location.pathname !== '/my-profile'
//     ) {
//       return  EducationAndExperience();
//         Modal.destroyAll();
//     }
// }, [authUser?.isEducationDetailsAdded === false || authUser?.isExperienceDetailsAdded === false , location.pathname]); // Added `authUser` to dependencies


  const getNotification = async () => {
    setTimeout(() => {
      setflag(false);
    }, 2000);
    try {
      const reqBody = {
        id: authUser?._id,
        department_name: authUser?.department_id?.department_name,
      };
      // dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getNotification,
        body: reqBody,
      });
      if (response.data.status === 200) {
        setdata(response?.data?.data);
        setCountdata(response?.data.metaData.totalCount);
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      // dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  let ids = [
    data?.map((item) => {
      return item?._id;
    }),
  ];
  const readNotificationFromUser = async () => {
    try {
      const reqBody = {
        notification_id: authUser?._id,
        allnotification_id: ids,
      };
      // dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.readNotificationFromUser,
        body: reqBody,
      });
      if (response.data.status === 200) {
        // getNotification();
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      // dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };


  // fetch contact data from api
  const fetchContactDetails = async () => {
    try {
      const empId = authUser?._id;
      const reqBody = {
        employee_id: empId,

      };
      // if (searchText && searchText !== "") {
      //   setSearchEnabled(true);
      // }
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.contactList,
        body: reqBody,
      });


      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const handleAddEdit = async (values) => {
    try {
      const empId = authUser?._id;
      const reqBody = {
        employee_id: empId,
        employee_contact: {
          ...values,
        },
      };

      dispatch(showAuthLoader());
      await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addContact,
        body: reqBody,
      });

      fetchContactDetails();
      getUserDetailsByID();
      let isContactsAdded = user_data?.isContactsAdded;//localStorage.getItem("isContactsAdded")
      console.log(isContactsAdded, "isContactsAdded");

      if (!isContactsAdded) {
        Modal.destroyAll();
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
    // handleCloseEditModal();
  };
  const readNotification = async (notification_id, slug) => {
    try {
      if (props.location.pathname == slug) {
        window.location.href = slug;
      }
      setVisible(false);
      const reqBody = {
        notification_id: notification_id,
        user_id: authUser?._id,
      };
      //   dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.readNotification,
        body: reqBody,
      });
      if (response.data.status === 200) {
        // setdata(response?.data?.data);
        // setCountdata(response?.data.metaData.totalCount);
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      //   dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };
  const getUserDetailsByID = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.empById + "/" + authUser?._id,
        body: {},
      });

      const userData = response.data.data;
      localStorage.setItem("user_data", JSON.stringify(userData));
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  }


  const changepassword = async (value) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value?.current_password,
        newPassword: value?.confirm_password,
        emp_id: authUser?._id,
        isPasswordChanged: true,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmschangepassword,
        body: reqBody,
      });
      if (response.data.status === 200) {
        message.success(response.data.message);
        setIsModalOpen(false);
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

useEffect(() => {
    if (authUser?.isPasswordChanged === false) {
        success();
    }
    if (authUser?.isContactsAdded === false) {
        contact();
    }
    if (
      
      authUser.isEducationDetailsAdded ==false&&
      location.pathname !== "/my-profile"
    ) {
        EducationAndExperience();
     
    }
    if ((authUser.experience != 0 && authUser.isExperienceDetailsAdded==false) &&
      location.pathname !== "/my-profile"
    ) {
        EducationAndExperience();
       
    }
// console.log(authUser,'authUser');



}, [authUser]);  // 🔥 Ensure re-run when authUser or pathname changes
 
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const [openPopover, setOpenPopover] = useState(false);

  // const { authUser } = useSelector(({ auth }) => auth);  
  const first_name =
    authUser.first_name.charAt(0).toUpperCase() + authUser.first_name.slice(1);
  const last_name =
    authUser.last_name.charAt(0).toUpperCase() + authUser.last_name.slice(1);

  const userName = first_name + " " + last_name;

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser?.emp_code && (
        <>
          {" "}
          <li
            onClick={() => {
              setOpenPopover(false)
              props.history.push("/my-profile");
            }}
          >
            Edit Profile
          </li>
          <li onClick={() => {
            props.history.push('/employee-overview')
          }}>Employee Overview</li>
          <li onClick={showModal}>Change Password</li>
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
      {!authUser?.emp_code && (
        <>
          {" "}
          <li onClick={() => props.history.push("/admin-edit")}>
            Edit Profile
          </li>
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
    </ul>
  );

  const changepasswordForCommon = async (value) => {

    // console.log(value,'valuevalue',isPasswordData);

    try {
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value.old_password,
        newPassword: value.new_password,
        emp_id: authUser?._id,
        isPasswordChanged: true
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmschangepassword,
        body: reqBody,
      });
      if (response.data.status == 200) {
        message.success(response.data.message);
        // setIsModalOpen(false);
        // dispatch(setIsPasswordChange(false))

        Modal.destroyAll();
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };



  const success = () => {
    Modal.warning({
      title: 'Change Password',
      content: (
        <>
          <div className="CVhangePassword">
            <Form
              onFinish={changepasswordForCommon}
              {...formItemLayout}
            >
              <Form.Item
                label="Current Password"
                name="old_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter Current Password!",
                  },
                ]}
              >
                <Input.Password name="old_password" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter New Password!",
                  },
                ]}
              >
                <Input.Password name="new_password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                className="search-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter Confirm Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The confirm passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="profilecenter-uploadbtn">
                <Button type="primary" htmlType="submit">
                  Change Password
                </Button>
              </div>
            </Form>
          </div>

        </>
      ),
      footer: false,
      style: { textAlign: 'center' },
    });
  };

  const contact = () => {
    Modal.warning({
      title: "Add Contact",
      content: (
        <>
          <div className="basic_info_form_wrapper">
            <Form {...formItemLayoutContact} form={addeditform} onFinish={handleAddEdit}>
              <Row>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                      {
                        max: 30,
                        message: "Name should be less than 30 characters",
                      },
                    ]}
                    normalize={capitalizeFirstLetter}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="mobile"
                    label="Contact No."
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Contact Number",
                      },
                      {
                        len: 10,
                        message: "Please enter valid Contact Number",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        addeditform.setFieldsValue({ mobile: value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="relationship"
                    label="Relationship"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Relationship",
                      },
                      {
                        max: 30,
                        message: "Relationship should be less than 30 characters",
                      },
                    ]}
                  >
                    <Select placeholder="Select Relationship">
                      {relationshipArry?.map((i) => (
                        <Select.Option key={i} value={i}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    {...formItemLayout}
                    className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                  >
                    <div className="add-leave-button">
                      <Button type="primary" htmlType="submit" id="addbutton">
                        Submit
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      ),
      footer: false,
      style: { textAlign: "center" },

    });

  }

  const EducationAndExperience = () => {
    Modal.warning({
      title: "Add Details",
      content: (
        <>
          <div className="basic_info_form_wrapper">
            <p>Please Fill Your Pending Details
            </p>
            {/* <Link to='/my-profile'> */}
                              <Button
                                className="btn-success"
                              onClick={()=>{
                                props.history.push('/my-profile')
                                Modal.destroyAll()                              }}
                              >
                              Click
                              </Button>
                            {/* </Link> */}
           
                              {/* <a href={'}>
                              <Button
                                className="btn-success"
                               
                              >
                               Click 
                              </Button>
                            </a> */}
          </div>
        </>
      ),
      footer: false,
      style: { textAlign: "center" },

    });

  }
  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      { }

      {notificationAccessBlock.includes(authUser?.role_id) ? <></> : <Popover
        placement="bottomRight"
        open={visible}
        onOpenChange={handleVisibleChange}
        content={
          <div className="notidication-modal">
            <div className="refresh-icon-box">
              <span
                className={
                  flag == true ? "refresh-icon rotation" : "refresh-icon"
                }
                id="refresh-icon"
                onClick={() => {
                  setflag(true);
                  // getNotification();
                }}
              >
                <BiRefresh />
              </span>
            </div>
            <ul className="notify-ul">
              {data.length > 0 ? (
                data.map((item) => (
                  <li>
                    <div className="label">
                      <div className="label-date">
                        {item?.notification_type == "0" ? (
                          <label style={{ background: "#ff5252" }}>
                            {item?.leave_type} Applied
                          </label>
                        ) : item?.notification_type == "1" ? (
                          <label style={{ background: "#4b40ff" }}>
                            Attendance Regularization Applied
                          </label>
                        ) : item?.notification_type == "2" ? (
                          <label style={{ background: "#72008f" }}>
                            WFH Request Applied
                          </label>
                        ) : // item?.notification_type == "3" ? (
                          //   <label style={{ background: "#0e7e0e" }}>
                          //     {item.award_type} of The Month
                          //   </label>
                          // ) :
                          item?.notification_type == "4" ? (
                            <label style={{ background: "#187cb7" }}>
                              New Polices Added
                            </label>
                          ) : item?.notification_type == "5" ? (
                            <label style={{ background: "#b36624" }}>
                              New Ticket Added
                            </label>
                          ) : item?.notification_type == "6" ? (
                            <label style={{ background: "#cf5f00" }}>
                              Comp-off Applied
                            </label>
                          ) : item?.notification_type == "7" ? (
                            <label style={{ background: "#4CAF50" }}>
                              New Comment Added
                            </label>
                          ) : //  item?.notification_type == "8" ? (
                            //   <label style={{ background: "#cf008f" }}>
                            //     Happy Birthday
                            //   </label>
                            // )                         :
                            // item?.notification_type == "9" ? (
                            //   <label style={{ background: "#5c1c49" }}>
                            //     Happy Work Anniversary
                            //   </label>
                            // ) :
                            item?.notification_type == "10" ? (
                              <label style={{ background: "#e71269" }}>
                                Reminder{" "}
                              </label>
                            ) : item?.notification_type == "12" ? (
                              <label style={{ background: "#bd6eeb" }}>
                                New Job opening created
                              </label>
                            ) : item?.notification_type == "14" ? (
                              <label style={{ background: "#4CAF50" }}>
                                Job status changed
                              </label>
                            ) : item?.notification_type == "13" ? (
                              <label style={{ background: "#4CAF50" }}>
                                New Comment Added For A Job
                              </label>
                            ) : item?.notification_type == "15" ? (
                              <label style={{ background: "#4b40ff" }}>
                                On Duty Regularization Applied
                              </label>
                            )
                              : (
                                ""
                              )}
                        <div className="date-time">
                          <span>
                            {moment(item?.created_At).format("DD/MM/YYYY")}
                          </span>
                          |
                          <span>
                            {moment(item?.created_At).format("hh:mm a")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="app-notify">
                      {item?.notification_type == "12" ||
                        item?.notification_type == "14" ? (
                        <img
                          src={JobIcon}
                          className="job-icon"
                          alt="job-icon"
                          width="35"
                          height="35"
                        />
                      ) : item?.notification_type == "4" ? (
                        <img
                          src={PdfIcon}
                          className="pdf-icon"
                          alt="pdf-icon"
                          width="35"
                          height="45"
                        />
                      ) : (
                        <img
                          src={
                            item.emp_img !== ""
                              ? `${Service.Server_Base_URL}/uploads/emp_images/${item.emp_img}`
                              : ProfileImage
                          }
                          className="avatar-user"
                          alt="user-icon"
                        />
                      )}
                      <div
                        className={
                          item?.notification_type == "4"
                            ? "policy-notify-data notify-data"
                            : "notify-data"
                        }
                      >
                        <div className="notify-name">
                          {item?.notification_type == "4" && (
                            <h5>Policy name : {item?.policy_name}</h5>
                          )}

                          {item?.notification_type == "0" && (
                            <>
                              <p>
                                <b>Name :</b> {item?.employee_name}
                              </p>
                              <p>
                                <b>From Date :</b>{" "}
                                {moment(item?.from_date).format("DD/MM/YYYY")}
                              </p>
                              <p>
                                <b>To Date :</b>{" "}
                                {moment(item?.to_date).format("DD/MM/YYYY")}
                              </p>
                              <p>
                                <b>Leave Type:</b> {item.leave_type}
                              </p>
                            </>
                          )}
                          {item.notification_type == "1" && (
                            <>
                              <p>
                                <b>Name :</b> {item?.employee_name}
                              </p>
                              <p>
                                <b>Date :</b>{" "}
                                {moment(item?.regulizarions_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                <b>Type :</b> Attendance Regularization
                              </p>
                            </>
                          )}
                          {item.notification_type == "2" && (
                            <>
                              <p>
                                <b>Name :</b> {item?.employee_name}
                              </p>
                              <p>
                                Date :{" "}
                                {moment(item?.wfh_from_date).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                              </p>
                              <p>
                                <b>Type :</b> WFH Request
                              </p>
                            </>
                          )}
                          {/* {item.notification_type == "3" && (
                            <>
                              <p>Achiever : {item?.employee_name}</p>
                              <p>Profile: {item?.designation_name}</p>
                            </>
                          )} */}

                          {item.notification_type == "5" && (
                            <>
                              <p>
                                <b>Subject:</b> {item.ticket_name}{" "}
                              </p>
                              <p>
                                <b>Ticket Raiser:</b> {item.employee_name}
                              </p>
                            </>
                          )}
                          {item.notification_type == "6" && (
                            <>
                              <p>
                                <b>Name :</b> {item.employee_name}{" "}
                              </p>
                              <p>
                                <b>Extra Work Date :</b>{" "}
                                {moment(item.compoff_date).format("DD/MM/YYYY")}
                              </p>
                            </>
                          )}
                          {item.notification_type == "7" && (
                            <>
                              <p>
                                <b>Name :</b> {item.employee_name}{" "}
                              </p>
                              <p>
                                <b>Ticket Name:</b> {item.ticket_name}
                              </p>
                            </>
                          )}
                          {item.notification_type == "12" && (
                            <>
                              <p>
                                <b>Job Title :</b> {item.jobs.title}{" "}
                              </p>
                              <p>
                                <b>Openings:</b> {item.jobs.no_of_opening}
                              </p>
                              <p>
                                <b>Priority:</b> {item.jobs.priority}
                              </p>
                            </>
                          )}
                          {item.notification_type == "14" && (
                            <>
                              <p>
                                <b>Job Title :</b> {item.jobs.title}{" "}
                              </p>
                              <p>
                                <b>Openings:</b> {item.jobs.no_of_opening}
                              </p>
                              <p>
                                <b>Priority:</b> {item.jobs.priority}
                              </p>
                            </>
                          )}
                          {item.notification_type == "13" && (
                            <>
                              <p>
                                <b>Name: </b>
                                {item.employee_name}
                              </p>
                              <p>
                                <b>Job Title:</b> {item.jobs?.title}
                              </p>
                            </>
                          )}
                          {/* {item.notification_type == "9" && (
                            <>
                              {authUser?._id == item?.sender_id ? (
                                <p>
                                  Elsner Technologies Wishing You a{" "}
                                  {item.work_complete} year Work Anniversary
                                </p>
                              ) : (
                                <p>
                                  Make a wish for {item.employee_name} To
                                  complete {item.work_complete} year In Elsner.
                                </p>
                              )}
                            </>
                          )} */}
                          {/* {item.notification_type == "8" && (
                            <>
                              {authUser?._id == item?.sender_id ? (
                                <p>
                                  Elsner Technologies Wishing You a Very Happy
                                  birthday.
                                </p>
                              ) : (
                                <p>
                                  Make a birthday wish for {item.employee_name}
                                </p>
                              )}
                            </>
                          )} */}
                          {item.notification_type == "10" && (
                            <>
                              <p>
                                Reminder! {item?.employee_name} Probation period
                                is about to end in 7 days.
                              </p>
                            </>
                          )}
                          {item.notification_type == "15" && (
                            <>
                              <p>
                                <b>Name :</b> {item?.employee_name}
                              </p>
                              <p>
                                <b>From Date :</b>{" "}
                                {moment(item?.onduty_from_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                <b>To Date :</b>{" "}
                                {moment(item?.onduty_to_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                <b>Type :</b> {item?.onduty_regularization_type}
                              </p>
                            </>
                          )}
                          {/* <>
                            <p>
                              <b>Profile:</b> Jr. JS Developer
                            </p>
                            <p>
                              Probation ending: 23-Apr
                            </p>
                          </> */}
                        </div>
                        {/* {item.notification_type == "3" && (
                          <img
                            src={CelebrationIcon}
                            className="represent-icon celebration-icon"
                            alt="celebration-icon"
                            width="45"
                            height="45"
                          />
                        )} */}
                        {item.notification_type == "5" && (
                          <div className="notify-status">
                            <a href={item?.slug}>
                              <Button
                                className="btn-success"
                                onClick={() => setVisible(false)}
                              >
                                View Ticket
                              </Button>
                            </a>
                          </div>
                        )}
                        {item.notification_type == "7" && (
                          <div className="notify-status">
                            <a href={item?.slug}>
                              <Button
                                className="btn-success"
                                onClick={() => setVisible(false)}
                              >
                                View Ticket Comment
                              </Button>
                            </a>
                          </div>
                        )}
                        {item.notification_type == "4" && (
                          <div className="notify-status">
                            <Link to={item?.slug}>
                              <Button
                                className="btn-success"
                                onClick={() => {
                                  readNotification(item._id, item.slug);
                                  setVisible(false);
                                }}
                              >
                                View Policy
                              </Button>
                            </Link>
                          </div>
                        )}
                        {(item.notification_type == "12" ||
                          item.notification_type == "14" ||
                          item.notification_type == "13") && (
                            <div className="notify-status">
                              <Link to={item?.slug}>
                                <Button
                                  className="btn-success"
                                  onClick={() => {
                                    readNotification(item._id, item.slug);
                                    setVisible(false);
                                  }}
                                >
                                  View Job
                                </Button>
                              </Link>
                            </div>
                          )}

                        {item?.notification_type == "6" && (
                          <div className="notify-status">
                            <>
                              {item?.compoff_status == "Rejected" ? (
                                <label className="status-error">Rejected</label>
                              ) : item?.notification_status == "Accepted" ? (
                                <label className="status-success">
                                  Approved
                                </label>
                              ) : item?.tl_compoff_status == "Accepted" &&
                                item?.director_compoff_status == "Pending" &&
                                authUser?._id != "63b6ca8770f2b5b3002cb43e" ? (
                                <label className="status-success">
                                  Approved
                                </label>
                              ) : item?.tl_compoff_status == "Rejected" &&
                                item?.director_compoff_status == "Pending" &&
                                authUser?._id != "63b6ca8770f2b5b3002cb43e" ? (
                                <label className="status-error">Rejected</label>
                              ) : item?.director_compoff_status == "Accepted" &&
                                item?.ceo_compoff_status == "Pending" &&
                                authUser?._id != "63b6ca8670f2b5b3002cb433" ? (
                                <label className="status-success">
                                  Approved
                                </label>
                              ) : item?.director_compoff_status == "Rejected" &&
                                item?.ceo_compoff_status == "Pending" &&
                                authUser?._id != "63b6ca8670f2b5b3002cb433" ? (
                                <label className="status-error">Rejected</label>
                              ) : item?.ceo_compoff_status == "Accepted" &&
                                authUser?._id == "63b6ca8670f2b5b3002cb433" ? (
                                <label className="status-success">
                                  Approved
                                </label>
                              ) : item?.ceo_compoff_status == "Rejected" &&
                                authUser?._id == "63b6ca8670f2b5b3002cb433" ? (
                                <label className="status-error">Rejected</label>
                              ) : (
                                <Link to={item.slug}>
                                  <Button
                                    className="btn-success"
                                    onClick={(event) => {
                                      readNotification(item._id, item.slug);
                                      setVisible(false);
                                    }}
                                  >
                                    Click to Approve/Reject
                                  </Button>
                                </Link>
                              )}
                            </>
                          </div>
                        )}

                        {item.status == "Pending" ? (
                          authUser?.emp_code == item.employee_code ? (
                            item.status && (
                              <div className="notify-status">
                                {item.status == "Pending" ? (
                                  <label className="status-error">
                                    {item.status}
                                  </label>
                                ) : (
                                  <label className="status-success">
                                    {item.status}
                                  </label>
                                )}
                              </div>
                            )
                          ) : (
                            <div className="notify-status">
                              {item?.notification_type == "0" && (
                                <>
                                  {item?.leave_status == "Accepted" ? (
                                    <div className="notify-status">
                                      <label className="status-success">
                                        Approved
                                      </label>
                                    </div>
                                  ) : item?.leave_status == "Rejected" ? (
                                    <div className="notify-status">
                                      <label className="status-error">
                                        Rejected
                                      </label>
                                    </div>
                                  ) : (
                                    <>
                                      <Link to={item.slug}>
                                        <Button
                                          className="btn-success"
                                          onClick={(event) => {
                                            readNotification(
                                              item._id,
                                              item.slug
                                            );
                                            setVisible(false);
                                          }}
                                        >
                                          Click to Approve/Reject
                                        </Button>
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}

                              {item?.notification_type == "1" && (
                                <>
                                  {
                                    item?.tl_regularized_status == "approved" &&
                                      (item?.director_regularized_status ==
                                        "pending" || item?.director_regularized_status ==
                                        "approved") &&
                                      authUser?._id !=
                                      "63b6ca8770f2b5b3002cb43e"
                                      &&
                                      authUser?._id !=
                                      "63b6ca9270f2b5b3002cb552" &&
                                      authUser?._id !=
                                      "63b6ca8670f2b5b3002cb433" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* TL */}
                                      </label>
                                    ) : item?.director_regularized_status ==
                                      "approved" &&
                                      (item?.ceo_regularized_status == "pending"
                                        || item?.ceo_regularized_status == "approved") &&
                                      authUser?._id !=
                                      "63b6ca8670f2b5b3002cb433" &&
                                      authUser?._id !=
                                      "63b6ca9270f2b5b3002cb552" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* Director */}
                                      </label>
                                    ) : item?.ceo_regularized_status ==
                                      "approved" &&
                                      authUser?._id ==
                                      "63b6ca8670f2b5b3002cb433" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* CEO */}
                                      </label>
                                    )
                                      :
                                      item?.ceo_regularized_status ==
                                        "approved" &&
                                        item?.admin_regularized_status == "approved" &&
                                        authUser?._id ==
                                        "63b6ca9270f2b5b3002cb552" ? (
                                        <label className="status-success">
                                          Approved
                                          {/* ADMIN */}
                                        </label>
                                      )
                                        : (
                                          <Link to={item.slug}>
                                            <Button
                                              className="btn-success"
                                              onClick={(event) => {
                                                readNotification(item._id, item.slug);
                                                setVisible(false);
                                              }}
                                            >
                                              Click to Approve/Reject
                                            </Button>
                                          </Link>
                                        )}
                                  {/* {item?.status == "Accepted" ? (
                                    <div className="notify-status">
                                      <label className="status-success">
                                        Approved
                                      </label>
                                    </div>
                                  ) : item?.status == "Rejected" ? (
                                    <div className="notify-status">
                                      <label className="status-error">
                                        Rejected
                                      </label>
                                    </div>
                                  ) : authUser?.is_reporting_manager == true &&
                                    item?.tl_regularized_status ==
                                      "approved" ? (
                                    <label className="status-success">
                                      Approved
                                    </label>
                                  ) : (
                                    <Link to={item.slug}>
                                      <Button
                                        className="btn-success"
                                        onClick={(event) => {
                                          readNotification(item._id, item.slug);
                                          setVisible(false);
                                        }}
                                      >
                                        Click to Approve/Reject
                                      </Button>
                                    </Link>
                                  )} */}

                                  {/* <Link to={item.slug}>
                                    <Button
                                      className="btn-success"
                                      onClick={(event) => {
                                        readNotification(item._id, item.slug);
                                        setVisible(false);
                                      }}
                                    >
                                      Click to Approve/Reject
                                    </Button>
                                  </Link> */}
                                </>
                              )}

                              {item?.notification_type == "2" && (
                                <Link to={item.slug}>
                                  <Button
                                    className="btn-success"
                                    onClick={(event) => {
                                      readNotification(item._id, item.slug);
                                      setVisible(false);
                                    }}
                                  >
                                    Click to Approve/Reject
                                  </Button>
                                </Link>
                              )}

                              {item?.notification_type == "15" && (
                                <>
                                  {item?.onduty_status == "Accepted" ? (
                                    <div className="notify-status">
                                      <label className="status-success">
                                        Approved
                                      </label>
                                    </div>
                                  ) : item?.onduty_status == "Rejected" ? (
                                    <div className="notify-status">
                                      <label className="status-error">
                                        Rejected
                                      </label>
                                    </div>
                                  ) : (
                                    <>
                                      <Link to={item.slug}>
                                        <Button
                                          className="btn-success"
                                          onClick={(event) => {
                                            readNotification(
                                              item._id,
                                              item.slug
                                            );
                                            setVisible(false);
                                          }}
                                        >
                                          Click to Approve/Reject
                                        </Button>
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          )
                        ) : item.status == "Approved" ? (
                          <div className="notify-status">
                            <label className="status-success">Approved</label>
                          </div>
                        ) : (
                          item.status && (
                            <div className="notify-status">
                              <label className="status-error">
                                {item.status}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p className="not-found">No New notifications</p>
              )}
            </ul>
          </div>
        }
        trigger="click"
      >
        <div className="bell-icon">
          <CiBellOn className="notification-icon" />
          {dataCount != "" ? (
            <span className="count">{dataCount}</span>
          ) : (
            <span className=" no_count"></span>
          )}
        </div>
      </Popover>}

      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        onOpenChange={(newVisible) => setOpenPopover(newVisible)}
      >
        <img
          src={
            authUser.emp_img
              ? `${Service.Server_Base_URL}/uploads/emp_images/${authUser.emp_img}`
              : ProfileImage
          }
          className="avatar-user"
          alt="User"
        />
        <span className="gx-avatar-name">
          {userName}
          <i className="icon icon-chevron-down gx-ml-2" />
        </span>
      </Popover>

      <Modal
        footer={false}
        title="Change Password"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={(values) => {
            changepassword(values);
          }}
          {...formItemLayout}
        >
          <Form.Item
            label="Current Password"
            name="current_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Current Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter New Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Confirm Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The confirm passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="profilecenter-uploadbtn">
            <Button key="back" type="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default withRouter(UserProfile);

import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Card,
  Checkbox,
  Select,
  Col,
  Row,
  message,
  Radio
} from "antd";
import Service from "../../service";
import { Link } from "react-router-dom";
import Logowhite from "assets/images/Logowhite.svg";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import { useDispatch } from "react-redux";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import "./DayOneFeedbackForm.css";

const DayOneFeedbackForm = (props) => {
  const userData = localStorage.getItem('user_data') || {};
  const userId = localStorage.getItem('user_data') ? JSON.parse(userData)?._id : "";
  const params_id = userId;

  const [form] = Form.useForm();
  const Logo = localStorage.getItem("LogoURL");
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState({});
  const [isFormsubmited, setIsFormsubmited] = useState(false);





  useEffect(() => {
    if (params_id != "") {
      fetchEmpById(params_id);
      getFeedbackStatus(); 
    } else {
      window.location = "/signin"
    }

  }, []);
  const questions = [
    "You have attended orientation session/meeting with HR",
    "You had got a walk through of company policies & procedures",
    "You have got a walk through of HRMS",
    "You are aware of your working days & shift timings",
    "You have understood the leave policy",
    "You have understood the probation confirmation policy",
    "You have understood the 7, 15 & 30 days feedback mechanism",
    "You have understood the quarterly ranking system at Elsner",
    "You have received an access to your official email ID",
    "You have understood the ticketing concept for your grievances",
    "You had a brief about Elsner Got Talent & Elsner Elevate",
    "You had a walk through of the hierarchy of your department",
    "You have received employee welcome kit",
    "You had your system ready on the day of your joining",
    "You had an introduction with your workplace buddy?",
    "You had an introduction with your reporting manager?",
  ];

  const induction = [
    "Your Induction Program Was Informative",
    "Your Induction Program Was well organized",
    "Your Induction Program Helped you feel more prepared",
    "Your Induction Program Helped you feel more at ease with colleagues",
    "Your Induction Program Company rules were explained clearly",
  ];



  const fetchEmpById = async (params_id) => {
    // use this userID params to fetch emp data for admin and TLs
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.empById + "/" + params_id,
      });

      if (
        response.data.status == 200 &&
        response?.data &&
        response?.data?.data
      ) {
        setResponseData(response.data);
        // setEmpDetails(response?.data?.data);
        // setDeptData(response?.data?.data?.subdepartment_id?._id)
      }

      if (response.data.status == 403) {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
      //   setMount(false);
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };

  const getFeedbackStatus = async () => {
    // use this userID params to fetch emp data for admin and TLs
    try {
      dispatch(showAuthLoader());
      const payload = {
        userId : params_id,
        feedbackType: "dayOneFeedback"
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getFeedbackStatus,
        body:payload
      });

      if (
        response.data.status == 200 &&
        response?.data &&
        response?.data?.message== "Day One feedback is already provided"
      ) {
       setIsFormsubmited(true);
        // setResponseData(response.data);
        // setEmpDetails(response?.data?.data);
        // setDeptData(response?.data?.data?.subdepartment_id?._id)
      }

     
      dispatch(hideAuthLoader());
      //   setMount(false);
    } catch (error) {
      console.log(error);
      dispatch(hideAuthLoader());
    }
  };


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      md: { span: 8 },
      lg: { span: 13 },
    },
    wrapperCol: {
      xs: { span: 24 },
      md: { span: 16 },
      lg: { span: 11 },
    },
  };


  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();
      const confirmation ={
        "session_with_hr":formValues?.question_0,
        "walk_through_policies":formValues?.question_1,
        "walk_through_hrms":formValues?.question_2,
        "aware_of_timings":formValues?.question_3,
        "understood_leave_policies":formValues?.question_4,
        "understood_probation_confirmation_policy":formValues?.question_5,
        "understood_feedback_mechanism":formValues?.question_6,
        "understood_quarterly_ranking_system":formValues?.question_7,
        "got_official_email":formValues?.question_8,
        "understood_ticketing_concept":formValues?.question_9,
        "brief_about_egt":formValues?.question_10,
        "walk_through_of_the_hierarchy_of_dept":formValues?.question_11,
        "received_welcome_kit":formValues?.question_12,
        "system_ready":formValues?.question_13,
        "introduction_with_buddy":formValues?.question_14,
        "introduction_with_repoting_manager":formValues?.question_15
      }

      const inductionQuestions= {
        "was_informative":formValues?.induction_question_0,
        "was_well_organized":formValues?.induction_question_1,
        "Helped_feel_more_prepared":formValues?.induction_question_2,
        "ease_with_colleagues":formValues?.induction_question_3,
        "company_rules_explained":formValues?.induction_question_4
      }
      
      const payload = {
        created_by: params_id, // Pass the logged-in user ID
        dayOneFeedback: {
          confirmation: confirmation,
          induction: inductionQuestions,
          onboarding_exp: formValues?.onboarding_rating,
          rating_justification: formValues?.justify_ratings,
          feedback_suggestion: formValues?.initial_feedback,
        },
      };

  
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.postNewFeedbackData,
        body: payload,
      });
  
      if (response.status === 200) {
        message.success("Feedback submitted successfully!");
        setIsFormsubmited(true);
      } else {
        message.error(response.data?.message || "Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      message.error("Failed to submit feedback. Please try again.");
    }
  };
  return (
    <>
      <div className="gx-app-login-wrap account-login">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content feedback-form-div-first">
              <div className="gx-app-logo" style={{ paddingBottom: "80px" }}>
                {Logo ? (
                  <img alt="example" src={Logo} />
                ) : (
                  <img alt="example" src={Logowhite} />
                )}
              </div>
              <div className="gx-app-login-left-content">
                <h6>Welcome to,</h6>
                <h2>Elsner HRMS Portal</h2>
              </div>
            </div>
            {(responseData.status !== 403 && !isFormsubmited) && (
              <div className="gx-app-login-content feedback-form-div-second">
                <Card style={{ overflowX: "hidden", margin: "0" }}>
                  <div className="inout-header">
                    <h2>Day One Feedback Form</h2>
                  </div>
                  <Form
                    form={form}
                    className="feedback"
                    noValidate={true}
                    {...formItemLayout}
                    onFinish={handleSubmit}
                  >
                    <Row>
                      <Col md={24} sm={24}>
                        <Form.Item
                          className="top-field"
                          label="Name"
                          name="fname"
                          initialValue={responseData ? responseData.data?.full_name : "-"}
                        >
                          <span>{responseData ? responseData.data?.full_name : "-"}</span>
                        </Form.Item>
                        <Form.Item
                          className="top-field"
                          label="Official Email ID"
                          name="email"
                          initialValue={responseData ? responseData.data?.email : "-"}
                        >
                          <span>{responseData ? responseData.data?.email : "-"}</span>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          label="Contact Number"
                          name="contact_number"
                          initialValue={responseData ? responseData.data?.personal_information?.personal_pnum : "-"}
                        >
                          <span>{responseData ? responseData.data?.personal_information?.personal_pnum : "-"}</span>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          label="Employee Code"
                          name="emp_code"
                          initialValue={responseData ? responseData.data?.emp_code : "-"}
                        >
                          <span>{responseData ? responseData.data?.emp_code : "-"}</span>
                        </Form.Item>

                        <Form.Item
                          className="top-field"
                          label="Date of joining"
                          name="joining_date"
                          initialValue={responseData ? responseData.data?.created_At : "-"}
                        >
                          <span>{responseData ? moment(responseData?.data?.created_At).format("DD-MM-YYYY") : "-"}</span>
                        </Form.Item>
                        {/* <Form.Item
                          className="feedback-day-one"
                          label="Emergency Contact Number"
                          name="emergency_contact"
                          rules={[
                            { required: true, message: "Please provide an emergency contact number." },
                            {
                              pattern: /^[0-9]{10}$/,
                              message: "Please enter a valid 10-digit contact number.",
                            },
                          ]}
                        >
                          <Input
                            maxLength={10} // Ensures the user can't input more than 10 digits
                          />
                        </Form.Item> */}

                        <Form.Item
                          className="top-field"
                          label="Branch Location"
                          name="branch_location"
                          initialValue={responseData ? responseData.data?.branch : "-"}
                        >
                          <span>{responseData ? responseData.data?.branch : "-"}</span>
                        </Form.Item>
                        {questions.map((question, index) => (
                          <Form.Item
                            key={index}
                            label={question}
                            name={`question_${index}`} // The name is still used for form submission but no default value is set
                          >
                            <Radio.Group>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        ))}

                        {induction.map((question, index) => (
                          <Form.Item
                            key={index}
                            label={`Your Induction Program: ${question}`}
                            name={`induction_question_${index}`} // Unique name for each question
                          >
                            <Select placeholder="--Select--">
                              <Select.Option value="Strongly Agree">Strongly Agree</Select.Option>
                              <Select.Option value="Agree">Agree</Select.Option>
                              <Select.Option value="Neither Agree Nor Disagree">
                                Neither Agree Nor Disagree
                              </Select.Option>
                              <Select.Option value="Disagree">Disagree</Select.Option>
                              <Select.Option value="Strongly Disagree">Strongly Disagree</Select.Option>
                            </Select>
                          </Form.Item>
                        ))}

                        <Form.Item
                          className="top-field"
                          label="Please rate your onboarding experience at Elsner"
                          name="onboarding_rating"
                        >
                          <Select placeholder="--Select--">
                            <Select.Option value="1">1 (Lowest)</Select.Option>
                            <Select.Option value="2">2</Select.Option>
                            <Select.Option value="3">
                              3
                            </Select.Option>
                            <Select.Option value="4">4</Select.Option>
                            <Select.Option value="5">5 (Highest)</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="feedback-day-one"
                          label="Please justify the above ratings"
                          name="justify_ratings"
                          rules={[
                            { required: true, message: "Please provide justification for your ratings." },
                          ]}
                        >
                          <Input.TextArea

                            rows={4}
                          />
                        </Form.Item>

                        {/* Initial Feedback */}
                        <Form.Item
                          className="feedback-day-one"
                          label="Do you have any initial feedback or suggestions regarding your onboarding experience? If yes, then please specify"
                          rules={[
                            { required: true, message: "Please provide feedback or suggestions." },
                          ]}
                          name="initial_feedback"
                        >
                          <Input.TextArea
                            rows={4}
                          />
                        </Form.Item>

                      </Col>
                    </Row>
                    <Form.Item className="ant-col-xs-24 ant-col-sm-24 ant-col-lg-10 leavebtns">
                      <div className="add-leave-button ">
                        <Button id="addbutton" type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            )}
             {isFormsubmited && (
                          <div className="gx-app-login-content response-msg">
                            <h2>Day one feedback submitted</h2>
                          </div>
                        )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DayOneFeedbackForm;

import React from "react";
import { Button, Input, message, Form } from "antd";
import Service from "../service";
import Logowhite from "assets/images/Logowhite.svg";

const ResetPassword = props => {
  const Logo = localStorage.getItem("LogoURL");

  const handleSubmit = async values => {
    try {
      const token =
        props.match?.params?.token || props.computedMatch?.params?.token;

      if (typeof token === "undefined" || token === null || token === "") {
        return message.error("Reset token not found!");
      }
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmsresetpassword,
        body: {
          // email: values?.email,
          password: values?.password,
          emailResetToken: token,
        },
      });

      if (response?.data) {

        message.success(response?.data?.message);
        props.history.push("/signin");
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="gx-app-login-wrap account-login">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo account_logo">
                { Logo ? (
                  <img alt="example" src={ Logo } />
                ) : (
                  <img alt="example" src={ Logowhite } />
                ) }
              </div>
              <div className="gx-app-login-left-content">
                <h6>Welcome to,</h6>
                <h2>Elsner HRMS Portal</h2>
              </div>
            </div>
            <div className="gx-app-login-content">
              <div className="form-center-login">
                <div className="gx-app-logo-wid">
                  <h2>Reset Password</h2>
                </div>
                <Form
                  name="basic"
                  onFinish={ handleSubmit }
                  className="gx-signin-form gx-form-row0"
                >
                  <div className="form-label">
                    <span>
                      <label>Password</label>
                    </span>
                  </div>
                  <div className="form-content">
                    <span className="login-icon">
                      <i className="fa fa-lock"></i>
                    </span>
                    <Form.Item
                      name="password"
                      hasFeedback
                      rules={ [
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ] }
                    >
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                  </div>
                  <div className="form-label">
                    <span>
                      <label>Confirm Password</label>
                    </span>
                  </div>
                  <div className="form-content">
                    <Form.Item
                      name="confirm"
                      hasFeedback
                      rules={ [
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The Confirm passwords that you entered do not match!"
                            );
                          },
                        }),
                      ] }
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                    <span className="login-icon">
                      <i className="fas fa-lock"></i>
                    </span>
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./signatureCanvas.css"
const SignaturePad = ({ onSave }) => {
  const sigPadRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);

  const clearSignature = () => {
    sigPadRef.current.clear();
    setIsEmpty(true);
  };

  const saveSignature = () => {
    if (!sigPadRef.current.isEmpty()) {
      const dataUrl = sigPadRef.current.getTrimmedCanvas().toDataURL("image/png");
      onSave(dataUrl);
    }
  };

  return (
    <div>
      <div style={ { display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' } }>
        {/* <label>Draw Signature</label> */ }
        <SignatureCanvas
          ref={ sigPadRef }
          penColor="black"
          canvasProps={ { width: 300, height: 100, className: "signatureCanvas" } }
          onEnd={ () => setIsEmpty(false) }
        />
      </div>
      <div style={ { display: 'flex', justifyContent: 'center', gap: '10px' } }>
        <div className="ant-delete" style={ { background: '#187CB7', padding: '5px 10px', color: 'white', cursor: 'pointer' } } onClick={ clearSignature } disabled={ isEmpty }>Clear</div>
        <div style={ { background: '#187CB7', padding: '5px 10px', color: 'white', cursor: 'pointer' } } onClick={ saveSignature } disabled={ isEmpty }>Save</div>
      </div>
    </div>
  );
};

export default SignaturePad;

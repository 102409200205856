import React, { useState, useEffect, useRef } from 'react';
// import * as pdfjs from 'pdfjs-dist';
import { jsPDF } from 'jspdf';
import {
    Button,
    Checkbox,
    Input,
    message
} from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Service from '../../service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import { ReloadOutlined } from '@ant-design/icons';
import SignaturePad from './SignaturePad';
import "./signatureCanvas.css"


const SignDocs = ({ initialFile = { url: '' }, getEmpDataFunction, isSignedAnyDoc, signForAllDoc }) => {
    console.log(initialFile, 'initialFile', isSignedAnyDoc, signForAllDoc, isSignedAnyDoc.length !== 0);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let token = query.get("token");
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
const [signAttch,setsignAttch]=useState(false) 
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [images, setImages] = useState([]);
    const [uploadedSignature, setUploadedSignature] = useState(null);
    const [uploadedSignatureAny, setUploadedSignatureAny] = useState(uploadedSignature);

    const [isMouseDown, setIsMouseDown] = useState(false);  // New state to track if the mouse button is down
    const [reloadPDF, setReloadPDF] = useState(false);
    const containerRef = useRef();// Add this state to keep track of the current PDF
    const fileInputRef = useRef();
    const [selectedOption, setSelectedOption] = useState("draw");
    const [fileName, setFileName] = useState("");
    useEffect(() => {
        dispatch(showAuthLoader());
        // Dynamic import of pdf.js from your own server
        const script = document.createElement('script');
        script.src = "https://hrms.elsner.com/pdfjs/build/pdf.js";  // Replace with the actual URL
        script.onload = () => {
            console.log('PDF.js script loaded');

            window.pdfjsLib.GlobalWorkerOptions.workerSrc = "https://hrms.elsner.com/pdfjs/build/pdf.worker.js";

            if (initialFile && initialFile.url) {
                const loadingTask = window.pdfjsLib.getDocument({ url: initialFile.url });

                setTimeout(() => {
                    loadingTask.promise
                        .then((pdf) => {
                            console.log('PDF loaded', pdf);
                            setTotalPages(pdf?.numPages);
                            return renderPage(pdf, currentPage);
                        })
                        .catch((error) => {
                            console.error('Error loading PDF:', error);
                        });
                    dispatch(hideAuthLoader());
                }, 2500);  // Adjust the delay as needed

            } else {
                console.log('initialFile.url is not defined or not a string:', initialFile);
            } // Adjust the delay as needed
        };
        script.onerror = () => {
            console.error('Error loading the PDF.js script');
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };

    }, [currentPage, images, initialFile, reloadPDF]);

    useEffect(() => { setCurrentPage(1); }, [initialFile.url])
    console.log(uploadedSignature, 'uploadedSignature');


    const renderPage = (pdf, pageNumber) => {
        dispatch(showAuthLoader());
        pdf.getPage(pageNumber).then((page) => {
            const scale = 1.2; // Increase this value to improve clarity
            const viewport = page.getViewport({ scale });
            const canvas = containerRef.current;
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            const renderTask = page.render(renderContext);
            renderTask.promise
                .then(() => {
                    // Adjust the canvas and container sizes after rendering to ensure the PDF is displayed correctly
                    canvas.style.width = '100%';
                    canvas.style.height = 'auto';
                    containerRef.current.style.width = '100%';
                    containerRef.current.style.height = 'auto';

                    images.forEach((img) => {
                        if (img.page === pageNumber) {
                            const image = new Image();
                            image.onload = () => {
                                context.drawImage(image, img.x, img.y, 160, 80);
                            };
                            image.src = img.src;
                        }
                    });
                    dispatch(hideAuthLoader());

                })
                .catch((error) => {
                    dispatch(hideAuthLoader());
                    console.error(error)
                });
        });
    };

    const fixImage = () => {
        dispatch(showAuthLoader());
        if (uploadedSignature) {
            const canvas = containerRef.current;
            const rect = canvas.getBoundingClientRect();
            const x = (position.left * canvas.width) / rect.width;
            const y = (position.top * canvas.height) / rect.height;

            setImages((prevImages) => [
                ...prevImages,
                {
                    src: uploadedSignature,
                    x: x,
                    y: y,
                    page: currentPage,
                },
            ]);
            setUploadedSignature(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the file input value
            }
            setUploadedSignature(localStorage.getItem('signatu'))
            setsignAttch(true)
        }
        dispatch(hideAuthLoader());

    };

    //Upload signed PDF function:
    const uploadSignedPDF = async (values, fileName, file) => {
        try {
            dispatch(showAuthLoader());
            let docFormData = new FormData()
            docFormData.append('signDoc', file, fileName)
            docFormData.append('doc_type', values.doc_Type)
            docFormData.append('doc_name', fileName)
            docFormData.append('uniqueId', values.uniqueId)

            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: `${Service.signDocsByEmployee}/${token}`,
                body: docFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data && response.data.data) {
                await getEmpDataFunction()
                dispatch(hideAuthLoader());
            }
            else {
                message.error(response.data.message)
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            // dispatch(hideAuthLoader());
            console.log(error);
            dispatch(hideAuthLoader());

        }
    };

    const handleCanvasMouseDown = (e) => {
        if (uploadedSignature && e.target.tagName === 'IMG') {  // Added a check to ensure the mouse is down on the image
            setIsMouseDown(true);
        }
    };

    const handleCanvasMouseUp = (e) => {
        setIsMouseDown(false);
    };

    const handleCanvasMouseMove = (e) => {
        if (isMouseDown && uploadedSignature) {
            const bounds = containerRef.current.getBoundingClientRect();
            let newTop = e.clientY - bounds.top - 25;
            let newLeft = e.clientX - bounds.left - 50;

            // Add these conditions to keep the image within the canvas
            if (newTop < 0) newTop = 0;
            if (newLeft < 0) newLeft = 0;
            if (newTop + 80 > bounds.height) newTop = bounds.height - 80;  // 80 is the height of the image, adjust as needed
            if (newLeft + 160 > bounds.width) newLeft = bounds.width - 160; // 160 is the width of the image, adjust as needed

            setPosition({
                top: newTop,
                left: newLeft,
            });
        }
    };

    const [fileError, setFileError] = useState('');  // New state to hold the file error message

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && ['image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type)) {
            setFileError('');  // Clear the error message if the file type is valid
            console.log(URL.createObjectURL(file), 'URL.createObjectURL(file)');

            setUploadedSignature(URL.createObjectURL(file));
            localStorage.setItem('signatu', URL.createObjectURL(file))
            setPosition({ top: 50, left: 50 }); // Set initial position of the image
            setFileName(e.target.files[0].name);
        } else {
            setFileError('File format not supported!');  // Set the error message if the file type is not valid
            setFileName("");
        }
    };





    //Download PDF function:
    const downloadPDF = () => {
        if (images.length == 0) {
            message.error("Please upload your signature")
            return;
        }
        dispatch(showAuthLoader());
        const pdfDoc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
        const loadingTask = window.pdfjsLib.getDocument(initialFile.url);

        loadingTask.promise.then((pdf) => {
            const promises = [];

            for (let i = 1; i <= totalPages; i++) {
                promises.push(
                    pdf.getPage(i).then((page) => {
                        const scale = 1.2;
                        const viewport = page.getViewport({ scale });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        return page.render(renderContext).promise.then(() => {
                            const imagePromises = images.filter(img => img.page === i).map(img => {
                                return new Promise((resolve) => {
                                    const image = new Image();
                                    image.onload = () => {
                                        context.drawImage(image, img.x, img.y, 180, 90); // Adjust the size as needed
                                        resolve();
                                    };
                                    image.src = img.src;
                                });
                            });

                            return Promise.all(imagePromises).then(() => {
                                return new Promise((resolve) => {
                                    canvas.toBlob((blob) => {
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            resolve(reader.result);
                                        };
                                        reader.readAsDataURL(blob);
                                    });
                                });
                            });
                        });
                    })
                );
            }

            Promise.all(promises).then(async (pages) => {
                pages.forEach((imgData, index) => {
                    if (index !== 0) {
                        pdfDoc.addPage();
                    }
                    pdfDoc.addImage(imgData, 'PNG', 0, 0, 210, 297);
                });

                // Convert jsPDF to Blob
                const blob = pdfDoc.output('blob');
                let downloadDocName = initialFile.url.split('/')
                console.log("🚀 ~ file: SignDocs.js:296 ~ Promise.all ~ downloadDocName:", downloadDocName, initialFile)
                downloadDocName = downloadDocName[downloadDocName.length - 1]
                await uploadSignedPDF(initialFile, `signed_${downloadDocName}`, blob)
                // pdfDoc.save(`signed_${downloadDocName}`);
                images.length = 0;
                dispatch(hideAuthLoader());

            });
        });
    };


    //Next & Previous page functins:
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    const removeUploadedSignature = () => {
        setUploadedSignature(null);
        setsignAttch(false)
localStorage.removeItem('signatu')
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleSaveSignature = (dataUrl) => {
        setUploadedSignature(dataUrl);
        // setUploadedSignature(URL.createObjectURL(file));
        setPosition({ top: 50, left: 50 });
        localStorage.setItem('signatu', dataUrl)

        console.log("Signature saved:", dataUrl);
    };
    useEffect(() => {
        setUploadedSignature(localStorage.getItem('signatu'))
    }, [isSignedAnyDoc])
    return (
        <>
            <div className='signed_docs'>
                <div style={{display:uploadedSignature!==null?'none':'block'
}}>

                {/* <div

                    className="radio-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginBottom: '20px',
                     }}>
                    <label style={{
                        display: 'flex', alignItems: 'center', gap: '5px',


                    }}>
                        <input
                            type="radio"
                            value="draw"
                            checked={selectedOption === "draw"}
                            onChange={() => {
                                setSelectedOption("draw")
                            }}
                            disabled={signAttch}

                        />
                        Draw Signature
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

                        <input
                            type="radio"
                            value="upload"
                            checked={selectedOption === "upload"}
                            onChange={() => {
                                setSelectedOption("upload")
                            }
                            }

                        />
                        Upload Signature
                    </label>
                </div> */}
                {/* <div  className="radio-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginBottom: '20px', */}
                     {/* }}> */}
                {/* <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> */}

{/* <input
    type="radio"
    value="upload"
    checked={selectedOption === "upload"}
    onChange={() => {
        setSelectedOption("upload")
    }
    }

/> */}
{/* Upload Signature */}
{/* </label> */}
                {/* </div> */}
                <div className='form_row'>

                    {/* {selectedOption == 'draw' ?
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                        >

                            <SignaturePad onSave={handleSaveSignature} />
                        </div> :
                        <> */}
                            {/* <label>Draw Signature</label> */}
                            <div className="file-upload-container">
                                <label
                                    className="file-upload-box"
                                // onClick={() => fileInputRef.current.click()}
                                >
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileUpload}
                                        accept=".jpg, .jpeg, .png, .svg"
                                        className="file-input"
                                    />
                                    <p className="file-upload-text">{fileName != "" ? fileName : "Click to upload an image"}</p>
                                </label>
                                {/* {fileName && <p className="file-name">Selected file: {fileName}</p>} */}

                            </div>

                        {/* </>} */}
                    {fileError && <span style={{ color: 'red' }}>{fileError}</span>}  {/* Display error message if fileError state is not empty */}

                </div>
                </div>
                <div
                    className='uploaded_form'
                    onMouseDown={handleCanvasMouseDown}
                    onMouseUp={handleCanvasMouseUp}
                    onMouseMove={handleCanvasMouseMove}
                >
                    <canvas ref={containerRef} ></canvas>
                    {uploadedSignature && (
                        <> <img
                            src={uploadedSignature}
                            style={{
                                position: 'absolute',
                                top: position.top,
                                left: position.left,
                                zIndex: 9999,
                                width: 150,
                                height: 80,
                                padding: "3px",
                                border: isMouseDown ? '1px solid red' : '1px solid green',
                                cursor: 'move',
                            }}
                            alt="uploaded signature"
                        />
                            <button
                                className='ok_btn btn_main'
                                style={{
                                    position: 'absolute',
                                    top: position.top - 10,
                                    left: position.left + 140, // Adjust the position values according to your needs
                                    zIndex: 10000,
                                    border: 'none',
                                    borderRadius: '50px'

                                }}
disabled={signAttch}
                                onClick={removeUploadedSignature}>X</button>
                            <button
                                className='close_btn btn_main'
                                style={{
                                    position: 'absolute',
                                    top: position.top - 10,
                                    left: position.left - 10, // Adjust the position values according to your needs
                                    zIndex: 10000,
                                    border: 'none',
                                    borderRadius: '50px'

                                }}
                                onClick={fixImage}>&#10003;</button>
                        </>

                    )}

                    <div className='navigation_signdocs'>
                        <Button className='nav-btn prev' onClick={prevPage} disabled={currentPage === 1}>
                            <FaChevronLeft />
                        </Button>
                        <Button className='nav-btn' onClick={nextPage} disabled={currentPage === totalPages}>
                            <FaChevronRight />
                        </Button>
                    </div>
                </div>
                <div className='signdocs-btn'>Pages: {currentPage} / {totalPages}</div>
                <div className='signdocs-btn' style={{ marginBottom: '2%' }}>
                    <Checkbox checked={checked} onChange={onChange}>I have read all the documents carefully</Checkbox>

                    <Button type='primary' disabled={!(checked)} onClick={downloadPDF}>Submit</Button>
                </div>

            </div>
            <button
                className='floating-btn'
                onClick={() => setReloadPDF(!reloadPDF)}
                style={{
                    position: 'fixed',
                    bottom: '70px',
                    right: '20px',
                    zIndex: 1000,
                    padding: '15px 15px',
                    backgroundColor: ' #007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
                    transition: 'background-color 0.3s'
                }}
            >
                <ReloadOutlined style={{ fontSize: '20px' }} />
            </button>
        </>
    );
};

export default SignDocs;
